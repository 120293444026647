import { createReducer, on } from '@ngrx/store';

export const reducerLoadingMap = (initialState, actionInit, actionSuccess, actionError) => {
    return createReducer(
        initialState,
        on(actionInit, () => true),
        on(actionSuccess, () => false),
        on(actionError, () => false),
    );
};
