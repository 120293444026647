import { Injectable, NgZone } from '@angular/core';
import { AlertService } from './alert.service';
import { BackButtonAndroidHelper } from '../helpers/back-button-android.helper';
import { PlatformService } from './platform.service';
import { PopoverController } from '@ionic/angular';
import { AppUpdateProgressComponent } from '../../shared/ui/app-update-progress/app-update-progress.component';
import {BehaviorSubject, interval} from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { Network } from '@capacitor/network';
import { App } from '@capacitor/app';
import { FirebaseRemoteConfig } from '@capacitor-firebase/remote-config';
import {environment} from "../../../environments/environment";
import {SplashScreen} from "@capacitor/splash-screen";
import { Deploy } from 'cordova-plugin-ionic';

@Injectable({
    providedIn: 'root',
})
export class AppVersionService {

    constructor(
        private alertService: AlertService,
        private backButtonAndroidHelper: BackButtonAndroidHelper,
        private platformService: PlatformService,
        public popoverController: PopoverController,
        public updates: SwUpdate,
        private ngZone: NgZone,
    ) {
    }

    async checkUpdate() {
        if (this.platformService.isCordova()) {
            this.checkNativeBuild();
            Network.addListener('networkStatusChange', status => {
              if (status.connected) {
                this.checkNativeBuild();
              }
            });
        } else {
            //this.checkServiceWorker();
        }
    }

    checkServiceWorker() {
        this.updateServiceWorker();
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.updates.checkForUpdate().catch(() => {});
            }, 5000)
            interval(60000).subscribe(() => {
                this.updates.checkForUpdate().catch(() => {});
            });
        });
    }

    async updateServiceWorker() {
        this.updates.available.subscribe((event: any) => {
            localStorage.setItem('version', event.current.hash);
            this.updates.activateUpdate().then(async () => {
                const popover = await this.popoverController.create({
                    component: AppUpdateProgressComponent,
                    componentProps: { indeterminate: true },
                    cssClass: 'popover_center',
                    backdropDismiss: false,
                });
                await popover.present();
            });
        });
        this.updates.activated.subscribe((event: any) => {
            localStorage.setItem('version', event.previous.hash);
            window.location.reload();
        });
    }


    async checkNativeBuild() {
        try {
            if (this.platformService.isCordova()) {
                await FirebaseRemoteConfig.fetchAndActivate();
                const appVersion: any = JSON.parse((await FirebaseRemoteConfig.getString({key:'app_version'})).value);
                const lastVersion = appVersion[this.platformService.isIos() ? 'app_version_ios' : 'app_version_android'];
                const currentVersion = await this.getVersionNumber();
                if (lastVersion > currentVersion) {
                    this.update(appVersion);
                    return false;
                } else {
                    this.checkAppflow();
                    return true;
                }
            }
        } catch (e) {
            return false;
        }
    }

    async getVersionNumber(): Promise<string> {
        return (await App.getInfo()).version.split('.').reduce((acc, version) => {
            acc = acc + (1000 + Number(version)).toString().slice(1);
            return acc;
        }, '');
    }

    update(appVersion) {
        this.backButtonAndroidHelper.setPrimaryBackButtonFunction(() => {
            navigator['app'].exitApp();
        }, true);

        this.alertService.showAlert(`Вышла новая версия приложения`,
            [{
                text: 'Обновить',
                handler: () => {
                    if (this.platformService.isIos()) {
                        window.location.href = `https://apps.apple.com/ru/app/${appVersion.app_bundle_ios}`;
                    } else {
                        window.open(`https://play.google.com/store/apps/details?id=${appVersion.app_bundle_android}`, '_system');
                        navigator['app'].exitApp();
                    }
                    return false;
                },
            }], 'Обновление', false);
    }


    async checkAppflow() {
        if (!environment.production){
          return true;
        }
        await Deploy.configure({
          appId: environment.APPFLOW_ID,
          channel: environment.APPFLOW_CHANNEL,
          maxVersions: 2,
        })
        const update = await Deploy.checkForUpdate();
        if (update.available) {
            this.backButtonAndroidHelper.setPrimaryBackButtonFunction(() => {
            }, true);
            const percentProgress = new BehaviorSubject<number>(0);
            const popover = await this.popoverController.create({
                component: AppUpdateProgressComponent,
                componentProps: { progress: percentProgress.asObservable() },
                cssClass: 'popover_center',
                backdropDismiss: false,
            });
            await popover.present();
            await Deploy.downloadUpdate((progress) => {
                percentProgress.next(progress / 100);
            });
            await Deploy.extractUpdate();
            await SplashScreen.show();
            await Deploy.reloadApp();
            await SplashScreen.hide();
        }
    }


}
