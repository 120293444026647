import { Component, Input, OnInit } from '@angular/core';
import {toDataURL, QRCodeToDataURLOptions} from "qrcode";

@Component({
    selector: 'app-qrcode',
    templateUrl: './qrcode.component.html',
    styleUrls: ['./qrcode.component.scss'],
})
export class QrcodeComponent implements OnInit {
    @Input() url!: string;

    qr: any;

    options: QRCodeToDataURLOptions = {
        errorCorrectionLevel: 'L',
        margin: 1,
        scale: 17,
        color: {
            dark: '#1D2E69',
        },
    };

    constructor() {}

    ngOnInit() {
        const canvas = document.createElement('canvas');
        toDataURL(canvas, this.url, this.options, (err: any, url: string) => {
            this.qr = url;
        });
    }
}
