import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-profile-be-executor',
    templateUrl: './be-executor.component.html',
    styleUrls: ['./be-executor.component.scss'],
})
export class ProfileBeExecutorComponent implements OnInit {
    @Input() show = false;

    constructor() {}

    ngOnInit() {}
}
