import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-input-one-word',
    templateUrl: './input-one-word.component.html',
    styleUrls: ['./input-one-word.component.scss'],
})
export class InputOneWordComponent implements OnInit {
    @Input() placeholder = '';
    @Input() value = '';
    @Input() disabled = false;

    regexp = /^[a-zA-Zа-яёА-ЯЁ]+$/;

    @Output() inputEv: EventEmitter<any> = new EventEmitter<any>(false);
    @Output() clickEnter: EventEmitter<any> = new EventEmitter<any>(false);

    customPatterns = { A: { pattern: new RegExp(this.regexp) } };

    constructor() {}

    ngOnInit() {
        this.customPatterns = { A: { pattern: new RegExp(this.regexp) } };
    }

    inputText(event: any) {
        this.inputEv.emit(event);
    }

    clickEnterEv() {
        this.clickEnter.emit(true);
    }
}
