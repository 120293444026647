import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { catchError, filter, map, share, startWith, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { muteFirst } from '../../../core/utils/rx-js.util';

import { NotificationActions } from '../store/notification.actions';
import { NotificationSelectors } from '../store/notification.selectors';

import { NotificationApiService } from '../../../core/api/notification/notification-api.service';

import { INotificationState } from '../store/notification.reducer';
import { SocketService } from '../../../core/api/socket.service';
import { ESocketEvents } from '../../../core/api/enums/socket-events.enum';
import {INotificationEvent} from '../models/notificaton-event.model';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    // Добавляется в запрос в случае если необходимо дозагрузать уведомления
    private requiredNotification$ = this.store.pipe(
        select(NotificationSelectors.needLoad),
        filter((needLoad) => needLoad),
        tap(() => {
            this.store.dispatch(NotificationActions.loadItemsRequest());
        }),
        switchMap(() => this.notificationApi.getNotificationList({})),
        map((notificationResult) => {
            if (notificationResult.status === 'ok') {
                this.store.dispatch(NotificationActions.loadItemsSuccess({ notifications: notificationResult.result }));
            } else {
                this.store.dispatch(NotificationActions.loadItemsError({ e: notificationResult.message }));
            }
        }),
        catchError((e) => of(this.store.dispatch(NotificationActions.loadItemsError({ e })))),
        share(),
    );

    constructor(
        private readonly notificationApi: NotificationApiService,
        private readonly socketService: SocketService,
        private readonly store: Store<INotificationState>,
    ) {}

    async init() {
        this.subscribeToNotification();
    }

    subscribeToNotification() {
        this.socketService
            .onPrivateEvent(ESocketEvents.USER_NOTIFY_EVENTS)
            .subscribe((notifyEvents: INotificationEvent[]) => {
                this.store.dispatch(
                    NotificationActions.receiveNotifications({
                        notifications: notifyEvents.map((nEvent) => ({ ...nEvent.notify })),
                    }),
                );
            });
    }

    getNotificationList() {
        return muteFirst(
            this.requiredNotification$.pipe(startWith(() => null)),
            this.store.select(NotificationSelectors.selectAll),
        );
    }

    setNotificationRead() {
        return this.notificationApi.markNotificationRead();
    }
}
