<div>
    <button
        hidden
        (click)="click(value)"
        [id]="name + '_' + value"
        [name]="name"
        [value]="value"
        [disabled]="disabled"
        [ngClass]="{ checked: checked }"
    ></button>
    <label class="caption_circe" [class.disabled]="disabled" [for]="name + '_' + value">{{ text }}</label>
</div>
