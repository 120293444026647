<ion-header [mode]="'ios'" [ngClass]="shadow ? 'shadow' : 'no-shadow'">
    <ion-toolbar [mode]="'ios'">
        <div class="grid-wrapper {{ class }}">
            <div class="left-grid header-column {{ class }}">
                <ng-content select="[slot=start]"></ng-content>
            </div>

            <div class="center-grid header-column">
                <ng-content select="[slot=center]"></ng-content>
            </div>

            <div class="right-grid header-column">
                <ng-content select="[slot=end]"></ng-content>
            </div>
        </div>
    </ion-toolbar>
    <div class="bottom">
        <ng-content select="[slot=bottom]"></ng-content>
    </div>
</ion-header>
