import { Injectable } from '@angular/core';
import { PlatformService } from './platform.service';
import { AccountService } from '../account/account.service';
import * as EmojiConvertor from 'emoji-js';
import * as crypto from 'crypto-js';
import { environment } from '../../../environments/environment';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { deleteCookie } from '../helpers/cookies';
import {pause} from "../utils/helpers";

declare let window: any;
declare let carrotquest: any;
declare let CordovaCarrotquestSDK: any;

@Injectable({
    providedIn: 'root',
})
export class CarrotQuestService {
    private emojiConvertor;
    private isAuth = false;

    private loaded = false;
    private webSocketSub: WebSocketSubject<any> | null = null;

    constructor(
        private platformService: PlatformService,
        private accountService: AccountService,
    ) {
        this.accountService.getProfileInfo().subscribe(async (user) => {
          if (this.loaded) {
            if (user) {
              await pause(500);
              this.setAuthData(user);
            }
          } else {
            const i = setInterval(() => {
              if (this.loaded) {
                this.setAuthData(user);
                clearInterval(i);
              }
            }, 500);
          }
        });
    }

  private async setAuthData(user: any) {
    if (user) {
      if (!this.isAuth) {
        await this.authorization(user._id);
      }
      await pause(200);
      const formatName = `${user.lastName ? user.lastName : ''} ${user.name ? user.name : ''} ${user.patronymic ? user.patronymic : ''}`;
      await this.identification({
        name: formatName,
        inn: user.inn ? user.inn : '',
        phone: user.phone.toString(),
      });
    }
  }

    async init() {
        this.emojiConvertor = new EmojiConvertor();
        this.emojiConvertor.replace_mode = 'unified';
        if (this.platformService.isCordova()) {
            await this.platformService.getPlatform().ready();
            return new Promise<void>((resolve) =>
                CordovaCarrotquestSDK.setup(
                    environment.carrotApiKey,
                    environment.carrotAppId,
                    false,
                ).then(() => {
                    CordovaCarrotquestSDK.setProperties([{ tag: 'marketplace' }]);
                    // if (this.platformService.isIos()) {
                    //     window.FirebasePlugin.onMessageReceived((message) => {
                    //         if (message.is_carrot) {
                    //             this.showToast({
                    //                 message: message.body,
                    //                 name: 'Сообщение из поддержки',
                    //                 avatar: null,
                    //             });
                    //         }
                    //     });
                    // }
                    resolve();
                }),
            );
        } else {
            return new Promise<void>((resolve) => {
                const l = () => {
                    const styles = document.head.appendChild(
                        document.createElement('style'),
                    );
                    styles.innerHTML = `
                    #carrotquest-messenger-collapsed-container {display: none}
                    .carrot-notification-container {display: none}
                `;
                    const s = document.createElement('script');
                    s.async = true;
                    s.src = 'https://cdn.carrotquest.app/api.min.js';
                    document.head.appendChild(s);
                    window.carrotquest = {};
                    window.carrotquestasync = [];
                    window.carrotquest.settings = {};
                    const n = [
                        'connect',
                        'track',
                        'identify',
                        'auth',
                        'onReady',
                        'addCallback',
                        'removeCallback',
                        'trackMessageInteraction',
                    ];
                    function t(t) {
                      return function() {
                        window.carrotquestasync.push(t, arguments)
                      }
                    }
                    // tslint:disable-next-line:prefer-for-of
                    for (let a = 0; a < n.length; a++) {
                        window.carrotquest[n[a]] = t(n[a]);
                    }
                    s.addEventListener('load', (event) => {
                        window.carrotquest.connect(environment.carrotApiKey, {
                            settings: {
                                messenger_mode: 'hidden',
                            },
                        });
                        window.carrotquest.onReady(() => {
                            window.carrotquest.BELL_SOUND = '/assets/silent.wav';
                            if (this.webSocketSub) {
                                this.webSocketSub.complete();
                            }
                            this.webSocketSub = webSocket(
                                `wss://realtime-services.carrotquest.io/websocket/conversation_parts_batch.${window.carrotquest.data.app.id}.${window.carrotquest.data.user.id}?auth_token=${window.carrotquest.data.auth_token}`,
                            );
                            this.webSocketSub.subscribe(({ message }) => {
                                message.parts.forEach((mess) => {
                                    if (mess.type === 'reply_user') {
                                        return;
                                    }
                                    this.showToast({
                                        message:
                                            mess.attachments && mess.attachments.length
                                                ? 'Файл'
                                                : mess.body,
                                        name: mess.from.name,
                                        avatar: mess.from.avatar,
                                    });
                                });
                            });
                            setTimeout(() => {
                              window.carrotquest.identify({tag: 'marketplace'});
                              this.loaded = true;
                            }, 500);
                            carrotquest = window.carrotquest;
                            resolve();
                        });
                    });
                };
                if (document.readyState === 'complete') {
                    l();
                } else if (window.attachEvent) {
                    window.attachEvent('onload', l);
                } else {
                    window.addEventListener('load', l, false);
                }
            });
        }
    }

    open() {
        if (this.platformService.isCordova()) {
            return CordovaCarrotquestSDK.openChat();
        } else {
            return window.carrotquest.openMessenger();
        }
    }

    close(force: boolean = false) {
        const iframe: HTMLIFrameElement = document.getElementById(
            'carrot-messenger-frame',
        ) as HTMLIFrameElement;
        const backButton: HTMLElement =
            iframe.contentDocument.querySelector('.back-button');
        const closeButton: HTMLElement =
            iframe.contentDocument.querySelector('.close-button');
        if (iframe) {
            if (!force) {
                if (backButton) {
                    backButton.click();
                } else {
                    closeButton.click();
                }
            } else {
                closeButton.click();
            }
        }
    }

    async authorization(userId: string) {
        if (this.platformService.isCordova()) {
            await CordovaCarrotquestSDK.auth(
                `marketplace_${userId}`,
                environment.carrotAuthKey,
            );
            this.isAuth = true;
        } else {
            const userIDwPrefix = `marketplace_${userId}`;
            const hash = crypto.HmacSHA256(userIDwPrefix, environment.carrotAuthKey);
            this.isAuth = true;
            return window.carrotquest.auth(userIDwPrefix, hash);
        }
    }

    logout() {
        if (this.platformService.isCordova()) {
            this.isAuth = false;
            return CordovaCarrotquestSDK.deInit();
        } else {
            deleteCookie('carrotquest_auth_token');
            deleteCookie('carrotquest_device_guid');
            deleteCookie('carrotquest_realtime_services_transport');
            deleteCookie('carrotquest_session');
            deleteCookie('carrotquest_session_started');
            deleteCookie('carrotquest_uid');
            this.isAuth = false;
            if (window.carrotquest) {
              window.carrotquest.removeChat();
            }
        }
    }

    identification(args: { name: string; phone: string; inn: string }) {
        if (this.isAuth) {
            if (this.platformService.isCordova()) {
                return CordovaCarrotquestSDK.setProperties([
                    { $name: args.name },
                    { $phone: args.phone },
                    { tag: 'marketplace' },
                    { worker_inn: args.inn },
                ]);
            } else {
                return window.carrotquest.identify({
                    $name: args.name,
                    $phone: args.phone,
                    tag: 'marketplace',
                    worker_inn: args.inn,
                });
            }
        }
    }

    private showToast(args: { message: string; name: string; avatar: string }) {
        const toastContainer: any = document.createElement('div');
        toastContainer.classList.add('toast-carrot-quest');
        toastContainer.style = `
      cursor: pointer;
      background-color: white;
      position: relative;
      top: calc(20px + var(--ion-safe-area-top));
      margin: 0 20px 20px;
      font-family: Circe;
      border-radius: 13px;
      padding: 10px 10px;
      display: flex;
      box-shadow: 0px 0px 20px rgb(97 106 136 / 12%);
      overflow: hidden;
      opacity: 1;
      transition: opacity 0.3s;
    `;
        toastContainer.innerHTML = `
      <div style='margin-right: 10px;flex-shrink: 0;'>
        <img style='width: 48px;height: 48px;border-radius: 50%;object-fit: cover; display: ${args.avatar ? 'block' : 'none'}' src='${
            args.avatar
        }'>
      </div>
      <div style='flex: 1;'>
        <div style='color: #26252D;font-weight: 500;font-size: 16px;'>${
            args.name
        }</div>
        <div style='color: #26252D;font-weight: 400;font-size: 14px;
        white-space: break-spaces;
        word-break: break-all;'>${this.emojiConvertor.replace_colons(
            args.message,
        )}</div>
      </div>
    `;
        toastContainer.addEventListener('click', () => {
            this.open();
            const toasts = document.querySelectorAll('.toast-carrot-quest');
            toasts.forEach((toast) => {
                toast.remove();
            });
        });
        document.body.appendChild(toastContainer);
        setTimeout(() => {
            toastContainer.style.opacity = 0;
            toastContainer.addEventListener(
                'transitionend',
                () => {
                    toastContainer.remove();
                },
                { once: true },
            );
        }, 7500);
    }
}
