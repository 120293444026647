import {Injectable} from '@angular/core';
import {first, map} from 'rxjs/operators';
import {PlatformService} from './platform.service';
import {loadScript} from "../utils/helpers";
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {KeyboardFixService} from "../helpers/keyboard-fix.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class CaptchaService {

  private captchaWidgetId = null;
  private captchaScriptInited: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private captchaScriptLoad = false;

  private ycaptchaClientKey = environment?.ycaptchaClientKey || null;
  private captchaToken$: Subject<string> = new Subject<string>();
  private captchaToken: string | null;
  private keyboardHeightSub: Subscription;
  private hideChallengeSub;
  private hideChallengeSubExec;
  private successChallengeSub;

  constructor(
    private platformService: PlatformService,
    private keyboardFixService: KeyboardFixService,
  ) {
  }

  async init() {
    return new Promise(async (resolve) => {
      if (this.captchaScriptInited.getValue() || !this.ycaptchaClientKey) {
        resolve(true);
      } else {
        if (!this.captchaScriptLoad) {
          this.captchaScriptLoad = true;
          await loadScript(`https://captcha-api.yandex.ru/captcha.js`);
          this.captchaScriptInited.next(true);
          this.captchaScriptLoad = false;
          resolve(true);
        } else {
          const i = setInterval(() => {
            if (this.captchaScriptInited.getValue()) {
              resolve(true);
              clearInterval(i);
            }
          }, 1000);
        }
      }
    })
  }

  getToken() {
    if (!this.ycaptchaClientKey) {
      return '';
    }
    return this.captchaToken;
  }

  async render() {
    if (!this.ycaptchaClientKey) {
      return;
    }

    if (this.captchaWidgetId !== null) {
      this.reset();
    } else {
      this.captchaToken = null;
      this.captchaToken$ = new Subject();
      const captchaContainer = document.createElement('div');
      captchaContainer.id = "captcha-container";
      captchaContainer.setAttribute('style', 'bottom: 0;position: absolute;width: 100%;');
      document.body.appendChild(captchaContainer);
      this.captchaWidgetId = (window as any).smartCaptcha.render(document.getElementById('captcha-container'), {
        sitekey: this.ycaptchaClientKey,
        hl: 'ru',
        invisible: true,
        hideShield: true,
        callback: (token: string) => {
          this.captchaToken = token;
          this.captchaToken$.next(token);
          this.captchaToken$.complete();
        }
      })
    }
    this.keyboardHeightHandlerStart();
    return this.captchaWidgetId;
  }

  destroy() {
    if (!this.ycaptchaClientKey || this.captchaWidgetId === null) {
      return;
    }
    this.captchaToken = null;
    this.captchaToken$ = new Subject();
    //const res = (window as any).smartCaptcha.destroy(this.captchaWidgetId);
    this.keyboardHeightHandlerStop();
  }

  reset() {
    if (!this.ycaptchaClientKey || this.captchaWidgetId === null) {
      return;
    }
    this.captchaToken = null;
    this.captchaToken$ = new Subject();
    return (window as any).smartCaptcha?.reset(this.captchaWidgetId);
  }

  async execute(args: { id }): Promise<{id: string, token: string} >{
    return new Promise(async (resolve, reject) => {
      if (!this.ycaptchaClientKey || this.captchaWidgetId === null) {
        resolve({
          id: args.id,
          token: ''
        })
      }
      this.hideChallengeSubExec = (window as any).smartCaptcha.subscribe(
        this.captchaWidgetId,
        'challenge-hidden',
        () => {
          this.hideChallengeSubExec();
          reject();
        }
      );
      (window as any).smartCaptcha.execute(this.captchaWidgetId);
      const token = await this.captchaToken$.toPromise();
      resolve( {
        id: args.id,
        token: token
      })
    })
  }

  private keyboardHeightHandlerStart() {
    this.keyboardHeightSub = this.keyboardFixService.subToKeyboardEvent$().subscribe((height) => {
      const iframe: any = document.querySelector('.SmartCaptcha-Overlay_visible')?.children[1];
      if (iframe) {
        const ionSafeAreaBottom = parseInt(
          getComputedStyle(document.documentElement).getPropertyValue('--ion-safe-area-bottom'),
          10,
        );
        const iframeTop = height > 0 ? -(height + ionSafeAreaBottom) : 0;
        iframe.style.transform = `translate3d(0px, ${iframeTop}px, 0px)`;
      }
    });
    this.hideChallengeSub = (window as any).smartCaptcha.subscribe(
      this.captchaWidgetId,
      'challenge-hidden',
      () => {
        const iframe: any = document.querySelector('.SmartCaptcha-Overlay')?.children[1];
        if (iframe) {
          const iframeTop = 0;
          iframe.style.transform = `translate3d(0px, ${iframeTop}px, 0px)`;
          this.keyboardFixService.hide();
        }
      }
    );
    this.successChallengeSub = (window as any).smartCaptcha.subscribe(
      this.captchaWidgetId,
      'success',
      () => {
        const iframe: any = document.querySelector('.SmartCaptcha-Overlay')?.children[1];
        if (iframe) {
          const iframeTop = 0;
          iframe.style.transform = `translate3d(0px, ${iframeTop}px, 0px)`;
          this.keyboardFixService.hide();
        }
      }
    );
  }

  private keyboardHeightHandlerStop() {
    this.keyboardHeightSub.unsubscribe();
    this.hideChallengeSub();
    this.successChallengeSub();
  }

}
