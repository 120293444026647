import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-input-readonly',
    templateUrl: './input-readonly.component.html',
    styleUrls: ['./input-readonly.component.scss'],
})
export class InputReadonlyComponent implements OnInit {
    _value = '';

    @Input() placeholder = '';
    @Input() set value(val: any) {
        if (val) {
            this._value = val;
        }
    }
    @Input() disabled = false;

    @Output() output: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit() {}

    click() {
        this.output.emit(true);
    }
}
