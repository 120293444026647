import { Injectable } from '@angular/core';

import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, filter, take, timeout } from 'rxjs/operators';
import {Geolocation} from "@capacitor/geolocation";

export interface IPosition {
    lat: number;
    lng: number;
}

@Injectable({ providedIn: 'root' })
export class PositionService {
    readonly userPositionState = new BehaviorSubject<IPosition | 'deined' | null>(null);

    userPosition$ = this.userPositionState.asObservable();

    defaultPosition = {lat: 55.753960, lng: 37.620393};

    constructor() {}

    async getApproximatePosition(): Promise<IPosition> {
        const position = await this.getPosition()
            .pipe(
                filter((res) => !!res),
                take(1),
                timeout(10000),
                catchError(() => {
                    return EMPTY;
                }),
            )
            .toPromise();

        if (!position) {
            return this.defaultPosition;
        }

        if (position === 'deined') {
          return this.defaultPosition;
        }

        return position;
    }

    private getPosition(): Observable<IPosition | 'deined' | null> {
        this.refreshPosition();
        return this.userPosition$;
    }

    async refreshPosition() {
        try {
            const userPosition: any = await Geolocation.getCurrentPosition({timeout: 10000, maximumAge: 60 * 1000 * 2});
            this.userPositionState.next({lat: userPosition.coords.latitude, lng: userPosition.coords.longitude});
        } catch (e) {
            console.error('[GeoProvider] getCurrentPosition error: ', e.message);
            this.userPositionState.next('deined');
        }
    }
}
