import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-ui-online-status',
    templateUrl: './ui-online-status.component.html',
    styleUrls: ['./ui-online-status.component.scss'],
})
export class UiOnlineStatusComponent {
    status = 'Сейчас онлайн';
    isOnline = false;

    @Input() set lastOnlineDate(date: Date | string) {
        this.setOnlineStatus(date);
    }

    @Input() darkMode = false;

    constructor() {
    }

    setOnlineStatus(date: any) {
        const timePassed = Date.now() - new Date(date).getTime();

        this.status = 'Был(а) давно'; // больше 30 дней

        if (timePassed < 1000 * 60 * 60 * 24 * 30) {
            // последние 30 дней
            this.status = 'Был(а) в этом месяце';
        }

        if (timePassed < 1000 * 60 * 60 * 24 * 7) {
            // последние 7 дней
            this.status = 'Был(а) на этой неделе';
        }

        if (timePassed > 1000 * 60 * 60 * 24 && timePassed < 1000 * 60 * 60 * 24 * 2) {
            // Вчера
            this.status = 'Был(а) вчера';
        }

        if (timePassed < 1000 * 60 * 60 * 24) {
            // меньше 24 часа
            this.status = 'Был(а) недавно';
        }

        if (timePassed < 1000 * 60 * 10) {
            // меньше 10 минут
            this.status = 'Сейчас онлайн';
            this.isOnline = true;
        }
    }
}
