import {ComponentFactoryResolver, Injectable, Injector} from '@angular/core';
import { AngularDelegate, ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CustomModalController extends ModalController {

    private isModalShowSource$: BehaviorSubject<{show: boolean, opts:any}> = new BehaviorSubject<any>({show: false, opts: null});
    isModalShow = this.isModalShowSource$.asObservable();

    constructor(angularDelegate: AngularDelegate, resolver: ComponentFactoryResolver, injector: Injector) {
        super(angularDelegate, resolver, injector);
    }

    create(opts: ModalOptions|any): Promise<HTMLIonModalElement> {
        this.isModalShowSource$.next({show: true, opts: opts.componentProps || null});
        return super.create(opts);
    }

    dismiss(data?: any, role?: string, id?: string): Promise<boolean> {
        this.isModalShowSource$.next({show: false, opts: null});
        return super.dismiss(data, role, id);
    }


}
