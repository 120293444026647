import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
    selector: 'app-back-button',
    templateUrl: './back-button.component.html',
    styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
    @Input() height = 14;
    @Input() width = 20;
    @Input() color = 'var(--ion-color-main-text)';

    @Input() func: () => void = null;

    @Input() present: 'page' | 'modal' = 'page';

    constructor(
        private router: Router,
        private navController: NavController,
        private modalController: ModalController,
    ) {
    }

    ngOnInit() {
    }

    async back() {
        if (this.func) {
            this.func();
        } else {
            if (this.present === 'page') {
                const oldUrl = this.router.url;
                await this.navController.pop();
                const newUrl = this.router.url;
                if (newUrl === oldUrl){
                    await this.navController.navigateRoot('/', {animated: true, animationDirection: 'back'});
                }
            } else if (this.present === 'modal') {
                await this.modalController.dismiss();
            }
        }
    }
}
