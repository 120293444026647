import { Injectable } from '@angular/core';
import * as socketIo from 'socket.io-client';
import { interval, Observable, Subject } from 'rxjs';
import { ESocketEvents } from './enums/socket-events.enum';

import { environment } from '../../../environments/environment';
import { takeUntil, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    private socket;
    private connectionSubject = new Subject<boolean>();

    constructor() {}

    public async initSocket(token) {
        this.socket = socketIo(environment.SOCKET_URL, {
            query: { token },
        });

        this.socket.on(ESocketEvents.DISCONNECT, () => {
            this.connectionSubject.next(false);
            interval(2500)
                .pipe(takeUntil(this.connectionSubject))
                .subscribe(() => {
                    this.socket.open();
                });
        });

        this.socket.on(ESocketEvents.CONNECT, () => {
            this.connectionSubject.next(true);
        });
    }

    public emit(event: ESocketEvents, payload: any): void {
        this.socket.emit(event, payload);
    }

    public onPrivateEvent(event: ESocketEvents): Observable<any> {
        return new Observable<Event>((observer) => {
            const subscription = this.connectionSubject.asObservable().subscribe((isConnected) => {
                if (isConnected) {
                    this.socket.removeListener(event);

                    this.socket.emit(event);

                    this.socket.on(event, (a) => {
                        observer.next(a);
                    });
                }
            });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
                this.socket.removeListener(event);
            };
        });
    }

    onUserMessageEvents() {
        return this.onPrivateEvent(ESocketEvents.USER_MESSAGE_EVENTS);
    }

    onUserNotifyEvents() {
        return this.onPrivateEvent(ESocketEvents.USER_NOTIFY_EVENTS);
    }
}
