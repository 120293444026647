import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-ad-banner',
  templateUrl: './create-ad-banner.component.html',
  styleUrls: ['./create-ad-banner.component.scss'],
})
export class CreateAdBannerComponent implements OnInit {
  @Input() show = false;

  constructor() {}

  ngOnInit() {}

}
