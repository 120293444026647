<div class="container">
    <div class="content" [ngClass]="{ selectOpacity: !selected }">
        <app-category-icon
            [category]="{ color: category.property.color, icon: getIconCategory(category.property.icon) }"
        ></app-category-icon>

        <div class="name body_rubik">
            {{ category.name }}
        </div>

        <div class="arrow" [hidden]="!showArrow">
            <app-svg-icon [name]="'right-arrow'" [stroke]="'var(--ion-color-additional-text)'"></app-svg-icon>
        </div>
    </div>
</div>
