import {Component, OnInit } from '@angular/core';
import { SmsService } from '../../../../core/sms/sms.service';
import { AuthNavigationService } from '../../auth-navigation.service';
import {catchError, finalize} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import { ToastService } from '../../../../core/services/toast.service';
import { ApiService } from '../../../../core/api/api.service';
import {CaptchaService} from "../../../../core/services/captcha.service";

@Component({
    selector: 'app-sign-in-page',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
})
export class SignInPageComponent implements OnInit {

    phone = '';
    loading = false;
    constructor(
        private authNavigationService: AuthNavigationService,
        private smsService: SmsService,
        private toastService: ToastService,
        private apiService: ApiService,
        private captchaService: CaptchaService
    ) {
    }

    ngOnInit() {

    }

    ngOnDestroy() {

    }

    async sendPhone() {
        this.loading = true;
        try {
          if (this.captchaService.getToken()) {
            this.captchaService.reset();
          }
          const {id, token} = await this.captchaService.execute({id: 'signIn'});
          if (this.authNavigationService.getSlide() !== 0 || id !== 'signIn') {
            this.loading = false;
            return;
          }
          const phone = `+7${this.phone}`;
          const exists = await this.apiService.getUserExists(this.phone);
          if (!exists) {
            this.loading = false;
            return this.toastService.presentToast('Пользователь не зарегистрирован', 3000, 'danger');
          }
          this.smsService.getSmsWithCaptchaToken(phone, token).pipe(catchError((err) => {
              this.toastService.presentToast(err);
              this.loading = false;
              return EMPTY;
            }),
          ).subscribe((token: string) => {
            this.authNavigationService.setType(true);
            this.authNavigationService.smsInfoSignIn.phone = phone;
            this.authNavigationService.smsInfoSignIn.smsConfirm.token = token;
            this.authNavigationService.setSlide(2);
            setTimeout(() => {
              this.loading = false
            }, 200);
          });
        } catch (e) {

        }
        finally {
          setTimeout(() => {
            this.loading = false
          }, 500);
        }
    }

    toSignUp() {
        this.authNavigationService.setSlide(1);
    }

    back() {
        return () => {
            this.authNavigationService.back();
        };
    }

    phoneVaild() {
        return this.phone.length === 10;
    }

}
