import { createAnimation, Animation } from '@ionic/core';

import { SwipeToCloseDefaults } from '../gestures/bootm-modal.swipe-to-close';

export const bottomModalLeaveAnimation = (heightPlatform, isIos: boolean = false, backdropDismiss = true) => (
    baseEl: HTMLElement,
    presentingEl?: HTMLElement,
    duration = 500,
): Animation => {

    const ionSafeAreaBottom = parseInt(
        getComputedStyle(document.body).getPropertyValue('--ion-safe-area-bottom'),
        10,
    );

    const modalHeight = baseEl.getElementsByTagName('app-modal-content-wrapper')[0].clientHeight + 80;
    const height = heightPlatform - ionSafeAreaBottom - modalHeight + 'px';

    baseEl.querySelector('ion-backdrop').style.pointerEvents = backdropDismiss ? 'all' : 'none';

    const backdropAnimation = createAnimation()
        .addElement(baseEl.querySelector('ion-backdrop')!)
        .fromTo('opacity', 0.5, 0.0);

    const wrapperAnimation = createAnimation()
        .addElement(baseEl.querySelectorAll('.modal-wrapper, .modal-shadow')!)
        .beforeStyles({ opacity: 1 })
        .fromTo('transform', `translateY(${height})`, 'translateY(100vh)');

    const baseAnimation = createAnimation()
        .addElement(baseEl)
        .easing('cubic-bezier(0.32,0.72,0,1)')
        .duration(duration)
        .addAnimation(wrapperAnimation);

    if (presentingEl && isIos) {
        const hasCardModal =
            presentingEl.tagName === 'ION-MODAL' &&
            (presentingEl as HTMLIonModalElement).presentingElement !== undefined;

        const presentingAnimation = createAnimation()
            .beforeClearStyles(['transform'])
            .afterClearStyles(['transform'])
            .onFinish((currentStep) => {
                // only reset background color if this is the last card-style modal
                if (currentStep !== 1) {
                    return;
                }

                presentingEl.style.setProperty('overflow', '');

                const numModals = Array.from(bodyEl.querySelectorAll('ion-modal')).filter(
                    (m) => m.presentingElement !== undefined,
                ).length;
                if (numModals <= 1) {
                    bodyEl.style.setProperty('background-color', '');
                }
            });

        const bodyEl = document.body;

        const transformOffset = !CSS.supports('width', 'max(0px, 1px)')
            ? '30px'
            : 'max(30px, var(--ion-safe-area-top))';
        const modalTransform = hasCardModal ? '-10px' : transformOffset;
        const toPresentingScale = SwipeToCloseDefaults.MIN_PRESENTING_SCALE;
        const finalTransform = `translateY(${modalTransform}) scale(${toPresentingScale})`;

        presentingAnimation.addElement(presentingEl).keyframes([
            { offset: 0, filter: 'contrast(0.85)', transform: finalTransform, borderRadius: '10px 10px 0 0' },
            { offset: 1, filter: 'contrast(1)', transform: 'translateY(0px) scale(1)', borderRadius: '0px' },
        ]);

        baseAnimation.addAnimation(presentingAnimation);
    } else {
        baseAnimation.addAnimation(backdropAnimation);
    }

    return baseAnimation;
};
