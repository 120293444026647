import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TUserShortInfo } from '../../../core/user/types/user-short-info.type';

@Component({
    selector: 'app-ui-review-profile-item',
    templateUrl: './ui-review-profile-item.component.html',
    styleUrls: ['./ui-review-profile-item.component.scss'],
})
export class UiReviewProfileItemComponent implements OnInit {
    @Input() profile!: TUserShortInfo;

    @Input() arrow = true;
    @Input() contact = false;

    constructor(private navCtrl: NavController) {
    }

    ngOnInit() {
    }

    write(id: string) {
        this.navCtrl.navigateForward(['im', id]);
    }

    call(phone: any) {
        location.href = `tel:${phone}`;
    }

    goToProfile(id: string) {
        this.navCtrl.navigateForward(['user', id]);
    }
}
