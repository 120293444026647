import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-chip',
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss'],
})
export class ChipComponent implements OnInit {
    @Input() name;
    @Input() text;
    @Input() value;
    @Input() checked = false;
    @Input() disabled = false;

    @Output() btnClick: EventEmitter<any> = new EventEmitter<any>(false);

    constructor() {}

    ngOnInit() {}

    click(value) {
        this.btnClick.emit(value);
    }
}
