import { ImageSizeTypeEnum } from '../../images/enums/image-size-type.enum';
import { IImage } from '../../images/interfaces/image.interface';

export const mapperSrcToIImage = (src: string, sizes: ImageSizeTypeEnum[], id: string | null = null): IImage => {
    return {
        _id: id,
        dateCreate: null,
        sizes: [
            ...sizes.map((size) => ({
                url: src,
                width: null,
                height: null,
                type: size,
            })),
        ],
        downloadUrl: null,
    };
};
