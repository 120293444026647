import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import {Device} from "@capacitor/device";
import {debounceTime} from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class PlatformService {
    constructor(
        private platform: Platform,
    ) {
    }

    getPlatform() {
        return this.platform;
    }

    isIos() {
        return this.platform.is('ios');
    }

    isAndroid() {
        return this.platform.is('android');
    }

    isCordova() {
        return this.platform.is('cordova');
    }

    showMenu(): Observable<boolean> {
        if (this.isCordova()){
          return of(false);
        }
        const maxWidthCss = '820px'; //getComputedStyle(document.documentElement).getPropertyValue('--max-width-app');
        const maxWidth = parseInt(maxWidthCss, 0);
        return new Observable((observer) => {
            observer.next(this.platform.width() >= maxWidth);
            this.platform.width() >= maxWidth ? document.body.classList.add('desktop') : document.body.classList.remove('desktop');
            this.platform.resize.pipe(debounceTime(100)).subscribe(() => {
                observer.next(this.platform.width() >= maxWidth);
                this.platform.width() >= maxWidth ? document.body.classList.add('desktop') : document.body.classList.remove('desktop');
            });
        });
    }

    async isOlderAndroid(version: number) {
        if (this.isCordova() && this.isAndroid()) {
            const deviceInfo = await Device.getInfo();
            return Number(deviceInfo.osVersion.split('.')[0]) <= version;
        } else {
            return false;
        }
    }
}
