import { Component, Input, OnInit } from '@angular/core';
import { ICategory } from '../../../core/category/interface/category';
import { CategoryService } from '../../../core/services/category.service';

@Component({
    selector: 'app-category-item',
    templateUrl: './category-item.component.html',
    styleUrls: ['./category-item.component.scss'],
})
export class CategoryItemComponent implements OnInit {
    @Input() category: ICategory;
    @Input() selected: boolean = true;
    @Input() showIcon: boolean = true;
    @Input() showArrow: boolean = true;

    constructor(private categoryService: CategoryService) {}

    ngOnInit() {}

    getIconCategory(name) {
        return this.categoryService.getCategoryIconByName(name);
    }
}
