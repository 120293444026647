import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
    selector: 'app-modal-reason',
    templateUrl: './modal-reason.component.html',
    styleUrls: ['./modal-reason.component.scss'],
})
export class ModalReasonComponent implements OnInit {
    @Input() reasons = [];

    @Input() adID!: string;

    @Input() func: any;

    reasonSelected: any;

    constructor(private modalController: ModalController, private navCtrl: NavController) {}

    ngOnInit() {}

    async goTo() {
        await this.func(this.adID, this.reasonSelected);
        //await this.navCtrl.navigateRoot(['/ad-detail', this.adID]);
        await this.modalController.dismiss(true);
    }
}
