import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IResult } from '../api.service';
import { environment } from '../../../../environments/environment';
import { INotification } from '../../../module/notification/models/notificaton.model';
import { GetListNotificationDto } from '../../../module/notification/dto/get-list-notification.dto';
import { createHttpGetParams } from '../../utils/http/create-http-get-params';

@Injectable({
    providedIn: 'root',
})
export class NotificationApiService {
    API_URL = environment.API_URL + '/notification';

    constructor(private http: HttpClient) {}

    getNotificationList(getListNotificationDto: GetListNotificationDto): Observable<IResult<INotification[]>> {
        const params = createHttpGetParams(getListNotificationDto);
        return this.http.get<IResult<INotification[]>>(`${this.API_URL}/`, { params });
    }

    markNotificationRead(): Observable<IResult> {
        return this.http.post<IResult>(`${this.API_URL}/markRead`, {});
    }
}
