<div class="item" (click)="goTo(ad._id)">
    <div class="grid-wrapper" *ngIf="ad.status && status">
        <div class="category"></div>
        <app-ad-status [status]="ad.status"></app-ad-status>
    </div>

    <div class="grid-wrapper">
        <app-category-icon [category]="ad.categoryInfo"></app-category-icon>

        <div class="text" [class.read]="read()">
            <div class="title font_bold sub_circe">{{ ad.name }}</div>

            <div class="item-center-container">
                <div class="price">
                    <div class="body_circe">{{ ad.price.toLocaleString('ru') }} ₽</div>
                </div>
                <ion-chip *ngIf="ad.type == adTypeEnum.vacancy" class="vacancy"><ion-label class="caption_circe">Вакансия</ion-label></ion-chip>
                <ion-chip *ngIf="ad.organisation && ad.type !== adTypeEnum.vacancy" class="organisation"><ion-label class="caption_circe">Юр. лицо</ion-label></ion-chip>
            </div>

            <div class="secondary">
                <div class="address description caption_circe" *ngIf="ad.address">
<!--                    <span> {{ad.address.streetAddress + ', ' + ad.address.city}}</span>-->
                  <span>{{ad.address.value}}</span>
                </div>

                <div class="remote description caption_circe" *ngIf="!ad.address">
                    <span>{{ 'Можно выполнить удаленно' }}</span>
                    <app-svg-icon [height]="15" [name]="'remote'"></app-svg-icon>
                </div>

                <div class="description caption_circe">{{ ad.categoryInfo.name }}</div>
            </div>

            <div class="bottom">
                <div class="date description caption_circe">{{ad.dateCreate|dateAd}}</div>
                <div></div>
            </div>

        </div>
    </div>
</div>
<div class="divider"></div>
