<input
    class="body_rubik font_regular"
    [disabled]="disabled"
    [placeholder]="placeholder"
    inputmode="text"
    [ngModel]="value"
    (ngModelChange)="inputText($event)"
    (keyup.enter)="clickEnterEv()"
    [patterns]="customPatterns"
    mask="A{15}"
    [dropSpecialCharacters]="false"
/>
