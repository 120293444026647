import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { CacheModule } from 'ionic-cache';
import { IonicStorageModule } from '@ionic/storage';
import { ThemeDetection } from '@ionic-native/theme-detection/ngx';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxMaskModule } from 'ngx-mask';
import { AuthModule } from '../page-modules/auth/auth.module';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import {AddressModule} from "../modules/address/address.module";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule,
        CacheModule.forRoot(),
        IonicStorageModule.forRoot({
            name: 'SelfWork',
            driverOrder: ['indexeddb', 'sqlite', 'websql', 'localstorage'],
        }),
        AngularSvgIconModule.forRoot(),
        NgxMaskModule.forRoot(),
        BackButtonDisableModule.forRoot(),
        AuthModule,
        AddressModule
    ],
    providers: [
        ThemeDetection,
    ],
})
export class NativeModule {}
