import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(
        private toastController: ToastController,
        private router: Router
    ) {
    }

    async presentToast(message: string, duration = 3000, color = 'danger', position: 'bottom'|'middle'|'top' = 'bottom') {
        const getTop = await this.toastController.getTop();
        if (getTop) {
            this.toastController.dismiss();
        }
        const toast = await this.toastController.create({message, duration, color, position});
        toast.present();
    }

    async presentToastWithUrl(message: string, url, duration = 3000, color = 'danger') {
        const getTop = await this.toastController.getTop();
        if (getTop) {
            await this.toastController.dismiss();
        }
        const toast = await this.toastController.create({
            message, duration, color, buttons: [{
                text: 'Перейти', handler: () => {
                    this.router.navigateByUrl(url);
                }
            }]
        });
        setTimeout(async () => await toast.present(), 250);
    }

}
