<app-header [shadow]="true">
  <div slot="start"><app-back-button [present]="'modal'"></app-back-button></div>
  <div slot="center"><div class="title body_circe font_bold">Отзыв</div></div>
  <div slot="end"><div (click)="save()" class="body_secondary_circe save">Отправить</div>
  </div>
</app-header>
<ion-content [scrollY]="false" [forceOverscroll]="false">
  <div class="padding-20">
    <h5 class="title">Помогите Маркетплейсу стать лучше</h5>
    <div class="body_secondary_circe description">Нам жаль, что вам не понравилось работать в Маркетплейсе. Пожалуйста, оставьте подробный отзыв, чтобы мы смогли улучшить сервис.
    </div>
  </div>
  <div class="divider"></div>
  <div class="padding-20">
    <textarea class="about-text-textarea body_circe" [(ngModel)]="_editText" [placeholder]="'Напишите свои пожелания, замечания'" [minRows]="1" autosize></textarea>
    <span class="bar"></span>
    <span class="under-title caption_rubik">{{ _editText?.trim().length }}</span>
  </div>
</ion-content>
