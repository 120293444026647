import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { PlatformService } from './platform.service';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    loading;

    constructor(private platformService: PlatformService, private loadingController: LoadingController) {}

    // отображение ожидания загрузки
    async presentLoading(message: string) {
        this.loading = await this.loadingController.create({
            message,
        });
        return this.loading.present();
    }

    // закрыть отображение загрузки
    async dismissLoading() {
        return this.loading.dismiss();
    }
}
