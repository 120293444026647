import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-ui-header-user-info',
    templateUrl: './ui-header-user-info.component.html',
    styleUrls: ['./ui-header-user-info.component.scss'],
})
export class UiHeaderUserInfoComponent implements OnInit {
    @Input() name = 'Пользователь';

    @Input() lastName = '';

    @Input() lastOnline: string | Date = '2020-09-21T15:02:33.664Z';

    @Input() darkMode = false;

    @Input() arrow = false;
    @Input() arrowReverse = false;
    constructor() {}

    ngOnInit() {}
}
