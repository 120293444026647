import { Component, OnInit } from '@angular/core';
import { PlatformService } from '../../../core/services/platform.service';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit {
    constructor(private platformService: PlatformService) {}

    ngOnInit() {}

    isIos() {
        return this.platformService.isIos();
    }
}
