import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeGuard } from './page-modules/welcome/welcome.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'system',
        pathMatch: 'full',
    },
    {
        path: '',
        loadChildren: () => import('./routing-module/routing.module').then((m) => m.RoutingModule),
    },
    {
        path: 'task-add',
        loadChildren: () => import('./page-modules/task-add/task-add.module').then((m) => m.TaskAddPageModule),
    },
    {
        path: 'welcome',
        loadChildren: () => import('./page-modules/welcome/welcome.module').then((m) => m.WelcomePageModule),
    },
    {
        path: 'system',
        loadChildren: () => import('./page-modules/tabs/tabs.module').then((m) => m.TabsPageModule),
        canActivate: [WelcomeGuard],
    },
    {
        path: 'ad-detail/:id',
        loadChildren: () => import('./page-modules/ad-detail/ad-detail.module').then((m) => m.AdDetailPageModule),
    },
    {
        path: 'ad-offers/:id',
        loadChildren: () => import('./page-modules/ad-offers/ad-offers.module').then((m) => m.AdOffersPageModule),
    },
    {
        path: 'vacancy-offers/:id',
        loadChildren: () => import('./page-modules/vacancy-offers/vacancy-offers.module').then( m => m.VacancyOffersPageModule)
    },
    {
        path: 'user',
        loadChildren: () =>
            import('./page-modules/profile/pages/user-info/user-info.module').then((m) => m.UserInfoModule),
    },
    {
        path: 'offer-ad-selfworker/:user',
        loadChildren: () =>
            import('./page-modules/profile/pages/offer-ad-selfworker/offer-ad-selfworker.module').then(
                (m) => m.OfferAdSelfworkerPageModule,
            ),
    },
    {
        path: 'ads-created/:id',
        loadChildren: () => import('./page-modules/created-ads/created-ads.module').then((m) => m.CreatedAdsPageModule),
    },
    {
        path: 'ads-completed/:id',
        loadChildren: () =>
            import('./page-modules/completed-ads/completed-ads.module').then((m) => m.CompletedAdsPageModule),
    },
    {
        path: 'ad-edit/:id',
        loadChildren: () => import('./page-modules/ad-edit/ad-edit.module').then((m) => m.AdEditPageModule),
    },
    {
        path: 'checks',
        loadChildren: () =>
            import('./page-modules/account-check/account-check.module').then((m) => m.AccountCheckModule),
    },
    {
        path: 'address',
        loadChildren: () => import('./modules/address/address.module').then((m) => m.AddressModule),
    },
    {
        path: 'work-examples',
        loadChildren: () =>
            import('./page-modules/work-examples/work-examples.module').then((m) => m.WorkExamplesPageModule),
    },
    {
        path: 'profile-edit',
        loadChildren: () =>
            import('./page-modules/profile-edit/profile-edit.module').then((m) => m.ProfileEditPageModule),
    },
    {
        path: 'work-examples/:id',
        loadChildren: () =>
            import('./page-modules/work-examples/work-examples.module').then((m) => m.WorkExamplesPageModule),
    },
    {
        path: 'im',
        loadChildren: () => import('./page-modules/messenger/messenger.module').then((m) => m.MessengerModule),
    },
    {
        path: 'subscriptions',
        loadChildren: () =>
            import('./page-modules/subscriptions/subscriptions.module').then((m) => m.SubscriptionsPageModule),
    },
    {
        path: 'review-list/:user',
        loadChildren: () => import('./page-modules/review-list/review-list.module').then((m) => m.ReviewListPageModule),
    },
    {
        path: 'common-ads/:user',
        loadChildren: () => import('./page-modules/common-ads/common-ads.module').then((m) => m.CommonAdsPageModule),
    },
    {
        path: 'offer-add/:ad',
        loadChildren: () => import('./page-modules/offer-add/offer-add.module').then((m) => m.OfferAddPageModule),
    },
    {
        path: 'offer-vacancy-add/:ad',
        loadChildren: () => import('./page-modules/offer-vacancy-add/offer-vacancy-add.module').then( m => m.OfferVacancyAddPageModule)
    },
    {
        path: 'offer-edit/:ad/:offer',
        loadChildren: () => import('./page-modules/offer-add/offer-add.module').then((m) => m.OfferAddPageModule),
    },
    {
        path: 'offer-vacancy-edit/:ad/:offer',
        loadChildren: () => import('./page-modules/offer-vacancy-add/offer-vacancy-add.module').then( m => m.OfferVacancyAddPageModule)
    },
    {
        path: 'ad-select-done-selfworker/:ad/:offer',
        loadChildren: () =>
            import('./page-modules/ad-select-done-selfworker/ad-select-done-selfworker.module').then(
                (m) => m.AdSelectDoneSelfworkerPageModule,
            ),
    },
    {
        path: 'ad-select-done-customer/:ad/:offer',
        loadChildren: () =>
            import('./page-modules/ad-select-done-customer/ad-select-done-customer.module').then(
                (m) => m.AdSelectDoneCustomerPageModule,
            ),
    },
    {
        path: 'my-ads',
        loadChildren: () =>
            import('./page-modules/account-ad-list/account-ad-list.module').then((m) => m.AccountAdListModule),
    },
    {
        path: 'my-vacancies',
        loadChildren: () => import('./page-modules/my-vacancies/my-vacancies.module').then((m) => m.MyVacanciesPageModule)
    },
    {
        path: 'my-offers',
        loadChildren: () =>
            import('./page-modules/account-offers/account-offers.module').then((m) => m.AccountOffersPageModule),
    },
    {
        path: 'my-partners',
        loadChildren: () => import('./page-modules/account-partners/account-partners.module').then( m => m.AccountPartnersPageModule)
    },
    {
        path: 'be-executor',
        loadChildren: () => import('./modules/be-executor/be-executor.module').then((m) => m.BeExecutorModule),
    },
    {
        path: 'static',
        loadChildren: () => import('./page-modules/static/static.module').then((m) => m.StaticPagesModule),
    },
    {
      path: '**',
      redirectTo: 'system',
      pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
