import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-update-progress',
  templateUrl: './app-update-progress.component.html',
  styleUrls: ['./app-update-progress.component.scss'],
})
export class AppUpdateProgressComponent implements OnInit {

  @Input() progress: Observable<number>;
  @Input() indeterminate = false;

  constructor() { }

  ngOnInit() {}

}
