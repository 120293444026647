<div
    class="rating-number"
    [class.rating-number--small]="size === 'small'"
    [class.rating-number--normal]="size === 'normal'"
>
    <div class="rating-number__star">
        <app-ui-rating-star [size]="size" [active]="true"></app-ui-rating-star>
    </div>
    <div class="rating-number__text caption_circe font_bold">{{ rating(sum, count) }}</div>
</div>
