import { AfterViewInit, Component, ElementRef, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { TOffer } from '../../../core/offer/types/offer.type';
import { IAdDetail } from '../../../page-modules/ad-detail/types/ad-detail.interface';

@Component({
    selector: 'app-selfworker-offer-item',
    templateUrl: './selfworker-offer-item.component.html',
    styleUrls: ['./selfworker-offer-item.component.scss'],
})
export class SelfworkerOfferItemComponent implements AfterViewInit {
    @Input() user!: boolean;
    @Input() offer!: TOffer;

    @Input() ad!: IAdDetail;
    @Input() checkID: any;

    @Input() contact = false;

    @Input() offersOnlyText = false;
    @Input() showOffer = true;
    @Input() vacancyOffersBtn = false;

    @ViewChild('responseReadMore') responseReadMore!: ElementRef<HTMLElement>;

    showReadMore = false;

    toggleReadMore = false;

    @Input() allowEdit = false;

    @Output() editClick: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    editOffer() {
        this.editClick.emit();
    }

    checkTextHeight() {
        setTimeout(() => {
            if (this.responseReadMore) {
                this.showReadMore = this.responseReadMore.nativeElement.scrollHeight > 96;
            }
        }, 100);
    }

    async readMoreToggle() {
        this.toggleReadMore = !this.toggleReadMore;
        if (this.toggleReadMore) {
            this.responseReadMore.nativeElement.style.maxHeight =
                this.responseReadMore.nativeElement.scrollHeight + 'px';
        } else {
            this.responseReadMore.nativeElement.style.maxHeight = '96px';
        }
    }

    ngAfterViewInit() {
        this.checkTextHeight();
    }
}
