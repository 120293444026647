import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'statusColor',
})
export class StatusColorPipe implements PipeTransform {
    status = {
        open: '#007AFF',
        in_progress: '#FFD254',
        verification: '#007AFF',
        performed: '#00ADB5',
        not_performed: '#FF2D55',
        canceled: '#9DA6BA',
        archive: '#9DA6BA',
        rejected: '#FF2D55',
        arbitration: '#FF2D55',
    };

    transform(value: any): any {
        return this.status[value] ? this.status[value] : value;
    }
}
