<div class="container">
    <app-header [shadow]="false">
        <div slot="start">
            <app-back-button [func]="back()"></app-back-button>
        </div>
    </app-header>

        <div class="padding-20" style='display: flex;flex-direction: column;flex:1;height: auto;'>
            <h4 class="title">Вход</h4>
            <div class="input-container">
                <app-sz-input
                    [type]="'phone'"
                    [(value)]="phone"
                    [title]="'Телефон'"
                    [placeholder]="'Ваш телефон'"
                ></app-sz-input>
            </div>

            <div class="body_secondary_circe description" (click)="toSignUp()">
                Нет аккаунта? <span class="accent">Зарегистрироваться</span>
            </div>

            <div class="fixed-button bottom-safe-area" style='margin-top: auto'>
                <div class="button-container">
                    <app-button data-cy=auth-button-login (btnClick)="sendPhone()" [class]="'primary'" [disabled]="!phoneVaild() || loading"
                        >Войти
                    </app-button>
                </div>
            </div>
        </div>
</div>
