<div class="container">
    <app-header [shadow]="false">
        <div slot="start">
            <app-back-button [func]="back()"></app-back-button>
        </div>
    </app-header>

    <div>
        <div class="padding-20">
            <h4 class="title">Введите код из смс</h4>
            <div class="input-container">
                <app-sz-input
                    #inputCode
                    [disabled]="disableInput || loading"
                    [valid]="valid"
                    (valueChange)="checkCode($event)"
                    (click)="checkCode(this.code)"
                    [type]="'code'"
                    [(value)]="code"
                    [title]="'Код'"
                    [placeholder]="''"
                ></app-sz-input>
                <ion-spinner [hidden]="!loading" style="float: right; position: relative; bottom: 35px;"></ion-spinner>
            </div>

            <div class="bottom-container">
                <div *ngIf="valid" class="sms-text body_secondary_circe">Введите код из СМС.</div>
                <div *ngIf="!valid" class="sms-text-error body_secondary_circe">Вы ввели неверный код</div>

                <div *ngIf="timeLeft > 0" class="sms-replay body_secondary_circe">
                    Запросить повторно можно через 00:{{ this.timeLeft < 10 ? '0' + timeLeft : timeLeft }}
                </div>
                <div *ngIf="timeLeft <= 0" class="sms-replay re-sms body_secondary_circe" (click)="reSms()">
                    Запросить повторно
                </div>
            </div>
        </div>
    </div>
</div>
