import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-modal-text-editor',
    templateUrl: './modal-text-editor.component.html',
    styleUrls: ['./modal-text-editor.component.scss'],
})
export class ModalTextEditorComponent implements OnInit, AfterViewInit {

    @Input() minLength = 10;
    @Input() maxLength = 4000;

    _editText = '';
    textareaElement: any;

    @ViewChild('textareaEl') set textareaEl(el: any) {
        this.textareaElement = el;
        this.focus();
    }

    @Input() set editText(text: string) {
        this._editText = text ? text : '';
        this.focus();
    }

    constructor(private modalController: ModalController) {
    }

    ngOnInit() {
    }

    focus() {
        if (this.textareaElement) {
            setTimeout(() => {
                this.textareaElement.nativeElement.focus();
            }, 250);
        }
    }

    ngAfterViewInit() {
    }

    save() {
        this.back(this._editText);
    }

    back(save?: string | boolean) {
        this.modalController.dismiss(save);
    }
}
