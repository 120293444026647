import { Injectable } from '@angular/core';
import { CategoryService } from './category.service';
import { first } from 'rxjs/operators';
import { AccountService } from '../account/account.service';
import { AuthenticationService } from '../auth/authentication.service';
import { SocketService } from '../api/socket.service';
import { DialogController } from '../../page-modules/messenger/dialog.controller';
import { MessengerService } from '../../page-modules/messenger/messenger.service';
import { PushService } from '../push/push.service';
import { PreloadService } from './preload.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { StatsService } from '../stats/stats.service';
import { CitiesService } from '../cities/cities.service';
import { NotificationService } from '../../module/notification/services/notification.service';
import { StatusBarService } from './status-bar.service';
import { KeyboardFixService } from '../helpers/keyboard-fix.service';
import { BackButtonAndroidHelper } from '../helpers/back-button-android.helper';
import { AppVersionService } from './app-version.service';
import { loadScript } from '../utils/helpers';
import { PlatformService } from './platform.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class InitService {
    constructor(
        private platformService: PlatformService,
        private readonly accountService: AccountService,
        private readonly authenticationService: AuthenticationService,
        private readonly categoryService: CategoryService,
        private readonly citiesService: CitiesService,
        private readonly socketService: SocketService,
        private readonly dialogController: DialogController,
        private readonly messengerService: MessengerService,
        private readonly pushService: PushService,
        private readonly preloadService: PreloadService,
        private readonly analytics: AnalyticsService,
        private statusBarService: StatusBarService,
        private keyboardFixService: KeyboardFixService,
        private backButtonAndroidHelper: BackButtonAndroidHelper,
        private appVersionService: AppVersionService,
        private statsService: StatsService,

        private readonly notificationService: NotificationService,
    ) {}

    async startNonAuth() {
        this.backButtonAndroidHelper.init();
        this.keyboardFixService.init();
        this.appVersionService.checkUpdate();
        if (this.platformService.isCordova()){
            this.statusBarService.init();
            this.analytics.init();
        }
        return await Promise.all([
            this.preloadService.init(),
            this.categoryService.getCategories().pipe(first()).toPromise(),
        ]);
    }

    startWithAuth() {
        this.authenticationService.watchAuthState$().subscribe(async (auth) => {
            if (auth) {
                if (this.platformService.isCordova()){
                    this.analytics.setUserID(auth.user);
                    this.pushService.init();
                }
                this.statsService.setOnlineFirst();
                await this.accountService.loadState();
                await this.socketService.initSocket(auth.token);
                this.messengerService.init();
                this.dialogController.init();

                this.notificationService.init();
            }
        });
    }
}
