import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
})
export class RadioComponent implements OnInit {
    @Input() name: any;
    @Input() text: any;
    @Input() value: any;
    @Input() checked = false;
    @Input() disabled = false;

    @Output() btnClick: EventEmitter<any> = new EventEmitter<any>(false);

    constructor() {}

    ngOnInit() {}

    click(value: any) {
        this.btnClick.emit(value);
    }
}
