export enum AnalyticsEventNameEnum {
    LOGIN = 'login',
    SIGN_UP = 'sign_up',

    OPEN_OFFER_MODAL = 'open_offer_modal',
    CREATE_OFFER = 'open_offer',

    OPEN_SUPPORT = 'open_support',

    CONNECT_WALLET_SUCCESS = 'connect_wallet_success',
    CONNECT_WALLET_ERROR = 'connect_wallet_success',

    AD_SET_CATEGORY = 'ad_set_category',
    AD_SET_SUBCATEGORY = 'ad_set_subcategory',
    AD_SET_NAME = 'ad_set_name',
    AD_SET_ADDRESS = 'ad_set_address',
    AD_SET_PRICE = 'ad_set_price',
    AD_SET_DESCRIPTION = 'ad_set_description',
    AD_SET_AUTH = 'ad_set_description',
    AD_SET_SMS = 'ad_set_description',


    AD_CREATED = 'ad_created',
    AD_COMPLETED = 'ad_completed',
    AD_REJECTED = 'ad_rejected',
    AD_CANCELED = 'ad_canceled',
    AD_ARCHIVED = 'ad_archived',
    AD_CLOSE = 'ad_close',

    INVOICE_CREATED = 'invoice_created',

    MESSENGER_SEND = 'messenger_send',

    REVIEW_CREATED = 'review_created',

    SEND_COMPLAINT = 'send_complaint',

    WELCOME_LOGIN = 'welcome_login',
    WELCOME_SKIP = 'welcome_skip',

}

