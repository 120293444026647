import { Component, Input, OnInit } from '@angular/core';
import { TUserStats } from '../../../core/user/types/user-stats.type';

@Component({
    selector: 'app-ui-sw-item-stat',
    templateUrl: './ui-sw-item-stat.component.html',
    styleUrls: ['./ui-sw-item-stat.component.scss'],
})
export class UiSwItemStatComponent implements OnInit {
    @Input() profileStat!: TUserStats;

    constructor() {}

    ngOnInit() {}
}
