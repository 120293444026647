import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TMessage } from './types/message.type';
import { concatMap, filter, first, map, share, shareReplay } from 'rxjs/operators';
import { ApiService } from '../../core/api/api.service';
import { AuthenticationService } from '../../core/auth/authentication.service';
import { SocketService } from '../../core/api/socket.service';
import { IMessageEvent } from './interfaces/message-event.interface';
import { GetConversationsDto } from './dto/get-conversations.dto';
import { IConversationState } from './interfaces/conversation-state.interface';
import { AnalyticsService } from '../../core/analytics/analytics.service';
import { AnalyticsEventNameEnum } from '../../core/analytics/enums/analytics-event-name.enum';
import { AnalyticsEventCategoryEnum } from '../../core/analytics/enums/analytics-event-category.enum';

@Injectable({
    providedIn: 'root',
})
export class MessengerService {
    messageState: BehaviorSubject<Map<string, TMessage>> = new BehaviorSubject(new Map());
    private socketMessageEvents$: Observable<IMessageEvent[]>;

    constructor(
        private readonly apiService: ApiService,
        private readonly authService: AuthenticationService,
        private readonly socketService: SocketService,
        private readonly analyticsService: AnalyticsService,
    ) {}

    public init() {
        this.socketMessageEvents$ = this.socketService.onUserMessageEvents().pipe(share());
        this.initMessageEvents();
    }

    chatEvents$(conversationID?: string): Observable<IMessageEvent> {
        return this.socketMessageEvents$.pipe(
            // Разбиваем массив на элементы
            concatMap((x) => x),
            filter((mEvent) => {
                return conversationID ? mEvent.message.conversationID === conversationID : true;
            }),
        );
    }

    markMessageRead(messageIDs) {
        this.apiService.markMessageRead({ messageIDs }).pipe(first()).toPromise();
    }

    getChatMessages(conversationID: string): Observable<TMessage[]> {
        return this.messageState.asObservable().pipe(
            map((messages) =>
                Array.from(messages.values())
                    .filter((message) => message.conversationID === conversationID)
                    .sort((m1, m2) => (m1.dateCreate >= m2.dateCreate ? 1 : -1)),
            ),
        );
    }

    async loadChatHistory(conversationID, lastMessageID?) {
        const messages = await this.apiService
            .getMessageHistory({
                conversationID,
                lastMessageID,
                count: 20,
            })
            .pipe(first())
            .toPromise();

        await this.addToState(
            messages.map((message) => ({
                type: 'add',
                message,
            })),
        );
    }

    async send(text: string, peerID: string) {
        const messageText = text.trim();

        this.analyticsService.sendEvent({
            action: AnalyticsEventNameEnum.MESSENGER_SEND,
            category: AnalyticsEventCategoryEnum.AD_TASK,
            page: 'messenger',
        });
        this.apiService
            .sendMessage({
                peerID,
                text: messageText,
            })
            .subscribe();
    }

    getConversations(getConversationsDto: GetConversationsDto): Observable<IConversationState> {
        return this.apiService.getConversations(getConversationsDto);
    }

    private async addToState(events: IMessageEvent[]) {
        // Получение текущего состояния
        const curValue = this.messageState.getValue() || new Map();

        // Обработка эвентов
        for (const event of events) {
            if (event.type === 'add') {
                curValue.set(event.message._id, {
                    ...event.message,
                    out: event.message.fromID === this.authService.getAccountID(),
                });
            }

            if (event.type === 'update') {
                curValue.set(event.message._id, {
                    ...event.message,
                    out: event.message.fromID === this.authService.getAccountID(),
                });
            }
        }

        // Обновление состояния
        this.messageState.next(curValue);
    }

    private initMessageEvents() {
        this.socketMessageEvents$.subscribe((events: IMessageEvent[]) => {
            this.addToState(events);
        });
    }
}
