<svg-icon
    class="ico"
    [src]="'/assets/icons/' + name + '.svg'"
    [svgStyle]="{
        'width.px': width ? width : '',
        'height.px': height ? height : '',
        'stroke': stroke ? stroke : '',
        'fill': fill ? fill : ''
    }"
    [ngStyle]="{ width: width ? width + 'px' : '', height: height ? height + 'px' : '' }"
></svg-icon>
