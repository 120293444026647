import { Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class CoreService {
    constructor(private alertController: AlertController, private router: Router, private navCon: NavController) {}

    // Ограничение кол-ва символов в поле данных
    public static trimField(data: any, field: string, count: number) {
        if (data[field]) {
            if (data[field].length > count) {
                data[field] = data[field].slice(0, count) + '...';
            }
        }
    }

    // диалоговое окно с ошибкой
    async presentAlert(msg: string) {
        const alert = await this.alertController.create({
            header: 'Ошибка',
            message: msg,
            buttons: ['OK'],
        });

        await alert.present();
    }

    async goStart() {
        return this.navCon.navigateRoot(['/system/start', true]);
    }

    fileToBase64(file): Promise<string> {
        return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
        });
    }

    base64toFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }
}
