import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { first, map, take } from 'rxjs/operators';

import { Observable, throwError } from 'rxjs';
import { ICategory } from '../category/interface/category';
import { IAdItem } from '../ad/interface/ad-items.interface';
import { IAd } from '../ad/interface/ad.interface';
import { ISignUpSmsDto } from '../auth/dto/sign-up-sms.dto';
import { ISignInSmsDto } from '../auth/dto/sign-in-sms.dto';
import { TAuthToken } from '../auth/types/auth-token.type';
import { TSmsToken } from '../sms/sms.service';
import { IGetSmsDto } from '../sms/dto/get-sms.dto';
import { CreateAdDto } from '../../page-modules/task-add/dto/create-ad.dto';
import { TAccountUser } from '../account/types/account-user.type';
import { CreateOfferDto } from '../offer/dto/create-offer.dto';
import { UpdateOfferDto } from '../offer/dto/update-offer.dto';
import { TOffer } from '../offer/types/offer.type';
import { TOfferID } from '../offer/types/offer-id.type';
import { createHttpGetParams } from '../utils/http/create-http-get-params';
import { TAdID } from '../ad/types/ad-id.type';
import { IAdDetail } from '../../page-modules/ad-detail/types/ad-detail.interface';
import { GetWorkExampleDto } from '../work-example/dto/get-work-example.dto';
import { TExampleWork } from '../work-example/types/example-work.type';
import { CreateExampleWorkDto } from '../work-example/dto/create-example-work.dto';
import { EditAccountAdDto } from '../account/dto/account-ad-edit.dto';
import { DeleteExampleWorkDto } from '../work-example/dto/delete-example-work.dto';
import { ToastService } from '../services/toast.service';
import { SendMessageDto } from '../../page-modules/messenger/dto/send-message.dto';
import { TMessage } from '../../page-modules/messenger/types/message.type';
import { GetChatHistoryDto } from '../../page-modules/messenger/dto/get-chat-history.dto';
import { GetConversationsDto } from '../../page-modules/messenger/dto/get-conversations.dto';
import { IConversationState } from '../../page-modules/messenger/interfaces/conversation-state.interface';
import { MarkMessagesReadDto } from '../../page-modules/messenger/dto/mark-messages-read.dto';
import { ICheckListItem } from '../checks/interfaces/check.interface';
import { ISelfworkerItem } from '../selfworkers/interfaces/selfworkers.interface';
import { CreateReviewDto } from '../review/dto/create-review.dto';
import { TReviewListItem } from '../../page-modules/review-list/types/review-list-item.type';
import { ICheck } from '../checks/interfaces/check-detail.interface';
import { RegisterDeviceDto } from '../push/dto/register-device.dto';
import { UnregisterDeviceDto } from '../push/dto/unregister-device.dto';
import { SignInWalletDto } from '../wallet/signInWallet.dto';
import { UpdateNotifySettingsDto } from '../shared/dto/update-notify-settings.dto';
import { CreateComplaintDto } from '../../page-modules/conplaint/dto/create-complaint.dto';
import { AdStatusEnum } from '../ad/enums/ad-status.enum';
import { ICity } from '../cities/types/city.interface';
import { IInvoiceData } from '../../modules/invoice/interfaces/invoice-data.interface';
import { IInvoice } from '../../modules/invoice/interfaces/invoice.interface';
import { IPartner } from '../partner/partner.interface';

export interface IAppResponse<T> {
    count?: number;
    items: any[];
}

export interface IResult<T = any> {
    message: string;
    status: 'err' | 'ok';
    result: T;
}

export interface IResultToken extends IResult {
    token?: TAuthToken;
}

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    API_URL = environment.API_URL;

    constructor(private http: HttpClient, private toastService: ToastService) {
    }

    getInternetConnection() {
        if (navigator.onLine) {
            return true;
        } else {
            this.toastService.presentToast('Ошибка соединения');
            return throwError('Ошибка соединения');
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    // Auth

    signUp(signUpSmsDto: ISignUpSmsDto): Promise<IResultToken> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http
            .post<IResultToken>(`${this.API_URL}/auth/signUp`, signUpSmsDto)
            .pipe(take(1))
            .pipe(
                map((result) => {
                    return result;
                }),
            )
            .toPromise();
    }

    signIn(signInSmsDto: ISignInSmsDto): Promise<IResultToken> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http
            .post<IResultToken>(`${this.API_URL}/auth/signIn`, signInSmsDto)
            .pipe(take(1))
            .pipe(
                map((result) => {
                    return result;
                }),
            )
            .toPromise();
    }

    getUserExists(phone: string) {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http
            .get<IAdItem[]>(`${this.API_URL}/user/exists`, { params: { phone } })
            .pipe(first())
            .toPromise();
    }

    ////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////
    // SMS

    getSms(getSmsDto: IGetSmsDto): Observable<TSmsToken> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http
            .post<{ token: string }>(`${this.API_URL}/sms/getSms`, {phone: getSmsDto.phone})
            .pipe(first())
            .pipe(
                map(({ token }) => {
                    return token;
                }),
            );
    }

  getSmsWithCaptchaToken(getSmsDto: IGetSmsDto): Observable<TSmsToken> {
    if (!this.getInternetConnection()) {
      return;
    }
    return this.http
      .post<{ token: string }>(`${this.API_URL}/sms/getSmsAuth`, {phone: getSmsDto.phone}, {
        headers: new HttpHeaders({
          'ycaptcha': getSmsDto.captchaToken,
        })
      })
      .pipe(first())
      .pipe(
        map(({ token }) => {
          return token;
        }),
      );
  }

    ////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////
    // Upload img

    async uploadImageToServer(formData) {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.post<string>(`${this.API_URL}/image/upload`, formData).pipe(take(1)).toPromise();
    }

    ////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////
    // Ad - items

    getAdItems(params): Observable<IAppResponse<IAdItem>> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.post<IAppResponse<IAdItem>>(`${this.API_URL}/ad-items`, params).pipe(first());
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    // Ad - markers

    getAdMarkers(params): Observable<IAppResponse<IAdItem>> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.post<IAppResponse<IAdItem>>(`${this.API_URL}/ad-markers/`, params);
    }

    ////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////
    // Selfworkers - items

    getSelfworkerItems(params): Observable<IAppResponse<ISelfworkerItem>> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.post<IAppResponse<ISelfworkerItem>>(`${this.API_URL}/sw-items`, params).pipe(first());
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    // Selfworkers - markers

    getSelfworkerMarkers(params): Observable<IAppResponse<ISelfworkerItem>> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.post<IAppResponse<ISelfworkerItem>>(`${this.API_URL}/sw-markers/`, params);
    }

    ////////////////////////////////////////////////////////////////////////////////////////

    // Ad - detail

    getAdDetail(id: TAdID): Observable<IAdDetail> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.get<IAdDetail>(`${this.API_URL}/ad-detail/${id}`).pipe(first());
    }

    cancelAd(id: TAdID): Promise<IAdDetail> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http
            .post<IAdDetail>(`${this.API_URL}/ad-detail/${id}/cancel`, {})
            .pipe(first())
            .toPromise();
    }

    archiveAd(id: TAdID): Promise<IAdDetail> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http
            .post<IAdDetail>(`${this.API_URL}/ad-detail/${id}/archive`, {})
            .pipe(first())
            .toPromise();
    }

    rejectAdExecutor(adID, text) {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http
            .post<IResult>(`${this.API_URL}/ad-detail/${adID}/executorReject`, { text })
            .pipe(first())
            .toPromise();
    }

    rejectAdCustomer(adID, text) {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http
            .post<IResult>(`${this.API_URL}/ad-detail/${adID}/customerReject`, { text })
            .pipe(first())
            .toPromise();
    }

    createInvoice(adID: string, data: IInvoiceData) {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http
            .post<IResult>(`${this.API_URL}/ad-detail/${adID}/createInvoice`, { ...data })
            .pipe(first())
            .toPromise();
    }

    rejectInvoice(adID: string, invoiceID: string) {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http
            .post<IResult>(`${this.API_URL}/ad-detail/${adID}/rejectInvoice`, { invoiceID })
            .pipe(first())
            .toPromise();
    }

    approveInvoice(adID: string, invoiceID: string) {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http
            .post<IResult>(`${this.API_URL}/ad-detail/${adID}/approveInvoice`, { invoiceID })
            .pipe(first())
            .toPromise();
    }

    getInvoices(adID: string): Promise<IInvoice[]> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.post<IInvoice[]>(`${this.API_URL}/invoice/${adID}/get`, {})
            .pipe(first())
            .toPromise();
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    // Ad

    getAdById(id: TAdID): Promise<IAdDetail> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.get<IAdDetail>(`${this.API_URL}/account/ad/${id}`).pipe(first()).toPromise();
    }

    deleteAd(id) {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.delete(`${this.API_URL}/account/ad/${id}`).pipe(first()).toPromise();
    }

    editAd(id, ad): Promise<EditAccountAdDto> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.patch<EditAccountAdDto>(`${this.API_URL}/account/ad/${id}`, ad).pipe(first()).toPromise();
    }

    ////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////
    // Cities

    getCities(): Observable<ICity[]> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.get<IResult<ICity[]>>(`${this.API_URL}/region`).pipe(
            map(({ result }) => {
                return result;
            }),
        );
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    // Categories

    getCategories(): Observable<ICategory[]> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.get<IAppResponse<ICategory>>(`${this.API_URL}/category`).pipe(
            map(({ items }) => {
                return items;
            }),
        );
    }

    ////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////
    // Account

    getAccount(): Observable<TAccountUser> {
        return this.http.get<TAccountUser>(`${this.API_URL}/account`);
    }

    updateAccount(user: any): Promise<any> {
        return this.http.patch<any>(`${this.API_URL}/account/updateInfo`, user).pipe(first()).toPromise();
    }

    updatePhone(smsCredentials: ISignInSmsDto): Promise<any> {
        return this.http.post<any>(`${this.API_URL}/account/updatePhone`, smsCredentials).pipe(first()).toPromise();
    }

    deleteAccount(): Promise<any> {
        return this.http.post<any>(`${this.API_URL}/account/deleteAccount`, {}).pipe(first()).toPromise();
    }

    updateNotifySettings(updateNotifySettingsDto: UpdateNotifySettingsDto): Promise<any> {
        return this.http
            .post<any>(`${this.API_URL}/account/updateNotifySettings`, updateNotifySettingsDto)
            .pipe(take(1))
            .toPromise();
    }

    updateSubscriptions(subscriptions) {
        return this.http
            .post<any>(`${this.API_URL}/account/subscriptions`, { userSubscription: subscriptions })
            .pipe(take(1))
            .toPromise();
    }

    createAccountAd(createAdDto: CreateAdDto): Promise<IAd> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.post<IAd>(`${this.API_URL}/account/ad`, createAdDto).pipe(first()).toPromise();
    }

    getAccountAd(params, type?: 'customer' | 'executor' | null, commonUserID?: string | null): Observable<IAdItem[]> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http
            .get<IAppResponse<IAdItem>>(
                `${this.API_URL}/account/ads` +
                `?limit=${params.limit}` +
                `${type ? `&type=${type}` : ''}` +
                `${commonUserID ? `&userID=${commonUserID}` : ''}` +
                `${params.status ? `&status=${params.status}` : ''}`,
            )
            .pipe(
                map(({ items }) => {
                    return items;
                }),
            );
    }

    getAccountOffers() {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http
            .get<IAppResponse<IAdItem>>(`${this.API_URL}/account-offers`)
            .pipe(first())
            .pipe(
                map(({ items }) => {
                    return items;
                }),
            );
    }

    setOnline() {
        return this.http.post<IResult>(`${this.API_URL}/account/setOnline`, {}).pipe(first()).toPromise();
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    // User

    getUserById(id: string) {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.get<TAccountUser>(`${this.API_URL}/profile/${id}`).pipe(first());
    }

    suggestAd(user: string, adID: string) {
        if (!this.getInternetConnection()) {
            return;
        }
        const params = createHttpGetParams({ adID });
        return this.http
            .get<TAccountUser>(`${this.API_URL}/profile/${user}/suggestAd`, { params })
            .pipe(take(1))
            .toPromise();
    }

    getCreatedAds(id: string) {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.get<IAdItem[]>(`${this.API_URL}/profile/${id}/createdAd`).pipe(first());
    }

    getCompletedAds(id: string) {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.get<IAdItem[]>(`${this.API_URL}/profile/${id}/completedAd`).pipe(first());
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    // Example Work

    getExampleWork(getWorkExampleDto: GetWorkExampleDto): Observable<TExampleWork[]> {
        if (!this.getInternetConnection()) {
            return;
        }
        const params = createHttpGetParams(getWorkExampleDto);
        return this.http
            .get<IAppResponse<IAdItem>>(`${this.API_URL}/example-work/`, { params })
            .pipe(
                map(({ items }) => {
                    return items;
                }),
            );
    }

    createExampleWork(createExampleWork: CreateExampleWorkDto) {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.post<TExampleWork>(`${this.API_URL}/example-work`, createExampleWork);
    }

    deleteExampleWorks(deleteExampleWorkDto: DeleteExampleWorkDto) {
        if (!this.getInternetConnection()) {
            return;
        }
        const ids = deleteExampleWorkDto.imageIDs.join();
        const params = createHttpGetParams({ imageIDs: ids });
        return this.http.delete<IResult>(`${this.API_URL}/example-work`, { params });
    }

    ////////////////////////////////////////////////////////////////////////////////////////

    // AppReview
    createAppReview(data): Promise<IResult> {
        return this.http.post<IResult>(`${this.API_URL}/app-review/create`, data).pipe(first()).toPromise();
    }

    ////////////////////////////////////////////////////////////////////////////////////////

    // Review
    createReview(createReviewDto: CreateReviewDto): Promise<IResult> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.post<IResult>(`${this.API_URL}/reviews`, createReviewDto).pipe(first()).toPromise();
    }

    getReviews(userId, limit?): Promise<TReviewListItem[]> {
        if (!this.getInternetConnection()) {
            return;
        }
        const params: any = {};
        if (limit > 0) {
            params.limit = limit;
        }
        return this.http
            .get<TReviewListItem[]>(`${this.API_URL}/reviews/${userId}`, { params })
            .pipe(take(1))
            .toPromise();
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    // Offer

    createOffer(createOfferDto: CreateOfferDto): Promise<TOffer> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.post<TOffer>(`${this.API_URL}/offer`, createOfferDto).pipe(first()).toPromise();
    }

    getOfferById(id: TOfferID): Promise<TOffer> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.get<TOffer>(`${this.API_URL}/offer/${id}`).pipe(first()).toPromise();
    }

    getOffers(
        adID: TOfferID,
    ): Promise<IResult<{
        adStatus: AdStatusEnum;
        items: TOffer[];
    }>> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http
            .get<IResult<{
                adStatus: AdStatusEnum;
                items: TOffer[];
            }>>(`${this.API_URL}/ad-detail/${adID}/offers`)
            .pipe(first())
            .toPromise();
    }

    selectOffer(adID: string, offerID: string): Promise<TOffer[]> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http
            .get<TOffer[]>(`${this.API_URL}/ad-detail/${adID}/offers/${offerID}/select`)
            .pipe(first())
            .toPromise();
    }

    updateOffer(id: TOfferID, updateOfferDto: UpdateOfferDto): Promise<IResult> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.patch<IResult>(`${this.API_URL}/offer/${id}`, updateOfferDto).pipe(first()).toPromise();
    }

    rejectOffer(id: TOfferID): Promise<IResult> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.patch<IResult>(`${this.API_URL}/offer/${id}/reject`, {}).pipe(first()).toPromise();
    }

    deleteOffer(id: TOfferID): Promise<IResult> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.delete<IResult>(`${this.API_URL}/offer/${id}`).pipe(first()).toPromise();
    }

    ////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////
    // Messages

    sendMessage(sendMessageDto: SendMessageDto) {
        return this.http.post<TMessage>(`${this.API_URL}/messages/send`, sendMessageDto);
    }

    markMessageRead(markMessagesReadDto: MarkMessagesReadDto) {
        return this.http.post<IResult>(`${this.API_URL}/messages/markRead`, markMessagesReadDto);
    }

    getMessageHistory(getChatHistoryDto: GetChatHistoryDto) {
        const params = createHttpGetParams(getChatHistoryDto);
        return this.http.get<TMessage[]>(`${this.API_URL}/messages/`, { params });
    }

    getConversations(getConversationsDto: GetConversationsDto) {
        const params = createHttpGetParams(getConversationsDto);
        return this.http.get<IConversationState>(`${this.API_URL}/conversation/`, { params });
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    // Checks

    getChecks() {
        return this.http.get<ICheckListItem[]>(`${this.API_URL}/check/`);
    }

    getCheckById(id) {
        return this.http.get<ICheck>(`${this.API_URL}/check/${id}`);
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    // Notifications

    markNotificationRead(): Promise<IResult> {
        return this.http.post<IResult>(`${this.API_URL}/notification/markRead`, {}).pipe(take(1)).toPromise();
    }

    ////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////
    // Push
    registerDevice(registerDeviceDto: RegisterDeviceDto): Promise<IResult> {
        return this.http
            .post<IResult>(`${this.API_URL}/account/registerDevice`, registerDeviceDto)
            .pipe(take(1))
            .toPromise();
    }

    unregisterDevice(unregisterDevice: UnregisterDeviceDto): Promise<IResult> {
        return this.http
            .post<IResult>(`${this.API_URL}/account/unregisterDevice`, unregisterDevice)
            .pipe(take(1))
            .toPromise();
    }

    ////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////
    // Wallet
    connectWallet(params: SignInWalletDto): Promise<IResult> {
        return this.http.post<IResult>(`${this.API_URL}/wallet/connect`, params).pipe(first()).toPromise();
    }

    disconnectWallet(): Promise<IResult> {
        return this.http.put<IResult>(`${this.API_URL}/wallet/disconnect`, {}).pipe(first()).toPromise();
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    // Address
    suggestAddress(query: string): Promise<IResult> {
        return this.http
            .post<any>(`${this.API_URL}/geocoder/suggest`, { query })
            .pipe(take(1))
            .toPromise();
    }

    geolocateAddress({ lat, lon }): Promise<IResult> {
        return this.http
            .post<any>(`${this.API_URL}/geocoder/geolocate`, { lat, lon })
            .pipe(take(1))
            .toPromise();
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    // Address

    sendComplaint(createComplaintDto: CreateComplaintDto): Promise<IResult> {
        return this.http.post<any>(`${this.API_URL}/complaint`, createComplaintDto).pipe(take(1)).toPromise();
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    // Partners
    sendBusinessRequest(selfworkerID): Promise<IResult> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.post<IResult>(`${this.API_URL}/wallet/sendPartnerRequest`, { selfworkerID }).pipe(first()).toPromise();
    }

    acceptPartner({requestID, organisationID, card}): Promise<IResult> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.post<IResult>(`${this.API_URL}/wallet/acceptPartner`, {
            requestID,
            organisationID,
            card,
        }).pipe(first()).toPromise();
    }

    rejectPartner({requestID, organisationID}): Promise<IResult> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.post<IResult>(`${this.API_URL}/wallet/rejectPartner`, { requestID, organisationID }).pipe(first()).toPromise();
    }

    getPartners(): Promise<IResult<IPartner[]>> {
        if (!this.getInternetConnection()) {
            return;
        }
        return this.http.get<IResult<IPartner[]>>(`${this.API_URL}/wallet/getPartners`).pipe(first()).toPromise();
    }

    // Partners
    ////////////////////////////////////////////////////////////////////////////////////////

}
