import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    url = environment.API_URL;

    constructor(private authService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.getAccessToken().pipe(
            switchMap((token) => {
                if (token && this.authService.isAcceptRequest(request)) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                }
                return next.handle(request);
            }),
        );
    }
}
