<div *ngIf="show" class="container">
    <div class="container_top">
        <div class="text">
            <div class="title sub_circe main-text font_bold">Ищите исполнителя?</div>
            <div class="body_secondary_circe main-text">
                Создайте задание и в откликах на него выберите подходящего специалиста.
            </div>
        </div>
        <img class="background" src="/assets/pictures/create_task_top.png">
    </div>
    <div class="caption_circe link">Создать задание</div>
</div>
