<div class="selfworker-offer-item" *ngIf="offer || checkID || showOffer">
    <div class="field_item" [style.paddingTop]="user ? '0' : ''" *ngIf="user">
        <app-ui-review-profile-item
            [arrow]="false"
            [contact]="contact"
            [profile]="offer.profile"
        ></app-ui-review-profile-item>
    </div>

    <ng-container *ngIf="offer && showOffer">
        <div class="edit-button" *ngIf="allowEdit" (click)="editOffer()">
            <app-svg-icon [name]="'pen'" [stroke]="'var(--ion-color-accent)'"></app-svg-icon>
        </div>
        <div class="price-container" *ngIf="offer.price">
            <div class="field_item">
                <div class="title caption_circe">{{ user ? '' : 'Ваше ' }} предложение</div>
                <div class="value body_circe">{{ offer.price.toLocaleString('ru') }} ₽</div>
            </div>
        </div>
        <div class="field_item">
            <div class="title caption_circe" *ngIf="offer.description">Отклик</div>
            <div class="title body_circe" *ngIf="!offer.description">Вы откликнулись без информации</div>
            <div
                class="value response body_circe"
                #responseReadMore
                [innerHTML]="offer.description | linkify | sanitizeHtml"
            ></div>
            <div *ngIf="showReadMore" class="additional_container">
                <div class="shadow" [class.hideShadow]="toggleReadMore"></div>
                <div class="readmore">
                    <div class="caption_circe chip" (click)="readMoreToggle()">
                        {{ !toggleReadMore ? 'Читать полностью' : 'Свернуть' }}
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <div *ngIf="checkID" class="check" [routerLink]="['/ad-select-done-customer', ad._id, ad.offer._id]" [state]="{performed: true, checkID: ad.checkID}">
        <app-svg-icon [name]="'ads'" [fill]="'var(--ion-color-accent)'"></app-svg-icon>
        <div class="name body_circe">
            Отчет о работе
        </div>
        <div class="arrow">
            <app-svg-icon [name]="'right-arrow'" [stroke]="'var(--ion-color-additional-text)'"></app-svg-icon>
        </div>
    </div>

    <div *ngIf="vacancyOffersBtn" class="check" [routerLink]="['/vacancy-offers', ad._id]">
        <app-svg-icon [name]="'ads'" [fill]="'var(--ion-color-accent)'"></app-svg-icon>
        <div class="name body_circe">
            Посмотреть отклики
        </div>
        <div class="arrow">
            <app-svg-icon [name]="'right-arrow'" [stroke]="'var(--ion-color-additional-text)'"></app-svg-icon>
        </div>
    </div>

    <div *ngIf="offersOnlyText" class="check">
        <div class="name body_circe">
            Предложения исполнителей
        </div>
        <div class="arrow">
            <app-svg-icon [name]="'right-arrow'" [stroke]="'var(--ion-color-additional-text)'"></app-svg-icon>
        </div>
    </div>
</div>
