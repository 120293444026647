import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class AlertService {

    constructor(private alertController: AlertController) {
    }

    async showAlert(message: string, buttons: any = ['OK'], header?: string, backdropDismiss = true) {
        return new Promise(async (resolve, reject) => {
            const alertController = await this.alertController.create({
                header,
                message,
                buttons,
                backdropDismiss,
                cssClass: 'alert'
            });
            await alertController.present();
            alertController.onDidDismiss().then((data) => {
                if (data.role !== 'cancel' && data.role !== 'backdrop') {
                    resolve({ data });
                } else {
                    reject(false);
                }
            });
        });
    }

    async getTop() {
        return await this.alertController.getTop();
    }

    async dismiss(data?) {
        return this.alertController.dismiss(data ? data : null);
    }


}
