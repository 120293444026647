import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthNavigationService} from '../../auth-navigation.service';
import {SmsService} from '../../../../core/sms/sms.service';
import {EMPTY, Subject} from 'rxjs';
import {catchError, first, takeUntil} from 'rxjs/operators';
import {AuthenticationService} from '../../../../core/auth/authentication.service';
import {Haptics, NotificationType} from "@capacitor/haptics";
import {CaptchaService} from "../../../../core/services/captcha.service";

@Component({
    selector: 'app-sms-page',
    templateUrl: './sms.component.html',
    styleUrls: ['./sms.component.scss'],
})
export class SmsPageComponent implements OnInit, OnDestroy {
    code = '';

    valid = true;

    interval;
    timeLeft = 60;

    destroySub = new Subject();

    loading = false;

    disableInput = false;

    @ViewChild('inputCode') inputCode;

    constructor(
        private authNavigationService: AuthNavigationService,
        private authService: AuthenticationService,
        private smsService: SmsService,
        private captchaService: CaptchaService,
    ) {
    }

    ngOnInit() {
        this.authNavigationService.slide$.pipe(takeUntil(this.destroySub)).subscribe((slide) => {
            if (slide === 2) {
                this.startTimer();
            } else {
                this.stopTimer();
            }
        });
    }

    ngOnDestroy() {
        this.destroySub.next(null);
        this.destroySub.complete();
    }

    sendCode(code) {
        this.loading = true;
        this.disableInput = true;
        if (this.authNavigationService.authTypeSignIn) {
            this.signIn(code);
        } else if (!this.authNavigationService.authTypeSignIn) {
            this.signUp(code);
        }
    }

    signIn(code) {
        this.authNavigationService.smsInfoSignIn.smsConfirm.code = code;
        this.authService
            .login(this.authNavigationService.smsInfoSignIn, 'login')
            .then((result) => {
                if (result.status === 'err') {
                    this.errSmsCheck();
                } else {
                    this.dismiss();
                }
                this.loading = false;
            })
            .catch(() => {
                this.errSmsCheck();
            });
    }

    signUp(code) {
        this.authNavigationService.smsInfoSignUp.smsConfirm.code = code;
        this.authService
            .register(this.authNavigationService.smsInfoSignUp, 'login')
            .then((result) => {
                if (result.status === 'err') {
                    this.errSmsCheck();
                } else {
                    this.dismiss();
                }
                this.loading = false;
            })
            .catch(() => {
                this.errSmsCheck();
            });
    }

    errSmsCheck() {
        Haptics.notification({
            type: NotificationType.Error,
        });

        this.setValid(false);
        this.disableInput = false;
        this.loading = false;
    }

    dismiss() {
        this.authNavigationService.dismiss(true);
    }

    checkCode(ev) {
        const code = ev.toString();
        if (code.length >= 4 && this.valid === false) {
            this.code = '';
            this.setValid(true);
        } else if (code.length >= 4 && !this.disableInput) {
            this.sendCode(code);
        } else if (!this.disableInput) {
            this.setValid(true);
        }
    }

    back() {
        return () => {
            this.authNavigationService.back();
        };
    }

    setValid(val) {
        this.valid = val;
    }

    startTimer() {
        this.interval = setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft--;
            } else {
                clearInterval(this.interval);
            }
        }, 1000);
    }

    stopTimer() {
        this.code = '';
        this.setValid(true);
        this.timeLeft = 60;
        this.pauseTimer();
        this.disableInput = false;
    }

    pauseTimer() {
        clearInterval(this.interval);
    }

    async reSms() {
      try {
        if (this.captchaService.getToken()) {
          this.captchaService.reset();
        }
        const {id, token} = await this.captchaService.execute({id: 'reSms'})
        if (this.authNavigationService.getSlide() !== 2 && id !== 'reSms') {
          return
        }
        this.smsService
          .getSmsWithCaptchaToken(this.authNavigationService.authTypeSignIn ? this.authNavigationService.smsInfoSignIn.phone : this.authNavigationService.smsInfoSignUp.phone, token)
          .pipe(
            first(),
            catchError((err) => {
              return EMPTY;
            }),
          )
          .subscribe((token: string) => {
            if (this.authNavigationService.authTypeSignIn) {
              this.authNavigationService.smsInfoSignIn.smsConfirm.token = token;
            } else if (!this.authNavigationService.authTypeSignIn) {
              this.authNavigationService.smsInfoSignUp.smsConfirm.token = token;
            }
            this.code = '';
            this.setValid(true);
            this.timeLeft = 60;
            this.pauseTimer();
            this.startTimer();
            this.disableInput = false;
            Haptics.notification({
              type: NotificationType.Warning,
            });
          });
      } catch (e) {

      }
    }
}
