import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-input-currency',
    templateUrl: './input-currency.component.html',
    styleUrls: ['./input-currency.component.scss'],
})
export class InputCurrencyComponent implements OnInit {
    val = '';

    @Input() placeholder = '';

    @Input() set value(val: any) {
          this.val = val;
          this.inputCurrency(this.val);

          if (!this.currency) {
            return;
          }
          const inputEl = this.currency.nativeElement;
          setTimeout(() => {
            inputEl.dispatchEvent(new Event('input'));
          }, 50);
    }

    @Input() disabled = false;
    @Input() prefix = '';

    @Output() inputEv: EventEmitter<any> = new EventEmitter<any>(false);
    @Output() clickEnter: EventEmitter<any> = new EventEmitter<any>(false);

    @ViewChild('currency', { static: false }) currency: any;
    public currencyPatterns = '^[1-9][0-9]*[.]{0,1}[0-9]{0,2}$';

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        setTimeout(() => {
            const inputEl = this.currency.nativeElement;
            inputEl.dispatchEvent(new Event('input'));
        }, 0);
    }

    toNum(val: any) {
        return Number(val);
    }

    inputCurrency(value: any) {
        this.val = value;
        if (!this.currency) {
            return;
        }
        const inputEl = this.currency.nativeElement;
        const width = getTextWidth(value, '16px Rubik');
        if (width > 1) {
            inputEl.nextSibling.style.left = width - inputEl.clientWidth + 'px';
        }

        function getTextWidth(text: string, font: any): any {
            const canvas: any =
                (getTextWidth as any).canvas || ((getTextWidth as any).canvas = document.createElement('canvas'));
            const context = canvas.getContext('2d');
            context.font = font;
            const metrics = context.measureText(text);
            return metrics.width;
        }

        this.inputEv.emit(value);
    }

    clickEnterEv() {
        this.clickEnter.emit(true);
    }
}
