<div *ngIf="show" class="container">
    <div class="text">
        <div class="title sub_circe main-text font_bold">Станьте исполнителем</div>
        <div class="description body_secondary_circe main-text">
            Занимайтесь любимым делом и зарабатывайте!
        </div>
        <div class="caption_circe link">Подробнее</div>
    </div>
    <img class="background" src="/assets/pictures/be_executor_banner.png">
</div>
