import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import {IonRouterOutlet, ModalController} from '@ionic/angular';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {GeocodeService} from '../../../../core/geo/geocode.service';
import {IAddress, IAddressResult} from '../../../../core/geo/interfaces/address.interface';
import {PositionService} from '../../../../core/geo/position.service';
import {BottomModalController} from '../../../bottom-modal/bottom-modal.controller';
import {AddressService} from '../../address.service';
import {Router} from '@angular/router';
import {KeyboardFixService} from '../../../../core/helpers/keyboard-fix.service';
import {BackButtonAndroidHelper} from '../../../../core/helpers/back-button-android.helper';
import {getKeyByValue, radiusToZoom} from '../../../../core/utils/map-helper';
import {MapGoogleService} from '../../../../core/services/map-google.service';
import {PlatformService} from '../../../../core/services/platform.service';
import {pause} from "../../../../core/utils/helpers";

@Component({
  selector: 'app-search-address',
  templateUrl: './search-address.component.html',
  styleUrls: ['./search-address.component.scss'],
})
export class SearchAddressComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('mapOverlay', {static: false}) mapOverlay: ElementRef<HTMLElement>;
  @ViewChild('inputSearch', {static: false}) inputSearch: ElementRef<HTMLElement>;
  @ViewChild('searchContent', {static: false}) searchContent: ElementRef<HTMLElement>;

  zoom = 15;
  minZoom = 6;
  maxZoom = 20;
  disableZoom = false;
  centerPosition: any = this.positionService.defaultPosition;
  map;

  radius;
  enableRadius = false;
  circle = null;
  searchValue = '';
  searchInputChanged = new Subject<string>();
  addressList: IAddressResult[];
  suggestions = false;
  private keyboardHeightSub: Subscription;

  disableBtn = false;
  disableLocBtn = false;

  constructor(
    private modalController: ModalController,
    private geocodeService: GeocodeService,
    private readonly positionService: PositionService,
    private readonly bottomModalController: BottomModalController,
    @Optional() private ionRouterOutlet: IonRouterOutlet,
    private keyboardFixService: KeyboardFixService,
    private router: Router,
    private addressService: AddressService,
    private backButtonAndroidHelper: BackButtonAndroidHelper,
    private cdr: ChangeDetectorRef,
    private mapService: MapGoogleService,
    private platformService: PlatformService,
    private routerOutlet: IonRouterOutlet
  ) {
  }

  async ngOnInit() {
    this.backButtonStart();
    this.searchInputChanged.pipe(debounceTime(500)).subscribe((query) => {
      this.geocodeSearch(query);
    });
  }

  ionViewDidEnter(){
    if (this.platformService.isIos()) {
      this.routerOutlet.swipeGesture = false;
    }
  }

  ionViewDidLeave(){
    if (this.platformService.isIos()) {
      this.routerOutlet.swipeGesture = true;
    }
  }

  async ngAfterViewInit() {
    const state = this.router.getCurrentNavigation().extras.state;
    if (state) {
      const address = state.address as IAddress;
      this.enableRadius = state.enableRadius;
      this.radius = state.radius ? state.radius : 0;
      if (address && address.streetAddress && address.location.coordinates.length) {
        await this.loadMap();
        await this.showMap();
        address.location.coordinates = [address.location.coordinates[0], address.location.coordinates[1]];
        this.selectCoordinates(address);
      } else {
        await this.loadMap();
        await this.showMap();
        setTimeout(() => {
          //this.focusSearch();
        }, 50);
      }
    } else {
      await this.loadMap();
      await this.showMap();
      setTimeout(() => {
        //this.focusSearch();
      }, 50);
    }
    this.keyboardHeightHandlerStart();
  }

  ngOnDestroy() {
    this.keyboardHeightHandlerStop();
    if (this.map) {
      this.mapService.destroyMap(this.map);
    }
  }

  focusSearch() {
    this.inputSearch.nativeElement.focus();
  }

  onFocusInput(val) {
    this.searchInputChanged.next(val.target.value);
    this.showSuggestions(true);
  }

  showSuggestions(bool = true) {
    this.suggestions = bool;
  }

  showMap() {
    this.mapOverlay.nativeElement.style.opacity = '0';
    this.mapOverlay.nativeElement.style.zIndex = '0';
    this.mapOverlay.nativeElement.style.pointerEvents = 'none';
  }

  backButtonStart() {
    this.backButtonAndroidHelper.setBackButtonFunction(() => {
      this.back()();
    });
  }

  backButtonStop() {
    this.backButtonAndroidHelper.unsetBackButtonFunction();
  }

  keyboardHeightHandlerStart() {
    this.keyboardHeightSub = this.keyboardFixService.subToKeyboardEvent$().subscribe((height) => {
      this.searchContent.nativeElement.setAttribute('style', `padding-bottom: ${height}px`);
    });
  }

  keyboardHeightHandlerStop() {
    this.keyboardHeightSub.unsubscribe();
  }

  async geocodeSearch(query) {
    if (query.length >= 3) {
      this.addressList = await this.geocodeService.geocodeSearch(query);
    } else {
      this.addressList = [];
    }
  }

  async loadMap() {
    try {
      this.map = await this.mapService.createMap({
        map: 'map_search',
        zoomControl: !this.enableRadius,
        center: this.centerPosition,
        zoom: this.zoom,
        maxZoom: this.maxZoom,
        minZoom: this.minZoom,
        wheel: false,
      });
      this.mapService.subscribeMapEvent(this.map, 'dragstart').subscribe((ev) => {
        this.disableBtn = true;
        this.disableLocBtn = true;
        this.mapService.setOptions(this.map, {
          maxZoom: this.mapService.getMapZoom(this.map),
          minZoom: this.mapService.getMapZoom(this.map),
        })
        this.cdr.detectChanges();
      });
      this.mapService.subscribeMapEvent(this.map, 'drag').subscribe((ev) => {
        this.mapService.setOptions(this.map, {
          maxZoom: this.mapService.getMapZoom(this.map),
          minZoom: this.mapService.getMapZoom(this.map),
        })
      });
      await pause(200);
      this.mapService.subscribeMapEvent(this.map, 'dragend').subscribe(async (ev) => {
        await pause(100);
        this.setCenterPosition(this.mapService.getCenter(this.map));
        await pause(200);
        this.mapService.setOptions(this.map, {
          maxZoom: this.maxZoom,
          minZoom: this.minZoom,
        })
        this.disableBtn = false;
        this.disableLocBtn = false;
        await this.changeRadius(this.radius);
        this.cdr.detectChanges();
      });
      await pause(200);
    } catch (e) {
      console.log(e);
    }
  }

  async zoomMap(event) {
    if (this.disableZoom) {
      return;
    }
    const zoom = await this.mapService.getMapZoom(this.map);
    const target = this.centerPosition;
    this.disableZoom = true;
    try {
      if (!event && zoom > this.minZoom) {
        this.mapService.setCenter(this.map, target, zoom - 1);
      } else if (event && zoom < this.maxZoom) {
        this.mapService.setCenter(this.map, target, zoom + 1);
      }
    } catch (e) {
      console.log(e);
    }
    this.disableZoom = false;
  }

  setCenterPosition({lat, lng}) {
    this.centerPosition = {lat, lng};
  }

  async setMyPosition() {
    if (this.disableLocBtn) {
      return false;
    }
    const position = await this.getMyPosition();
    this.disableBtn = true;
    this.disableLocBtn = true;
    await this.geocodeService
      .setGeolocationAlways({
        lat: position.lat,
        lng: position.lng,
      })
      .then((loc) => {
        this.setPositionOnMap(loc);
        this.inputSearch.nativeElement.blur();
        this.showSuggestions(false);
        setTimeout(() => {
          this.disableBtn = false;
          this.disableLocBtn = false;
        }, 1000);
      }).catch(() => {
        setTimeout(() => {
          this.disableBtn = false;
          this.disableLocBtn = false;
        }, 500);
      });
  }

  async setPositionOnMap(position) {
    try {
      this.setCenterPosition(position);
      if (this.enableRadius) {
        this.changeRadius(this.radius, true, this.centerPosition);
      } else {
        await this.mapService.setCenter(this.map, this.centerPosition, this.mapService.getMapZoom(this.map) || this.zoom);
      }
    } catch (e) {
      console.log(e);
    }
  }

  getMyPosition() {
    return this.positionService.getApproximatePosition();
  }

  async selectCoordinates(address: IAddress) {
    this.searchValue = address.streetAddress;
    const convertCoordinates = {lat: address.location.coordinates[1], lng: address.location.coordinates[0]};
    await this.setPositionOnMap(convertCoordinates);
    this.inputSearch.nativeElement.blur();
    this.showSuggestions(false);
  }

  search(e) {
    this.searchInputChanged.next(e);
  }

  clearSearchInput() {
    this.searchInputChanged.next('');
    this.addressList = [];
    this.searchValue = '';
    this.showSuggestions(true);
  }

  async selectAddress() {
    this.disableBtn = true;
    await this.geocodeService
      .getAddressByCoordinates(this.centerPosition)
      .then((address) => {
        this.back(address)();
      })
      .catch(() => {
        this.disableBtn = false;
      });
  }

  async changeRadius(newRadius, force = false, center?) {
    if (this.map && this.enableRadius && (force || newRadius !== this.radius)) {
      this.mapService.removeCircle(this.map, this.circle);
      const target = this.centerPosition;
      const zoom = await radiusToZoom(newRadius * 1000 * 2.1, 256, target.lat);
      await this.mapService.setCenter(this.map, center ? center : target, zoom);
      this.radius = newRadius;
    }
  }

  back(address?) {
    return () => {
      this.disableBtn = true;
      this.backButtonStop();
      if (address) {
        this.addressService.dismiss({address, radius: this.enableRadius ? this.radius : false});
      } else {
        this.addressService.dismiss(null);
      }
      this.disableBtn = false;
    };
  }
}
