import { Component, Input, OnInit } from '@angular/core';
import { TUserShortInfo } from '../../../core/user/types/user-short-info.type';
import { NavController } from '@ionic/angular';
import { UserTypeEnum } from '../../../core/user/enums/user-type.enum';

@Component({
    selector: 'app-widget-user-info-view',
    templateUrl: './widget-user-info-view.component.html',
    styleUrls: ['./widget-user-info-view.component.scss'],
})
export class WidgetUserInfoViewComponent implements OnInit {
    @Input() user!: TUserShortInfo;
    @Input() invert = false;

    @Input() path = '/user';

    @Input() contact = false;

    userTypeEnum = UserTypeEnum;

    constructor(private navCtrl: NavController) {
    }

    ngOnInit() {
    }

    goToUser() {
        this.navCtrl.navigateForward([this.path, this.user._id]);
    }

    write() {
        this.navCtrl.navigateForward(['im', this.user._id]);
    }

    call() {
        location.href = `tel:${this.user.phone}`;
    }

}
