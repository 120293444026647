import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import cssVars from 'css-vars-ponyfill';

if (environment.production) {
    enableProdMode();
}

cssVars({
  onlyLegacy: true,
  shadowDOM: true,
  watch: true,
  updateURLs: false,
  silent: true,
  onComplete(cssText, styleNode, cssVariables) {
    //console.log(cssText);
  }
});

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => {}).catch(err => console.error(err));
