import { Injectable } from '@angular/core';

import { CoreService } from '../services/core.service';
import { IAddress, IAddressResult } from './interfaces/address.interface';
import { ApiService } from '../api/api.service';
import { PositionService } from './position.service';

@Injectable({
    providedIn: 'root',
})
export class GeocodeService {
    constructor(private apiService: ApiService, private coreService: CoreService, private positionService: PositionService) {
    }

    async geocodeSearch(input, position?): Promise<Array<IAddressResult>> {
        try {
            return (await this.apiService.suggestAddress(input)).result.filter((address) => {
                return address.qc_geo < 3 && address.address.streetAddress !== 'null';
            });
        } catch (e) {
            return [];
        }
    }

    setGeolocationAlways(location?) {
        const userCoordinates = this.positionService.userPositionState.getValue();
        return new Promise(async (resolve) => {
            if (!location.lat || !location.lng) {
                if (userCoordinates) {
                    resolve(userCoordinates);
                } else {
                    resolve([55.753960, 37.620393]);
                }
            }
            try {
                resolve({lat: location.lat, lng: location.lng});
            } catch (e) {
                resolve([55.753960, 37.620393]);
            }
        });
    }

    getAddressByCoordinates(location): Promise<IAddress> {
        return new Promise(async (resolve, reject) => {
            if (!location.lat || !location.lng) {
                this.presentError('Не удалось определить ваш адрес.');
                reject(false);
            }
            try {
                const address: IAddressResult[] = (
                    await this.apiService.geolocateAddress({
                        lat: location.lat,
                        lon: location.lng,
                    })
                ).result;
                if (address.length) {
                    if (address[0].qc_geo < 3 && address[0].address.streetAddress !== 'null') {
                        resolve(address[0].address);
                    } else {
                        this.presentError(
                            'Не удалось определить точный адрес.' +
                            ' Попробуйте подвинуть метку на объект или введите адрес заново',
                        );
                        reject(false);
                    }
                } else {
                    this.presentError(
                        'Не удалось определить точный адрес.' +
                        ' Попробуйте подвинуть метку на объект или введите адрес заново',
                    );
                    reject(false);
                }
            } catch (e) {
                this.presentError(
                    'Не удалось определить точный адрес.' +
                    ' Попробуйте подвинуть метку на объект или введите адрес заново',
                );
                reject(false);
            }
        });
    }

    presentError(error) {
        this.coreService.presentAlert(error);
    }
}
