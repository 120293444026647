import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { AuthNavigationService } from './auth-navigation.service';
import { Subscription } from 'rxjs';
import { CaptchaService } from 'src/app/core/services/captcha.service';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.page.html',
    styleUrls: ['./auth.page.scss'],
})
export class AuthPage implements OnInit, AfterViewInit, OnDestroy {

    @Input() navigateUrl;
    slidesSub: Subscription;

    slidesOpts = {
        noSwiping: true,
        speed: 400,
        noSwipingClass: 'no-swiping',
        keyboard: false,
        lazy: false,
        on: {
            beforeInit() {
                const swiper = this;
                swiper.classNames.push(`${swiper.params.containerModifierClass}fade`);
                const overwriteParams = {
                    slidesPerView: 1,
                    slidesPerColumn: 1,
                    slidesPerGroup: 1,
                    watchSlidesProgress: true,
                    spaceBetween: 0,
                    virtualTranslate: true,
                };
                swiper.params = Object.assign(swiper.params, overwriteParams);
                swiper.params = Object.assign(swiper.originalParams, overwriteParams);
            },
            setTranslate() {
                const swiper = this;
                const { slides } = swiper;
                for (let i = 0; i < slides.length; i += 1) {
                    const $slideEl = swiper.slides.eq(i);
                    const offset$$1 = $slideEl[0].swiperSlideOffset;
                    let tx = -offset$$1;
                    if (!swiper.params.virtualTranslate) {
                        tx -= swiper.translate;
                    }
                    let ty = 0;
                    if (!swiper.isHorizontal()) {
                        ty = tx;
                        tx = 0;
                    }
                    // const slideOpacity = swiper.params.fadeEffect.crossFade
                    //     ? Math.max(1 - Math.abs($slideEl[0].progress), 0)
                    //     : 1 + Math.min(Math.max($slideEl[0].progress, -1), 0);
                    const slideOpacity = $slideEl[0].progress === 0 ? 1 : 0;
                    $slideEl
                        .css({
                            opacity: slideOpacity,
                        })
                        .transform(`translate3d(${tx}px, ${ty}px, 0px)`);
                }
            },
            setTransition(duration) {
                const swiper = this;
                const { slides, $wrapperEl } = swiper;
                slides.transition(duration);
                if (swiper.params.virtualTranslate && duration !== 0) {
                    let eventTriggered = false;
                    slides.transitionEnd(() => {
                        if (eventTriggered) {
                            return;
                        }
                        if (!swiper || swiper.destroyed) {
                            return;
                        }
                        eventTriggered = true;
                        swiper.animating = false;
                        const triggerEvents = ['webkitTransitionEnd', 'transitionend'];
                        for (let i = 0; i < triggerEvents.length; i += 1) {
                            $wrapperEl.trigger(triggerEvents[i]);
                        }
                    });
                }
            },
        },
    };

    @ViewChild('slides') slides: IonSlides;

    constructor(private authNavigationService: AuthNavigationService, private captchaService: CaptchaService) {
    }

    async ngOnInit() {
        await this.captchaService.init();
        await this.captchaService.render();
        this.authNavigationService.navigateUrl = this.navigateUrl;
    }

    async ngAfterViewInit() {
        this.slides.update();
        this.slidesSub = this.authNavigationService.slide$.subscribe((slide) => {
            this.slides.slideTo(slide, 400, false);
        });
    }

    ngOnDestroy() {
        this.slidesSub.unsubscribe();
        this.captchaService.destroy();
    }
}
