<svg-icon
    class="icon"
    [src]="'/assets/icons/' + icon + '.svg'"
    [svgStyle]="{
        width: '100%',
        height: '100%',
        stroke: strokeColor,
        fill: fillColor,
        display: 'block'
    }"
></svg-icon>
