import { ActionReducerMap } from '@ngrx/store/src/models';
import {
    NOTIFICATION_REDUCER_NODE,
    notificationReducer,
    INotificationState,
} from '../module/notification/store/notification.reducer';
import { NotificationEffects } from '../module/notification/store/notification.effects';

export interface IAppState {
    [NOTIFICATION_REDUCER_NODE]: INotificationState;
}

export const appReducers: ActionReducerMap<IAppState> = {
    [NOTIFICATION_REDUCER_NODE]: notificationReducer,
};

export const appEffects = [NotificationEffects];
