import { Directive, HostListener, Injector, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appMask]',
})
export class MaskDirective {
    private _oldvalue = '';
    private regExpr: any;
    private control: NgControl;

    constructor(injector: Injector) {
        // this make sure that not error if not applied to a NgControl
        try {
            this.control = injector.get(NgControl);
        } catch (e) {}
    }

    @Input()
    set szMask(value) {
        this.regExpr = new RegExp(value);
    }

    @HostListener('input', ['$event'])
    change($event) {
        const item = $event.target;
        const value = item.value;
        this._oldvalue = this._oldvalue || value.substring(0, value.length - 1);
        let pos = item.selectionStart; // get the position of the cursor
        const matchvalue = value;

        const noMatch: boolean = value && !this.regExpr.test(matchvalue);
        if (noMatch || value.toString().length > 15) {
            item.selectionStart = item.selectionEnd = pos - 1;
            if (item.value.length < this._oldvalue.length && pos === 0) {
                pos = 2;
            }

            if (this.control) {
                this.control.control.setValue(this._oldvalue, { emit: true });
            }

            item.value = this._oldvalue;
            item.selectionStart = item.selectionEnd = pos - 1; // recover the position
        } else {
            this._oldvalue = value;
        }
    }
}
