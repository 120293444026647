import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { NavigationEnd, Router } from '@angular/router';
import { TAnalyticsEvent } from './types/analytics-event.type';
import { PlatformService } from '../services/platform.service';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    screenBlackList = ['/task-add'];

    constructor(
        private readonly router: Router,
        private platformService: PlatformService,
    ) {
    }

    init() {
        if (this.platformService.isCordova()) {
            this.watchPageChange();
        }
    }

    private watchPageChange() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Удалить параметры
                const reg = new RegExp(/\/[0-9a-fA-F]{24}|\/\d+/g);
                const screenName = event.url.replace(reg, '');

                if (this.screenBlackList.includes(screenName)) {
                    return;
                }
                this.setScreenName(screenName);
            }
        });
    }

    sendEvent({ action, category, value, page }: TAnalyticsEvent) {
      if (this.platformService.isCordova()) {
        return FirebaseAnalytics.logEvent({
          name: action.toString(), params: {
            category,
            page,
            ...(() => (value ? {...value} : {}))(),
          }
        });
      }
    }

    async setUserID(id: string) {
      if (this.platformService.isCordova()) {
        try {
          await FirebaseAnalytics.setUserId({userId: id});
        } catch (e) {
          if (e === 'cordova_not_available') {
            return;
          } else {
            throw new Error(e);
          }
        }
      }
    }

    async setScreenName(name: string) {
      if (this.platformService.isCordova()) {
        try {
          await FirebaseAnalytics.setCurrentScreen({screenName: name});
        } catch (e) {
          if (e === 'cordova_not_available') {
            return;
          } else {
            throw new Error(e);
          }
        }
      }
    }
}
