import { Injectable } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { PositionService } from '../../core/geo/position.service';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { IAddress } from '../../core/geo/interfaces/address.interface';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AddressService {
    backBtnClicked = false;
    resultAddress: Subject<{address: IAddress, radius: number}> = new Subject<{address: IAddress, radius: number}>();

    constructor(
        private readonly modalController: ModalController,
        private positionService: PositionService,
        private navCtrl: NavController,
        private router: Router,
    ) {
    }

    async getAddress(address: IAddress, enableRadius = false, radius?: number) {
        const state: {address, enableRadius, radius?} = {address: address ? address : null, enableRadius};
        if (enableRadius){
            state.radius = radius;
        }
        await this.navCtrl.navigateForward('/address', {state});
        this.backBtnClicked = false;
        return await this.resultAddress.asObservable().pipe(first()).toPromise();
    }

    dismiss(result) {
        this.resultAddress.next(result ? {address: result.address, radius: result.radius ? result.radius : false} : null);
        if (this.router.url.includes('/address') && !this.backBtnClicked) {
            this.backBtnClicked = true;
            this.navCtrl.pop();
        }
        this.resultAddress.complete();
        this.resultAddress = new Subject<{address: IAddress, radius: number}>();
    }
}
