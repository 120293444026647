import {combineLatest, concat, Observable, ObservedValueOf, OperatorFunction} from 'rxjs';
import {distinctUntilChanged, map, take} from 'rxjs/operators';

export function startFrom<T, O extends Observable<any>>(start: O): OperatorFunction<T, T | ObservedValueOf<O>> {
    return (source: Observable<T>) => concat(start.pipe(take(1)), source);
}

export const muteFirst = <T, R>(first$: Observable<T>, second$: Observable<R>): Observable<R> => {
    return combineLatest([first$, second$]).pipe(
        map(([a, b]: [T, R]): R => b),
        distinctUntilChanged(),
    );
};
