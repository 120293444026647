import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { IMessageEvent } from './interfaces/message-event.interface';
import { MessengerService } from './messenger.service';
import { IConversationState } from './interfaces/conversation-state.interface';
import { startFrom } from '../../core/utils/rx-js.util';
import { AuthenticationService } from '../../core/auth/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class DialogController {
    dialogStateSubject = new BehaviorSubject<IConversationState>(null);
    dialogState = this.dialogStateSubject.asObservable().pipe(filter((res) => !!res));

    constructor(
        private readonly messengerService: MessengerService,
        private readonly authService: AuthenticationService,
    ) {}

    init() {
        this.messengerService
            .chatEvents$()
            .pipe(
                switchMap((messageEvent: IMessageEvent) => {
                    return this.getConversations(messageEvent.message.peerID, messageEvent.message.conversationID).pipe(
                        map((conv) => {
                            conv.items[0].lastMessage = messageEvent.message;
                            return conv;
                        }),
                    );
                }),
                startFrom(this.getConversations()),
            )
            .subscribe((state) => {
                this.updateState(state);
            });
    }

    unreadCount$(): Observable<number> {
        return this.dialogState.pipe(
            map((dialogState: IConversationState) => {
                return dialogState.items.reduce(
                    (acc, item) =>
                        acc +
                        (!item.lastMessage.readState && item.lastMessage.fromID !== this.authService.getAccountID()
                            ? 1
                            : 0),
                    0,
                );
            }),
        );
    }

    private updateState(state: IConversationState) {
        const curValue = this.dialogStateSubject.getValue() || { items: [], profiles: {} };

        for (const conversation of state.items) {
            const i = curValue.items.findIndex((o) => o._id === conversation._id);
            if (curValue.items[i]) {
                curValue.items[i] = conversation;
            } else {
                curValue.items.push(conversation);
            }
        }

        Object.keys(state.profiles).forEach((key) => {
            curValue.profiles[key] = state.profiles[key];
        });

        this.dialogStateSubject.next(curValue);
    }
    getState() {
        return this.dialogState;
    }

    getConversations(peerID?, convID?): Observable<IConversationState> {
        return this.messengerService.getConversations({ peerID, conversationID: convID });
    }
}
