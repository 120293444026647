import { Component, Input, OnInit } from '@angular/core';
import { IImage } from '../../../core/images/interfaces/image.interface';

@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {
    @Input() img?: IImage;
    @Input() type?: string;
    @Input() data!: string;

    constructor() {}

    ngOnInit() {}

    getSrc() {
        return this.data ? this.data : this.img?.sizes.find((el) => el.type === this.type)!.url;
    }
}
