export enum AnalyticsEventCategoryEnum {
    NAVIGATION = 'navigation',
    OFFER = 'offer',
    AD = 'ad',
    AD_TASK = 'ad_task',
    AUTH = 'auth',
    WALLET = 'wallet',
    MESSENGER = 'MESSENGER',
    REVIEW = 'review',
    SUPPORT = 'support',
    TUTORIAL = 'tutorial',
}
