<input
    class="body_rubik font_regular"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [ngModel]="value"
    inputmode="numeric"
    type="number"
    (ngModelChange)="inputNumber($event)"
    (keyup.enter)="clickEnterEv()"
/>
