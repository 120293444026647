import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class WelcomeGuard implements CanActivate {

    constructor(
        private router: Router,
    ) {
    }

    async canActivate(): Promise<boolean> {
        const welcome = await localStorage.getItem('welcome');
        if (welcome) {
            return true;
        } else {
            await this.router.navigate(['/welcome']);
            return false;
        }
    }

}
