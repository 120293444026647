import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { IAdItem } from '../../../core/ad/interface/ad-items.interface';

@Component({
    selector: 'app-ad-list-widget',
    templateUrl: './ad-list.component.html',
    styleUrls: ['./ad-list.component.scss'],
})
export class AdListComponent implements OnInit {
    @Input() status = true;
    @Input() items!: IAdItem[];

    constructor() {}

    ngOnInit() {}

    trackByFn(index: any, item: any) {
        return item._id;
    }
}
