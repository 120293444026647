import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-ui-ad-budget',
    templateUrl: './ui-ad-budget.component.html',
    styleUrls: ['./ui-ad-budget.component.scss'],
})
export class UiAdBudgetComponent implements OnInit {
    @Input() budget = 1000;
    constructor() {}

    ngOnInit() {}
}
