import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-ui-segment',
    templateUrl: './ui-segment.component.html',
    styleUrls: ['./ui-segment.component.scss'],
})
export class UiSegmentComponent {
    @Input() buttons: { name: string; value: string }[] = [];

    @Input() value!: string;
    @Output() valueChange: EventEmitter<string> = new EventEmitter();

    constructor() {}

    segmentChanged(ev: any) {
        this.valueChange.emit(ev.detail.value);
    }
}
