import { mapperSrcToIImage } from '../mappers/image-mappers';
import { ImageSizeTypeEnum } from '../../images/enums/image-size-type.enum';

const imagePlugUrl = '/assets/no_user_photo.svg';

export const IMAGE_PLUG = mapperSrcToIImage(
    imagePlugUrl,
    [
        // 75px
        ImageSizeTypeEnum.s,
        ImageSizeTypeEnum.m,
        ImageSizeTypeEnum.x,
        ImageSizeTypeEnum.z,
    ],
    'imagePlug',
);
