import { createAction, props } from '@ngrx/store';
import { INotification } from '../models/notificaton.model';

const receiveNotifications = createAction(
    '[NOTIFICATION] Receive notification',
    props<{ notifications: INotification[] }>(),
);

const setNotificationsRead = createAction('[NOTIFICATION] Set notification read');

const loadItemsRequest = createAction('[NOTIFICATION] Load notification REQUEST');
const loadItemsSuccess = createAction(
    '[NOTIFICATION]  Load notification SUCCESS',
    props<{ notifications: INotification[] }>(),
);
const loadItemsError = createAction('[NOTIFICATION]  Load notification ERROR', props<{ e: string }>());

const notificationNavigate = createAction(
    '[NOTIFICATION]  Notification navigate',
    props<{ notification: INotification }>(),
);

export const NotificationActions = {
    receiveNotifications,
    setNotificationsRead,
    loadItemsRequest,
    loadItemsSuccess,
    loadItemsError,
    notificationNavigate,
};
