<ion-content [forceOverscroll]="false" [scrollX]="false" [scrollY]="false" [fullscreen]="true">
        <ion-slides #slides [options]="slidesOpts">
            <ion-slide class="no-swiping">
                <app-sign-in-page class="component-slide"></app-sign-in-page>
            </ion-slide>
            <ion-slide class="no-swiping">
                <app-sign-up-page class="component-slide"></app-sign-up-page>
            </ion-slide>
            <ion-slide class="no-swiping">
                <app-sms-page class="component-slide"></app-sms-page>
            </ion-slide>
        </ion-slides>
</ion-content>
