import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ISelfworkerItem } from '../../../core/selfworkers/interfaces/selfworkers.interface';

@Component({
    selector: 'app-self-worker-list-widget',
    templateUrl: './self-worker-list.component.html',
    styleUrls: ['./self-worker-list.component.scss'],
})
export class SelfWorkerListComponent implements OnInit {
    @Input() items!: ISelfworkerItem[];

    constructor() {}

    ngOnInit() {}

    trackByFn(index: any, item: any) {
        return item._id;
    }
}
