<div class="ad-link">
    <app-category-icon [category]="categoryInfo"></app-category-icon>

    <div class="ad-link__info">
        <div class="ad-link__info__label caption_circe">{{ label }}</div>
        <div class="ad-link__info__name body_secondary_rubik">{{ name }}</div>
    </div>

    <div class="ad-link__arrow">
        <app-svg-icon [name]="'right-arrow'" [stroke]="'var(--ion-color-additional-text)'"></app-svg-icon>
    </div>
</div>
