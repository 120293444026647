import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-app-popup-review-complete',
  templateUrl: './app-popup-review-complete.component.html',
  styleUrls: ['./app-popup-review-complete.component.scss'],
})
export class AppPopupReviewCompleteComponent implements OnInit {

  constructor(private popoverController: PopoverController) {
  }

  ngOnInit() {
  }

  close() {
    this.popoverController.dismiss({action: null});
  }

}
