<button
    [class]="'body_circe font_bold ion-activatable ripple-parent ' + class"
    [ngClass]="{ outline: outline == true }"
    [style.background]="!outline ? 'var(--ion-color-' + background + ')' : ''"
    [style.color]="'var(--ion-color-' + color + ')'"
    [style.border-color]="!outline ? 'var(--ion-color-' + background + ')' : 'var(--ion-color-' + color + ')'"
    [class.disabled]="disabled"
    [class.load]="load"
    (click)="click()"
>
    <ng-content *ngIf="!load"></ng-content>
    <ion-spinner class="load" *ngIf="load"></ion-spinner>
    <ion-ripple-effect></ion-ripple-effect>
</button>
