<div class="item-native" [class.item-lines-full]="lines === 'full'">
    <ng-content select="[slot='start']"></ng-content>

    <div class="item-inner">
        <div class="input-wrapper">
            <ng-content></ng-content>
        </div>
        <div class="item-native__slot-end">
            <ng-content select="[slot='end']"></ng-content>
        </div>
    </div>
</div>
