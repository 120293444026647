import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TReview } from '../../../core/review/types/review.type';
import { TAccountUser } from '../../../core/account/types/account-user.type';

@Component({
    selector: 'app-ui-review',
    templateUrl: './ui-review.component.html',
    styleUrls: ['./ui-review.component.scss'],
})
export class UiReviewComponent {
    @Input() review!: TReview & { profile: TAccountUser };
    @Output() clickProfile = new EventEmitter<string>();

    constructor() {}

    handlerClickProfile(uid: string) {
        this.clickProfile.emit(uid);
    }
}
