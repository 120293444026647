<div class="item" [routerLink]="['/user', selfworker._id]">
    <div class="grid-wrapper">
        <div class="left-container">
            <app-ui-avatar [src]="selfworker.attachment[0]"></app-ui-avatar>
        </div>

        <div class="text">
            <app-ui-online-status [lastOnlineDate]="selfworker.stats.lastOnline"></app-ui-online-status>
            <div class="title font_bold sub_circe">{{ selfworker.name }} {{ selfworker.lastName }}</div>

            <div class="secondary">
                <div class="description caption_circe" *ngIf="selfworker.address">{{ selfworker.address.city }}</div>
                <div class="description caption_circe info">
                    <app-ui-sw-item-stat [profileStat]="selfworker.stats"></app-ui-sw-item-stat>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="divider"></div>
