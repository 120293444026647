<div class="image-select">
    <div class="image-select__icon">
        <app-svg-icon
            [style.height]="'16px'"
            [height]="16"
            [width]="16"
            [fill]="'var(--ion-color-accent)'"
            [name]="'plus'"
        ></app-svg-icon>
    </div>

    <div class="caption_circe image-select__text">Добавить</div>
</div>
