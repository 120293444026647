import { Pipe, PipeTransform } from '@angular/core';
import * as LinkifyIt from 'linkify-it';

@Pipe({
    name: 'linkify',
})
export class LinkifyPipe implements PipeTransform {
    linkify = new LinkifyIt();

    constructor() {}

    transform(value: any, ...args: any[]): any {
        return this.addPhoneTag(this.addUrlTag(value));
    }

    addUrlTag(content) {
        const matches = this.linkify.match(content);
        const result = [];
        let out = '';

        if (matches) {
            let last = 0;
            matches.forEach((match) => {
                if (last < match.index) {
                    result.push(content.slice(last, match.index).replace(/\r?\n/g, '<br>'));
                }
                result.push(`<a target="_system"  href="${match.url}">${match.text}</a>`);
                last = match.lastIndex;
            });
            if (last < content.length) {
                result.push(content.slice(last).replace(/\r?\n/g, '<br>'));
            }
            out = result.join('');
            return out;
        } else {
            return content;
        }
    }

    addPhoneTag(content) {
        return content.replace(
            /(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}/gm,
            (match) => {
                return `<a href="tel:${match}">${match}</a>`;
            },
        );
    }
}
