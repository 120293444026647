import { HttpParams } from '@angular/common/http';

export const createHttpGetParams = (query: object) => {
    return Object.keys(query).reduce((acc, key) => {
        if (query[key]) {
            return acc.append(key, query[key]);
        }
        return acc;
    }, new HttpParams());
};
