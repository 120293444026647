import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, combineReducers, createReducer, on } from '@ngrx/store';

import { INotification } from '../models/notificaton.model';

import { NotificationActions } from './notification.actions';
import { reducerLoadingMap } from '../../../shared/store-utils/reducer-loading-map';

export const NOTIFICATION_REDUCER_NODE = 'notification';

export interface INotificationItemState extends EntityState<INotification> {}

export interface INotificationState {
    needLoad: boolean;
    loading: boolean;
    notifications: INotificationItemState;
}

export const notificationItemAdapter = createEntityAdapter<INotification>({
    sortComparer: (item1, item2) => (item1.dateCreate <= item2.dateCreate ? 1 : -1),
    selectId: (item) => item._id,
});

const reducerNotificationLoading = reducerLoadingMap(
    false,
    NotificationActions.loadItemsRequest,
    NotificationActions.loadItemsSuccess,
    NotificationActions.loadItemsError,
);

const reducerNotificationItems = createReducer(
    notificationItemAdapter.getInitialState(),
    on(NotificationActions.receiveNotifications, (state, { notifications }) => {
        return notificationItemAdapter.upsertMany(notifications, state);
    }),
    on(NotificationActions.loadItemsSuccess, (state, { notifications }) => {
        return notificationItemAdapter.upsertMany(notifications, state);
    }),
    on(NotificationActions.setNotificationsRead, (state) => {
        return notificationItemAdapter.map((notification) => ({ ...notification, isRead: true }), state);
    }),
);

// Указывает на необходимость обновить данные уведомлений
const needLoadReducer = createReducer<any, Action>(
    true,
    on(NotificationActions.loadItemsSuccess, () => false),
);

export const notificationReducer = combineReducers({
    needLoad: needLoadReducer,
    loading: reducerNotificationLoading,
    notifications: reducerNotificationItems,
});
