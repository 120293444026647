<div
    class="body_rubik font_regular"
    [class.disabled]="disabled"
    [innerHTML]="_value && _value.length ? (_value | date: 'd MMMM yyyy') : placeholder"
    [class.placeholder]="!_value || !_value.length"
    (click)="click()"
></div>

<ion-datetime hidden #ionDate [max]="maxDate" [min]="minDate" [pickerOptions]="customPickerOptions" [(ngModel)]="_value"
   monthShortNames="Январь, Февраль, Март, Апрель, Май, Июнь, Июль, Август, Сентябрь, Октябрь, Ноябрь, Декабрь">
   </ion-datetime>
