import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-card-alert',
    templateUrl: './card-alert.component.html',
    styleUrls: ['./card-alert.component.scss'],
})
export class CardAlertComponent implements OnInit {
    @Input() type: 'error' | 'warning';
    @Input() text: string = '';

    constructor() {}

    ngOnInit() {}
}
