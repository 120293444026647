import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import {ModalController, NavController, Platform} from '@ionic/angular';
import { ISignInSmsDto } from 'src/app/core/auth/dto/sign-in-sms.dto';
import { ISignUpSmsDto } from '../../core/auth/dto/sign-up-sms.dto';
import { PlatformService } from '../../core/services/platform.service';
import { BackButtonAndroidHelper } from '../../core/helpers/back-button-android.helper';
import { AccountService } from '../../core/account/account.service';

@Injectable()
export class AuthNavigationService {
    private slideSource$ = new BehaviorSubject(0);
    slide$ = this.slideSource$.asObservable();

    authTypeSignIn: boolean;

    smsInfoSignIn: ISignInSmsDto = { phone: '', smsConfirm: { token: '', code: '' } };
    smsInfoSignUp: ISignUpSmsDto = { phone: '', name: '', smsConfirm: { token: '', code: '' } };

    navigateUrl: string;

    constructor(
        private platform: Platform,
        private platformService: PlatformService,
        private modalController: ModalController,
        private backButtonAndroidHelper: BackButtonAndroidHelper,
        private accountService: AccountService,
        private navCtrl: NavController,
    ) {

    }

    setSlide(slide) {
        this.slideSource$.next(slide);
    }

    getSlide() {
      return this.slideSource$.getValue();
    }

    setType(authTypeSignIn) {
        this.authTypeSignIn = authTypeSignIn;
    }

    backButtonStart() {
        this.backButtonAndroidHelper.setBackButtonFunction(() => {
            this.back();
        });
    }

    backButtonStop() {
        this.backButtonAndroidHelper.unsetBackButtonFunction();
    }

    async dismiss(auth: boolean) {
        if (auth){
            await this.accountService.loadState();
        }
        if (this.navigateUrl){
            await this.navCtrl.navigateRoot(this.navigateUrl);
        }
        await this.modalController.dismiss({ auth });
    }

    back() {
        const oldSlide = this.slideSource$.getValue();
        if (oldSlide === 0 || oldSlide === 1) {
            this.modalController.dismiss({ auth: false });
            this.setSlide(0);
        } else if (oldSlide === 2) {
            this.setSlide(this.authTypeSignIn === true ? 0 : 1);
        } else  {
            this.setSlide(oldSlide - 1);
        }
    }
}
