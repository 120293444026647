<ion-app>

    <ion-split-pane when="md" contentId="main" [disabled]='!showMenu'>
        <ion-menu class="menu" contentId="main" [swipeGesture]='false'>
            <ion-header>
                <ion-toolbar [mode]='"ios"'>
                    <img class="header-logo" src="assets/logo.png" />
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <div class="menu-item body_circe" routerLinkActive="active" [routerLink]='"/system/ad-list"'>
                    <div class="svg-container"><app-svg-icon [name]="'menu/tasks-search'" [fill]='"inherit"'></app-svg-icon></div> Задания
                </div>
                <div class="menu-item body_circe" routerLinkActive="active" [routerLink]='"/system/selfworker-list"'>
                    <div class="svg-container"><app-svg-icon [name]="'menu/person-search'" [fill]='"inherit"'></app-svg-icon></div> Самозанятые
                </div>
                <div class="menu-item body_circe" routerLinkActive="active" [routerLink]='"/my-ads"' *ngIf='auth'>
                    <div class="svg-container"><app-svg-icon [name]="'menu/tasks'" [fill]='"inherit"'></app-svg-icon></div> Мои задания
                </div>
                <div class="menu-item body_circe" routerLinkActive="active" [routerLink]='"/checks"' *ngIf='auth'>
                    <div class="svg-container"><app-svg-icon [name]="'menu/checks'" [fill]='"inherit"'></app-svg-icon></div> Мои чеки
                </div>
                <div class="menu-item body_circe" routerLinkActive="active" [routerLink]='"/system/dialogs"' *ngIf='auth'>
                    <div class="svg-container"><app-svg-icon [name]="'menu/dialogs'" [fill]='"inherit"'></app-svg-icon></div> Диалоги
                    <span class="dot" [class.unread]="unreadCount$ | async"></span>
                </div>
                <div class="menu-item body_circe" routerLinkActive="active" [routerLink]='"/notification"' *ngIf='auth'>
                    <div class="svg-container"><app-svg-icon [name]="'menu/notify'" [fill]='"inherit"'></app-svg-icon></div> Оповещения
                    <span class="dot" [class.unread]="unreadNotifyExist$ | async"></span>
                </div>
                <div class="menu-item body_circe" routerLinkActive="active" [routerLink]='"/system/profile"'>
                    <div class="svg-container"><app-svg-icon [name]="'menu/profile'" [fill]='"inherit"'></app-svg-icon></div> Профиль
                    <span class="dot" [class.unread]="false"></span>
                </div>

                <div class="menu-item body_circe button" (click)='goTaskAdd()'>
                    <div class="svg-container"><app-svg-icon [name]="'menu/plus'" [fill]='"var(--ion-color-white)"'></app-svg-icon></div> Разместить
                    <span class="dot" [class.unread]="false"></span>
                </div>

            </ion-content>
        </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
    </ion-split-pane>
</ion-app>
