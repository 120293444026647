<div class="review">
    <div class="review__rating-container">
        <app-ui-rating-line [rating]="review.ratingValue"></app-ui-rating-line>
        <span class="caption_circe review__rating-text">{{ review.ratingValue | reviewText }}</span>
    </div>

    <div class="review__text-container">
        <div class="review__text body_circe">{{ review.text }}</div>
    </div>

    <div class="review__info-container" (click)="handlerClickProfile(review.profile._id)">
        <div class="review__profile caption_circe">{{ review.profile.name + ' ' + review.profile.lastName }}</div>
        <div class="review__date caption_circe">{{ review.dateCreate | date: 'HH:mm dd.MM.yyyy' }}</div>
    </div>
</div>
