<ion-header [mode]="'ios'">
    <div class="header-address-grid-wrapper">
        <div class="header-address-left-grid header-address-column">
            <app-back-button [func]="back()"></app-back-button>
        </div>

        <div class="header-address-column" (click)="focusSearch()">
            <div class="search-address-container-input">
                <input
                    #inputSearch
                    class="search-input body_secondary_rubik"
                    type="search"
                    [placeholder]="'Мое местоположение...'"
                    [(ngModel)]="searchValue"
                    (ngModelChange)="search($event)"
                    (focus)="onFocusInput($event)"
                />
                <div class="close-container" (click)="clearSearchInput()">
                    <app-svg-icon
                        [stroke]="'var(--ion-color-secondary-text)'"
                        [height]="8"
                        class="close"
                        [name]="'close'"
                    ></app-svg-icon>
                </div>
            </div>
        </div>
    </div>
</ion-header>

<ion-content forceOverscroll="false" [scrollX]="false" [scrollY]="false">
    <div [class.enableRadius]="enableRadius" style="height: 100vh;">
        <div #mapOverlay class="map_overlay changeOverlay">
            <app-loading-spinner></app-loading-spinner>
        </div>

      <div style="position: relative;">
        <div id="map_search" [style.pointerEvents]="suggestions ? 'none' : 'all'"></div>
        <div class="mark">
          <img src="/assets/icons/map/marker.svg" />
        </div>
        <div *ngIf="enableRadius" class="circle"></div>
      </div>

        <div class="map-approximation" *ngIf="!enableRadius">
            <div (click)="zoomMap(true)"><a class="map-approximation__container map-approximation__plus">+</a></div>
            <div (click)="zoomMap(false)"><a class="map-approximation__container map-approximation__minus">-</a></div>
        </div>

        <div class="button-container">
            <div class="location1 bottom-safe-area" [class.location1-disabled]="disableLocBtn" (click)="setMyPosition()">
                <img src="./assets/icons/map/arrow.png" alt="" class="location-img" />
            </div>
        </div>

        <div *ngIf="enableRadius" class="fixed-bottom-container">
            <div class="filter-field" *ngIf="enableRadius">
                <div class="left-container">
                    <div class="title caption_circe">Поиск на расстоянии {{radius}} км</div>
                    <app-ui-range [value]="radius ? radius : 1.5" (valueChange)="changeRadius($event, false)" [rangeSteps]="[1.5, 5, 10, 15, 30, 50, 100]"></app-ui-range>
                </div>
            </div>

            <app-button [class]="'primary'" [disabled]="disableBtn" (btnClick)="selectAddress()">Готово</app-button>
        </div>

        <div *ngIf="!enableRadius" class="fixed-bottom-container-non-radius">
            <app-button [class]="'primary'" [disabled]="disableBtn" (btnClick)="selectAddress()">Готово</app-button>
        </div>

        <div class="search-content" #searchContent [hidden]="!suggestions">
            <div class="search-content-container my-location" (click)="setMyPosition()" [hidden]="searchValue.length">
                <div class="search-content-location">
                    <app-svg-icon [stroke]="'var(--ion-color-accent)'" [name]="'map/location-outline'"></app-svg-icon>
                </div>
                <div class="search-content-text">
                    <div class="body_rubik address-street">Мое местоположение</div>
                </div>
                <div class="search-content-arrow">
                    <app-svg-icon [name]="'right-arrow'" [stroke]="'var(--ion-color-additional-text)'"></app-svg-icon>
                </div>
            </div>

            <div class="search-content-container" *ngFor="let address of addressList" (click)="selectCoordinates(address.address)">
                <div class="search-content-text">
                    <div class="body_rubik address-street">{{ address.address.streetAddress }}</div>
                    <div class="caption_rubik address-country">{{ address.address.city || address.address.region }}</div>
                </div>
            </div>

        </div>
    </div>
</ion-content>
