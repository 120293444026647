import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-ui-labeld-text-view',
    templateUrl: './ui-labeld-text-view.component.html',
    styleUrls: ['./ui-labeld-text-view.component.scss'],
})
export class UiLabeldTextViewComponent {
    @Input() label!: string;
    @Input() text!: string;

    constructor() {}
}
