import { Pipe, PipeTransform } from '@angular/core';
import { isCurrentYear, isToday, isYesterday } from 'src/app/core/utils/date-helpers';
import * as moment from 'moment';

@Pipe({
    name: 'dateAd',
})
export class DateAdPipe implements PipeTransform {

    transform(value: any) {
        if (isToday(moment(value))) {
            return `Размещено ` + moment(value).format('HH:mm');
        } else if (isYesterday(moment(value))) {
            return `Размещено вчера`;
        } else if (isCurrentYear(moment(value))) {
            return `Размещено ` + moment(value).locale('ru').format('D MMMM');
        } else {
            return `Размещено ` + moment(value).format('DD.MM.YY');
        }

    }

}
