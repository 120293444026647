<div class="review-app-container">
  <div class="review-app-close">
    <app-svg-icon (click)="close()" [stroke]="'var(--ion-color-secondary-text)'" [height]="10" [name]="'close'"></app-svg-icon>
  </div>
  <h5 class="review-app-title font_bold">Нравится Маркетплейс?</h5>
  <div class="review-app-text body_secondary_circe">Оцените наше приложение, чтобы увеличить количество заданий и помочь другим самозанятым узнать о сервисе.</div>

  <div class="review-app-choice">
    <div class="review-app-choice-button review-app-choice-dislike" (click)="dislike()"><img src="/assets/pictures/app-review/app-review-dislike.png"></div>
    <div class="review-app-choice-button review-app-choice-like" (click)="like()"><img src="/assets/pictures/app-review/app-review-like.png"></div>
  </div>

  <div class="review-app-not-now body_circe" (click)="close()">Не сейчас</div>

</div>
