<span [hidden]="!prefix || !val || !val.toString().length" class="body_rubik prefix font_regular">{{ prefix }}</span>
<input
    class="body_rubik currency font_regular"
    #currency
    [disabled]="disabled"
    [placeholder]="placeholder"
    [ngModel]="val"
    inputmode="numeric"
    type="text"
    appMask
    (ngModelChange)="inputCurrency($event)"
    [szMask]="currencyPatterns"
    (keyup.enter)="clickEnterEv()"
/>
<span [hidden]="!val || !val.toString().length || toNum(val) < 1" class="body_rubik suffix font_regular">₽</span>
