import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlatformService } from '../../../core/services/platform.service';

@Component({
    selector: 'app-infinite-scroll',
    templateUrl: './infinite-scroll.component.html',
    styleUrls: ['./infinite-scroll.component.scss'],
})
export class InfiniteScrollComponent implements OnInit {
    @Input() disabled: boolean = false;
    @Input() threshold = '30%';
    @Input() position: 'top' | 'bottom' = 'bottom';
    @Output() infinite: EventEmitter<any> = new EventEmitter<any>();

    constructor(private platformService: PlatformService) {
    }

    ngOnInit() {
    }

    isIos() {
        return this.platformService.isIos();
    }

    async loadData(ev) {
        this.infinite.emit(ev);
    }
}
