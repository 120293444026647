export const setCookie = (
    key: string,
    value: string,
    expireDays: number | null,
    expireHours: number | null,
    expireMinutes: number | null,
    expireSeconds: number | null,
) => {
    const expireDate = new Date();
    if (expireDays) {
        expireDate.setDate(expireDate.getDate() + expireDays);
    }
    if (expireHours) {
        expireDate.setHours(expireDate.getHours() + expireHours);
    }
    if (expireMinutes) {
        expireDate.setMinutes(expireDate.getMinutes() + expireMinutes);
    }
    if (expireSeconds) {
        expireDate.setSeconds(expireDate.getSeconds() + expireSeconds);
    }
    document.cookie = key + '=' + encodeURI(value) +
        ';domain=' + getCookieUrl() +
        ';path=/' +
        ';expires=' + expireDate.toUTCString();
};

export const deleteCookie = (name: string) => {
    setCookie(name, '', null, null, null, 1);
};

const getCookieUrl = () => {
    if (window.location.hostname.match(/[a-z]/i) && !window.location.hostname.includes('localhost')) {
        return '.' +
            window.location.hostname.split('.')[window.location.hostname.split('.').length - 2] +
            '.' +
            window.location.hostname.split('.')[window.location.hostname.split('.').length - 1];
    } else {
        return window.location.hostname;
    }
};


