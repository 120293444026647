import {Injectable} from '@angular/core';
import {PlatformService} from './platform.service';
import {Observable} from 'rxjs';
import {loadScript, pause} from "../utils/helpers";
import {environment} from "../../../environments/environment";

declare let google;

@Injectable({
  providedIn: 'root',
})
export class MapGoogleService {

  mapScriptInited = false;
  mapScriptLoad = false;

  constructor(private platformService: PlatformService) {

  }

  async mapScriptInit() {
    return new Promise(async (resolve) => {
      if (this.mapScriptInited) {
        resolve(true);
      } else {
        if (!this.mapScriptLoad) {
          this.mapScriptLoad = true;
          (window as any).initGMaps = () => {
          };
          await loadScript(`https://maps.googleapis.com/maps/api/js?key=${environment.KEY_GOOGLE_MAPS}&callback=initGMaps&language=ru&region=RU`);
          this.mapScriptInited = true;
          this.mapScriptLoad = false;
          resolve(true);
        } else {
          const i = setInterval(() => {
            if (this.mapScriptInited) {
              resolve(true);
              clearInterval(i);
            }
          }, 1000);
        }
      }
    })
  }

  async createMap(options) {
    await this.mapScriptInit();
    return new Promise(async (resolve) => {
      const mapDiv = document.getElementById(options.map);
      if (mapDiv && google) {
        const map = new google.maps.Map(mapDiv, {
          center: options.center,
          zoom: options.zoom,
          disableDefaultUI: true,
          clickableIcons: false,
          rotateControl: false,
          panControl: true,
          zoomControl: false,
          fullscreenControl: false,
          scrollwheel: options.wheel || false,
          disableDoubleClickZoom: true,
          minZoom: options.minZoom,
          maxZoom: options.maxZoom,
        });
        await google.maps.event.addListenerOnce(map, 'idle', () => {
          mapDiv.style.height = `${String(mapDiv.clientHeight + 1)}px`;
          resolve(map);
        });
      }
    });
  }

  setOptions(map, options) {
    return map.setOptions(options);
  }

  destroyMap(map) {
    if (map && google) {
      google.maps.event.clearInstanceListeners(map);
    }
  }

  getMapZoom(map) {
    return map.getZoom();
  }

  async setCenter(map, center, zoom?) {
    if (!zoom) {
      zoom = this.getMapZoom(map);
    }
    if (center.lat && center.lng) {
      map.panTo(center);
      map.setZoom(zoom);
    } else {
      map.fitBounds(center);
    }
  }

  getCenter(map) {
    const center = map.getCenter();
    return {lat: center.lat(), lng: center.lng()};
  }

  getVisibleBounds(map) {
    const bounds = map.getBounds();
    const northeast = bounds.getNorthEast();
    const southwest = bounds.getSouthWest();
    return {
      northeast: {
        lng: northeast.lng(),
        lat: northeast.lat(),
      }, southwest: {
        lng: southwest.lng(),
        lat: southwest.lat(),
      },
    };
  }

  containsBounds(map, marker) {
    return map.getBounds().contains({
      lat: marker.getPosition().lat(),
      lng: marker.getPosition().lng()
    });
  }

  createMarker(map, marker, clickFunction) {
    let newMarker;
    newMarker = new google.maps.Marker({
      position: {
        lat: marker.coordinates.lat,
        lng: marker.coordinates.lng,
      },
      data: marker.data,
      icon: {
        url: marker.icon.url,
        scaledSize: marker.icon.size,
      },
    });
    newMarker.addListener('click', () => {
      this.setCenter(map, {lat: marker.coordinates.lat, lng: marker.coordinates.lng}, this.getMapZoom(map)).then(() => {
        clickFunction();
      });
    });
    newMarker.setMap(map);
    return newMarker;
  }

  setIconMarker(marker, icon) {
    let newIcon;
    newIcon = {
      scaledSize: icon.size,
      url: icon.url,
    };
    marker.setIcon(newIcon);
  }

  getMarkerData(marker) {
    return marker.data;
  }

  removeMarker(map, marker) {
    if (marker.setMap) {
      return marker.setMap(null);
    }
  }

  createCircle(map, options) {
    let circle;
    circle = new google.maps.Circle({
      strokeColor: 'rgba(29, 46, 105, 0.3)',
      strokeWidth: 1,
      fillColor: 'rgba(0, 122, 255, 0.3)',
      map,
      center: options.center,
      radius: options.radius,
    });
    return {
      circle,
      bounds: this.getCircleBounds(circle),
    };
  }

  getCircleBounds(circle) {
    const bounds = circle.getBounds();
    const northeast = bounds.getNorthEast();
    const southwest = bounds.getSouthWest();
    const boundsCircle = new google.maps.LatLngBounds();
    boundsCircle.extend({lat: northeast.lat(), lng: northeast.lng()});
    boundsCircle.extend({lat: southwest.lat(), lng: southwest.lng()});
    return bounds;
  }

  changeCircle(circle, options) {
    circle.setCenter(options.center);
    circle.setRadius(options.radius);
    return this.getCircleBounds(circle);
  }

  removeCircle(map, circle) {
    if (circle) {
      circle.setMap(null);
    }
  }

  subscribeMapEvent(map, event) {
    return new Observable((observer) => {
      const listener = map.addListener(event, (res) => {
        observer.next(res);
      });
      return () => {
        google.maps.event.removeListener(listener);
      };
    });
  }

}
