import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-ui-rating-select',
    templateUrl: './ui-rating-select.component.html',
    styleUrls: ['./ui-rating-select.component.scss'],
})
export class UiRatingSelectComponent implements OnInit {
    @Input() rating = 0;
    @Output() ratingChange = new EventEmitter<number>();

    constructor() {}

    ngOnInit() {}

    setRating(ratingValue: number) {
        this.rating = ratingValue;
        this.ratingChange.emit(ratingValue);
    }
}
