import { Component, Input, OnInit } from '@angular/core';
import { ICategory } from '../../../core/category/interface/category';
import { CategoryService } from '../../../core/services/category.service';

@Component({
    selector: 'app-ui-ad-link-with-label',
    templateUrl: './ui-ad-link-with-label.component.html',
    styleUrls: ['./ui-ad-link-with-label.component.scss'],
})
export class UiAdLinkWithLabelComponent implements OnInit {
    @Input() category!: any;
    @Input() name!: any;
    @Input() label!: any;

    categoryInfo: any;

    constructor(private categoryService: CategoryService) {}

    async ngOnInit() {
        this.categoryInfo = { color: '', icon: '' };
        this.categoryInfo.color = (await this.categoryService.getCategoryById(this.category)).property!.color;
        this.categoryInfo.icon = await this.categoryService.getCategoryIconById(this.category);
    }
}
