import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-ui-icon',
    templateUrl: './ui-icon.component.html',
    styleUrls: ['./ui-icon.component.scss'],
})
export class UiIconComponent implements OnInit {
    @Input() icon = '';
    @Input() fillColor = '';
    @Input() strokeColor = '';
    constructor() {}

    ngOnInit() {}
}
