import * as moment from 'moment';

export const isToday = (momentDate) => {
    const REFERENCE = moment(); // fixed just for testing, use moment();
    const TODAY = REFERENCE.clone().startOf('day');
    return momentDate.isSame(TODAY, 'd');
};
export const isYesterday = (momentDate) => {
    const REFERENCE = moment(); // fixed just for testing, use moment();
    const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
    return momentDate.isSame(YESTERDAY, 'd');
};
export const isWithinAWeek = (momentDate) => {
    const REFERENCE = moment(); // fixed just for testing, use moment();
    const A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
    return momentDate.isAfter(A_WEEK_OLD);
};
export const isTwoWeeksOrMore = (momentDate) => {
    return !isWithinAWeek(momentDate);
};

export const isCurrentYear = (momentDate) => {
    const REFERENCE = moment();
    const YEAR = REFERENCE.clone().startOf('day');
    return momentDate.isSame(YEAR, 'year');
};
