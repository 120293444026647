import { Animation, createAnimation } from '@ionic/angular';

export const shadow = <T extends Element>(el: T): ShadowRoot | T => el.shadowRoot || el;

export const getIonPageElement = (element: HTMLElement) => {
  if (element.classList.contains('ion-page')) {
    return element;
  }

  const ionPage = element.querySelector(':scope > .ion-page, :scope > ion-nav, :scope > ion-tabs');

  if (ionPage) {
    return ionPage;
  }
  // idk, return the original element so at least something animates and we don't have a null pointer
  return element;
};

export function animation(navEl: HTMLElement, opts?: any): Animation {
  if (opts.mode === 'ios') {
    const DURATION = 700;
    const EASING = 'cubic-bezier(0.32,0.72,0,1)';
    const OPACITY = 'opacity';
    const TRANSFORM = 'transform';
    const CENTER = '0%';
    const OFF_OPACITY = 0.1;
    const isRTL = (navEl.ownerDocument as any).dir === 'rtl';
    const OFF_RIGHT = isRTL ? '-99.5%' : '99.5%';
    const OFF_LEFT = isRTL ? '50%' : '-50%';
    const enteringEl = opts.enteringEl;
    const leavingEl = opts.leavingEl;
    const backDirection = opts.direction === 'back';

    const pageEl = enteringEl;

    const rootAnimation = createAnimation();
    const enteringContentAnimation = createAnimation();

    rootAnimation
      .addElement(enteringEl)
      .duration(opts.duration || DURATION)
      .easing(opts.easing || EASING)
      .fill('both')
      .beforeRemoveClass('ion-page-invisible');

    if (leavingEl && navEl) {
      const navDecorAnimation = createAnimation();
      navDecorAnimation.addElement(navEl);
      rootAnimation.addAnimation(navDecorAnimation);
    }

    enteringContentAnimation.addElement(pageEl);

    rootAnimation.addAnimation(enteringContentAnimation);

    if (backDirection) {
      enteringContentAnimation
        .fromTo('transform', `translateX(${OFF_LEFT})`, `translateX(${CENTER})`)
        .fromTo('filter', `brightness(0.6)`, `brightness(0.99)`);
    } else {
      enteringContentAnimation.beforeClearStyles([OPACITY]).fromTo('transform', `translateX(${OFF_RIGHT})`, `translateX(${CENTER})`);
    }

    if (leavingEl) {
      const leavingContent = createAnimation();
      const leavingPageEl = leavingEl;

      leavingContent.addElement(leavingPageEl);
      rootAnimation.addAnimation(leavingContent);

      if (backDirection) {
        leavingContent
          .beforeClearStyles([OPACITY])
          .fromTo('transform', `translateX(${CENTER})`, isRTL ? 'translateX(-100%)' : 'translateX(100%)');

        const leavingPage = getIonPageElement(leavingEl) as HTMLElement;
        rootAnimation.afterAddWrite(() => {
          if (rootAnimation.getDirection() === 'normal') {
            leavingPage.style.setProperty('display', 'none');
          }
        });
      } else {
        leavingContent.fromTo('transform', `translateX(${CENTER})`, `translateX(${OFF_LEFT})`).fromTo(OPACITY, 1, OFF_OPACITY);
      }
    }
    return rootAnimation;
  } else {
    const backDirection = opts.direction === 'back';
    const enteringEl = opts.enteringEl;
    const leavingEl = opts.leavingEl;

    const ionPageElement = getIonPageElement(enteringEl);
    const enteringToolbarEle = ionPageElement.querySelector('ion-toolbar');
    const rootTransition = createAnimation();

    rootTransition.addElement(ionPageElement).fill('both').beforeRemoveClass('ion-page-invisible');

    if (backDirection) {
      rootTransition.duration(opts.duration || 200).easing('cubic-bezier(0.47,0,0.745,0.715)');
    } else {
      rootTransition
        .duration(opts.duration || 280)
        .easing('cubic-bezier(0.36,0.66,0.04,1)')
        .fromTo('opacity', 0.01, 1);
    }

    if (enteringToolbarEle) {
      const enteringToolBar = createAnimation();
      enteringToolBar.addElement(enteringToolbarEle);
      rootTransition.addAnimation(enteringToolBar);
    }

    if (leavingEl && backDirection) {
      rootTransition.duration(opts.duration || 200);

      const leavingPage = createAnimation();
      leavingPage
        .addElement(getIonPageElement(leavingEl))
        .onFinish(currentStep => {
          if (currentStep === 1 && leavingPage.elements.length > 0) {
            leavingPage.elements[0].style.setProperty('display', 'none');
          }
        })
        .fromTo('opacity', 1, 0);

      rootTransition.addAnimation(leavingPage);
    }

    return rootTransition;
  }
}
