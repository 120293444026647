import { Component, Input, OnInit } from '@angular/core';
import { decOfNum } from '../../../core/utils/helpers';

@Component({
    selector: 'app-review-count',
    templateUrl: './review-count.component.html',
    styleUrls: ['./review-count.component.scss'],
})
export class ReviewCountComponent implements OnInit {
    @Input() count!: number;

    constructor() {}

    ngOnInit() {}

    decReview(num: number) {
        return decOfNum(num, ['отзыв', 'отзыва', 'отзывов']);
    }
}
