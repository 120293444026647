import { Component, Input, OnInit } from '@angular/core';
import { CategoryService } from '../../../core/services/category.service';

@Component({
    selector: 'app-ui-cat-map-icon',
    templateUrl: './ui-cat-map-icon.component.html',
    styleUrls: ['./ui-cat-map-icon.component.scss'],
})
export class UiCatMapIconComponent implements OnInit {
    @Input() category: any;

    constructor(private categoryService: CategoryService) {}

    ngOnInit() {}

    getIcon(name: string) {
        return this.categoryService.getCategoryIconByName(name);
    }
}
