import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationService } from '../services/notification.service';
import { NotificationActions } from './notification.actions';
import { switchMap, tap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { INotificationState } from './notification.reducer';
import { NotificationTypeEnum } from '../enums/notification-type.enum';

@Injectable()
export class NotificationEffects {
    setNotificationRead$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NotificationActions.setNotificationsRead),
                switchMap(() => this.notificationService.setNotificationRead()),
            ),
        { dispatch: false },
    );

    clickOnNotification$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(NotificationActions.notificationNavigate),
                tap(({ notification }) => {
                    switch (notification.type) {
                        case NotificationTypeEnum.archive:
                            this.navController.navigateForward(['ad-detail', notification.adID]);
                            break;

                        case NotificationTypeEnum.check:
                            this.navController.navigateForward(['ad-detail', notification.adID]);
                            break;

                        case NotificationTypeEnum.createInvoice:
                            this.navController.navigateForward(['ad-detail', notification.adID]);
                            break;

                        case NotificationTypeEnum.rejectInvoice:
                            this.navController.navigateForward(['ad-detail', notification.adID]);
                            break;

                        case NotificationTypeEnum.arbitration:
                            this.navController.navigateForward(['ad-detail', notification.adID]);
                            break;

                        case NotificationTypeEnum.review:
                            this.navController.navigateForward(['ad-detail', notification.adID]);
                            break;

                        case NotificationTypeEnum.notDone:
                            this.navController.navigateForward(['ad-detail', notification.adID]);
                            break;

                        case NotificationTypeEnum.editAd:
                            this.navController.navigateForward(['ad-detail', notification.adID]);
                            break;

                        case NotificationTypeEnum.swConfirmed:
                            this.navController.navigateForward(['ad-detail', notification.adID]);
                            break;

                        case NotificationTypeEnum.offer:
                            this.navController.navigateForward(['ad-detail', notification.adID]);
                            break;

                        case NotificationTypeEnum.suggest:
                            this.navController.navigateForward(['ad-detail', notification.adID]);
                            break;

                        case NotificationTypeEnum.deleteAd:
                            break;

                        case NotificationTypeEnum.swSelect:
                            this.navController.navigateForward(['ad-detail', notification.adID]);
                            break;

                        case NotificationTypeEnum.done:
                            this.navController.navigateForward(['ad-detail', notification.adID]);
                            break;

                        case NotificationTypeEnum.partner:
                            this.navController.navigateForward(['my-partners']);
                            break;
                    }
                }),
            );
        },
        { dispatch: false },
    );

    constructor(
        private readonly actions$: Actions,
        private readonly notificationService: NotificationService,
        private readonly navController: NavController,
        private readonly store: Store<INotificationState>,
    ) {}
}
