import {Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';

//TODO DataPicker

@Component({
    selector: 'app-input-date',
    templateUrl: './input-date.component.html',
    styleUrls: ['./input-date.component.scss'],
})
export class InputDateComponent implements OnInit {
    @ViewChild('date') date: any;

    _value: any;

  maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString();
  minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 100)).toISOString();

  customPickerOptions = {
    buttons: [{
      text: 'Выбрать',
      handler: (date) => {
        const newDate = new Date(`${date.year.value}/${date.month.value}/${date.day.value}`);
        this.valueChange.emit(newDate.toISOString());
      }
    }]
  }
    @Input() placeholder = '';
    @Input() set value(val: any) {
        if (val) {
            this._value = val;
        }
    }
    @Input() disabled = false;

    @ViewChild('ionDate') ionDate: any;

    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit() {
    }

    click() {
      this.ionDate.el.click();
    }
}
