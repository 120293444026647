import { Pipe, PipeTransform } from '@angular/core';
import * as sanitize from 'sanitize-html';

@Pipe({
    name: 'sanitizeHtml',
})
export class SanitizeHtmlPipe implements PipeTransform {
    sanitizeHtml = sanitize;

    sanitize(html) {
        return this.sanitizeHtml(html, {
            disallowedTagsMode: 'escape',
            allowedTags: ['a'],
            allowedAttributes: {
                a: ['href', 'target'],
            },
            allowedSchemes: ['tel', 'http', 'https'],
        });
    }

    transform(value: any, ...args: any[]): any {
        return this.sanitize(value);
    }
}
