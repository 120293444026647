import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'app-popup-review',
    templateUrl: './app-popup-review.component.html',
    styleUrls: ['./app-popup-review.component.scss'],
})
export class AppPopupReviewComponent implements OnInit {

    constructor(private popoverController: PopoverController) {
    }

    ngOnInit() {
    }

    like() {
        this.close('like');
    }

    dislike() {
        this.close('dislike');
    }

    close(action = null) {
        this.popoverController.dismiss({action});
    }

}
