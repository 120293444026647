import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-svg-icon',
    templateUrl: './svg-icon.component.html',
    styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent implements OnInit {
    @Input() name: any;
    @Input() width: any;
    @Input() height: any;
    @Input() fill: any;
    @Input() stroke: any;

    constructor() {}

    ngOnInit() {}
}
