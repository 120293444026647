import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-input-code',
    templateUrl: './input-code.component.html',
    styleUrls: ['./input-code.component.scss'],
})
export class InputCodeComponent implements OnInit {
    @Input() placeholder = '';
    @Input() value: any;
    @Input() disabled = false;

    @Output() inputEv: EventEmitter<any> = new EventEmitter<any>(false);
    @Output() clickEnter: EventEmitter<any> = new EventEmitter<any>(false);

    constructor() {}

    ngOnInit() {}

    inputCode(event: any) {
        if (event) {
            const code = event.replace(new RegExp('\\D', 'g'), '');
            this.inputEv.emit(code);
        }
    }

    clickEnterEv() {
        this.clickEnter.emit(true);
    }
}
