import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-ui-divider',
    templateUrl: './ui-divider.component.html',
    styleUrls: ['./ui-divider.component.scss'],
})
export class UiDividerComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
