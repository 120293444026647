import {Injectable} from '@angular/core';
import {FirebaseMessaging} from '@capacitor-firebase/messaging';
import {ApiService} from '../api/api.service';
import {NavController} from '@ionic/angular';
import {PushDataInterface} from './interfaces/push-data.interface';
import {PushTypeEnum} from './enums/push-type.enum';
import {PushPayloadInterface} from './interfaces/push-payload.interface';
import {Router} from '@angular/router';
import {Device} from '@capacitor/device';
import {Badge} from '@capawesome/capacitor-badge';
import {PlatformService} from "../services/platform.service";
import {ServiceChecker} from "capacitor-plugin-gmshms-checker";

@Injectable({
  providedIn: 'root',
})
export class PushService {
  constructor(
    private readonly apiService: ApiService,
    private readonly navController: NavController,
    private router: Router,
    private platformService: PlatformService,
  ) {
  }

  async init() {
    if (!this.platformService.isCordova()) {
      return;
    }
    if (this.platformService.isAndroid()) {
      const googleAvailability = await ServiceChecker.isGMSAvailable();
      if (!googleAvailability.value) {
        return;
      }
    }
    FirebaseMessaging.addListener('notificationActionPerformed', event => {
      if (event.notification.data) {
        this.tapHandler({
          type: (event.notification.data as any).type,
          payload: (event.notification.data as any).payload
        });
      }
    });
  }

  // Device register
  async registerDevice() {
    if (!this.platformService.isCordova()) {
      return;
    }
    if (this.platformService.isAndroid()) {
      const googleAvailability = await ServiceChecker.isGMSAvailable();
      if (!googleAvailability.value) {
        return;
      }
    }
    const granted = await this.hasPermission();
    if (granted) {
      const token = await this.getToken();
      const deviceId = await Device.getId();
      const deviceInfo = await Device.getInfo();
      return this.apiService.registerDevice({
        pushToken: token,
        uuid: deviceId.uuid,
        version: deviceInfo.osVersion,
        phoneModel: deviceInfo.model,
        platform: deviceInfo.platform,
      });
    } else {
      this.grantPermission().then(() => {
        this.registerDevice();
      });
    }
  }

  async unregisterDevice() {
    if (!this.platformService.isCordova()) {
      return;
    }
    if (this.platformService.isAndroid()) {
      const googleAvailability = await ServiceChecker.isGMSAvailable();
      if (!googleAvailability.value) {
        return;
      }
    }
    await this.deleteTokenApi();
    return await this.unregisterToken();
  }

  async deleteTokenApi() {
    if (!this.platformService.isCordova()) {
      return;
    }
    const token = await this.getToken();
    return await this.apiService.unregisterDevice({
      pushToken: token,
    });
  }

  // Token
  async getToken(): Promise<string> {
    if (!this.platformService.isCordova()) {
      return;
    }
    if (this.platformService.isAndroid()) {
      const googleAvailability = await ServiceChecker.isGMSAvailable();
      if (!googleAvailability.value) {
        return;
      }
    }
    return (await FirebaseMessaging.getToken()).token;
  }

  private unregisterToken() {
    if (!this.platformService.isCordova()) {
      return;
    }
    return FirebaseMessaging.deleteToken();
  }

  // Permission
  async grantPermission() {
    if (!this.platformService.isCordova()) {
      return;
    }
    if (this.platformService.isAndroid()) {
      const googleAvailability = await ServiceChecker.isGMSAvailable();
      if (!googleAvailability.value) {
        return;
      }
    }
    const perms = (await FirebaseMessaging.requestPermissions()).receive;
    return perms == 'prompt' || perms == 'prompt-with-rationale' || perms == 'granted';
  }

  async hasPermission() {
    if (!this.platformService.isCordova()) {
      return;
    }
    if (this.platformService.isAndroid()) {
      const googleAvailability = await ServiceChecker.isGMSAvailable();
      if (!googleAvailability.value) {
        return;
      }
    }
    const perms = (await FirebaseMessaging.checkPermissions()).receive;
    return perms == 'granted';
  }

  // Badge
  setBadgeNumber(num) {
    if (!this.platformService.isCordova()) {
      return;
    }
    return Badge.set(num);
  }

  private tapHandler(message: PushDataInterface) {
    if (!this.platformService.isCordova()) {
      return;
    }
    const payload: PushPayloadInterface = JSON.parse(message.payload);
    this.router.navigateByUrl('/system');
    switch (message.type) {
      case PushTypeEnum.createReview:
        this.navController.navigateForward(['ad-detail', payload.adID]);
        break;
      case PushTypeEnum.message:
        this.navController.navigateForward(['im', payload.peerID]);
        break;
      case PushTypeEnum.notification:
        this.navController.navigateForward(['notification']);
        break;
      case PushTypeEnum.offer:
        this.navController.navigateForward(['ad-offers', payload.adID]);
        break;
      case PushTypeEnum.task:
        this.navController.navigateForward(['ad-detail', payload.adID]);
        break;
      case PushTypeEnum.partner:
        this.navController.navigateForward(['my-partners']);
        break;
    }
  }
}
