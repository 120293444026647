import { Component, Input, OnInit } from '@angular/core';
import { IImage } from '../../../core/images/interfaces/image.interface';
import { ImageSizeTypeEnum } from '../../../core/images/enums/image-size-type.enum';
import { getImageSrcBySize } from '../../../core/utils/helpers';

@Component({
    selector: 'app-ui-image-preview',
    templateUrl: './ui-image-preview.component.html',
    styleUrls: ['./ui-image-preview.component.scss'],
})
export class UiImagePreviewComponent implements OnInit {
    public _src: any;
    private size = ImageSizeTypeEnum.x;

    @Input() set src(image: IImage | string) {
        this.setImage(image);
    }

    @Input() isUploading = false;

    setImage(image: any) {
        if (typeof image === 'object') {
            this._src = getImageSrcBySize(image, this.size);
        } else {
            this._src = image;
        }
    }

    constructor() {}

    ngOnInit() {}
}
