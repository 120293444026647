import { Component, Input, OnInit } from '@angular/core';
import { ImageSizeTypeEnum } from '../../../core/images/enums/image-size-type.enum';
import { IImage } from '../../../core/images/interfaces/image.interface';
import { getImageSrcBySize } from '../../../core/utils/helpers';
import { IMAGE_PLUG } from '../../../core/utils/mokc/iimage-mock';

@Component({
    selector: 'app-ui-avatar',
    templateUrl: './ui-avatar.component.html',
    styleUrls: ['./ui-avatar.component.scss'],
})
export class UiAvatarComponent implements OnInit {
    public _src: any;
    private size = ImageSizeTypeEnum.x;

    @Input() mode?: 'header' | 'list' | 'profile';

    @Input() set src(image: IImage | string) {
        this.setImage(image || IMAGE_PLUG);
    }

    @Input() isUploading = false;

    setImage(image: any) {
        if (typeof image === 'object') {
            this._src = getImageSrcBySize(image, this.size);
        } else {
            this._src = image;
        }
    }

    constructor() {}

    ngOnInit() {}
}
