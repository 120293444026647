import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {EMPTY, Observable, throwError, TimeoutError} from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { ToastService } from '../services/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthenticationService, private toastService: ToastService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authService.isAcceptRequest(request) && !request.url.includes('setOnline')) {
            return next.handle(request).pipe(
                timeout(15000),
                catchError((err) => {
                    if (err.statusText === 'Unknown Error') {
                        return throwError('Ошибка соединения');
                    } else if (err instanceof TimeoutError) {
                        this.toastService.presentToast('Ошибка соединения');
                        return throwError('Ошибка соединения');
                    } else if (err.status === 401) {
                        this.authService.logout();
                        return throwError('Ошибка авторизации');
                    } else if (err.status === 429) {
                      return throwError('Превышено количество запросов, попробуйте позднее');
                    }
                    if (err) {
                      const error = err?.error?.message || err.statusText;
                      return throwError(error);
                    } else return EMPTY;
                }),
            );
        }

        return next.handle(request);
    }
}
