<span [hidden]="!prefix" class="body_rubik prefix font_regular">+7{{ _value ? ' (' : ' ' }}</span>

<input data-cy="input-phone"
    class="body_rubik font_regular"
    [disabled]="disabled"
    [placeholder]="prefix ? '' : placeholder"
    [(ngModel)]="_value"
    inputmode="numeric"
    [mask]="_value.length ? '000) 000 00 00' : ''"
    type="text"
    (focus)="showPrefix(true)"
    (blur)="showPrefix(false)"
    (input)="inputNumber($event)"
    (paste)="inputNumber($event)"
    (keyup.enter)="clickEnterEv()"
/>
