import { createAnimation, Animation } from '@ionic/core';

import { SwipeToCloseDefaults } from '../gestures/bootm-modal.swipe-to-close';

export const bottomModalEnterAnimation = (heightPlatform, isIos: boolean = false, backdropDismiss = true) => (
    baseEl: HTMLElement,
    presentingEl?: HTMLElement,
): Animation => {

    const ionSafeAreaBottom = parseInt(
        getComputedStyle(document.body).getPropertyValue('--ion-safe-area-bottom'),
        10,
    );

    const modalHeight = baseEl.getElementsByTagName('app-modal-content-wrapper')[0].clientHeight + 80;
    const height = heightPlatform - ionSafeAreaBottom - modalHeight + 'px';

    baseEl.querySelector('ion-backdrop').style.pointerEvents = backdropDismiss ? 'all' : 'none';

    const backdropAnimation = createAnimation()
        .addElement(baseEl.querySelector('ion-backdrop')!)
        .fromTo('opacity', 0.01, 0.5)
        .beforeStyles({
            'pointer-events': 'none',
        })
        .afterClearStyles(['pointer-events']);

    const wrapperAnimation = createAnimation()
        .addElement(baseEl.querySelectorAll('.modal-wrapper, .modal-shadow')!)
        .beforeStyles({ opacity: 1 })
        .fromTo('transform', 'translateY(100vh)', `translateY(${height})`);

    const baseAnimation = createAnimation()
        .addElement(baseEl)
        .easing('cubic-bezier(0.32,0.72,0,1)')
        .duration(500)
        .addAnimation(wrapperAnimation);

    if (presentingEl && isIos) {
        const hasCardModal =
            presentingEl.tagName === 'ION-MODAL' &&
            (presentingEl as HTMLIonModalElement).presentingElement !== undefined;

        const presentingAnimation = createAnimation().beforeStyles({
            'transform': 'translateY(0)',
            'transform-origin': 'top center',
            'overflow': 'hidden',
        });

        const bodyEl = document.body;

        /**
         * Fallback for browsers that does not support `max()` (ex: Firefox)
         * No need to worry about statusbar padding since engines like Gecko
         * are not used as the engine for standlone Cordova/Capacitor apps
         */
        const transformOffset = !CSS.supports('width', 'max(0px, 1px)')
            ? '30px'
            : 'max(30px, var(--ion-safe-area-top))';
        const modalTransform = hasCardModal ? '-10px' : transformOffset;
        const toPresentingScale = SwipeToCloseDefaults.MIN_PRESENTING_SCALE;
        const finalTransform = `translateY(${modalTransform}) scale(${toPresentingScale})`;

        presentingAnimation
            .afterStyles({
                transform: finalTransform,
            })
            .beforeAddWrite(() => bodyEl.style.setProperty('background-color', 'black'))
            .addElement(presentingEl)
            .keyframes([
                { offset: 0, filter: 'contrast(1)', transform: 'translateY(0px) scale(1)', borderRadius: '0px' },
                { offset: 1, filter: 'contrast(0.85)', transform: finalTransform, borderRadius: '10px 10px 0 0' },
            ]);

        baseAnimation.addAnimation(presentingAnimation);
    } else {
        baseAnimation.addAnimation(backdropAnimation);
    }

    return baseAnimation;
};
