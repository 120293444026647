import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {NavController} from "@ionic/angular";

@Injectable({
  providedIn: 'root',
})
export class AddressGuard implements CanActivate {

  constructor(
    private router: Router,
    private navCtrl: NavController
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    if (this.router.getCurrentNavigation().extras.state) {
      return true;
    } else {
      this.navCtrl.navigateRoot('/');
      return false;
    }
  }

}
