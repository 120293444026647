<div *ngIf="type == 'text'" class="text_normal">
    <app-svg-icon
            class="star stars-padding"
            [width]="14"
            [height]="14"
            [fill]="'var(--ion-color-stars)'"
            [name]="'star'"
    ></app-svg-icon>
    <span class="body_secondary_circe font_bold text">{{ rating(sum, count) }}</span>
</div>

<div *ngIf="type == 'text_reverse'" class="text_reverse">
    <app-svg-icon
        class="star stars-padding"
        [width]="12"
        [height]="12"
        [fill]="'var(--ion-color-stars)'"
        [name]="'star'"
    ></app-svg-icon>
    <span class="caption_circe font_bold text">{{ rating(sum, count) }}</span>
</div>

<div *ngIf="type == 'stars'" class="only-icon">
    <app-svg-icon
        class="star stars-padding"
        [width]="14"
        [height]="16"
        *ngFor="let star of [].constructor(5); let i = index"
        [fill]="i < sum ? 'var(--ion-color-stars)' : 'var(--ion-color-border-active)'"
        [name]="'star'"
    ></app-svg-icon>
</div>
