<div class="user-view__container" [class.contact]="contact">
    <div (click)="goToUser()">
        <div class="user-view__photo">
            <app-ui-avatar [src]="user.attachment[0]"></app-ui-avatar>
        </div>
        <div class="user-view__organisation" *ngIf="user.type == userTypeEnum.organisation">
            <app-svg-icon [fill]="'var(--ion-color-secondary-text)'" [height]="15" [name]="'company'"></app-svg-icon>
        </div>
    </div>
    <div class="user-view__info" (click)="goToUser()">
        <div class="user-view__online-status">
            <app-ui-online-status [lastOnlineDate]="user.stats.lastOnline"></app-ui-online-status>
        </div>
        <div class="user-view__name font_bold body_rubik">{{ user.name + ' ' + (user.lastName || '') }}</div>

        <div class="user-view__stat-container">
            <app-ui-sw-item-stat [profileStat]="user.stats"></app-ui-sw-item-stat>
        </div>
    </div>

    <div *ngIf="contact" class="user-view__arrow-container">
        <app-svg-icon
                (click)="write()"
                [name]="'dialogs-outline'"
                [fill]="'var(--ion-color-accent)'"
        ></app-svg-icon>
        <app-svg-icon
                (click)="call()"
                class="call"
                [name]="'call-outline'"
                [stroke]="'var(--ion-color-accent)'"
        ></app-svg-icon>
    </div>

</div>
