import { Injectable } from '@angular/core';
import {ModalController} from '@ionic/angular';
import { AuthPage } from './auth.page';
import { AuthNavigationService } from './auth-navigation.service';
import { modalEnterAnimation } from '../../animation/modal/modal.enter';
import { modalLeaveAnimation } from '../../animation/modal/modal.leave';

@Injectable({
    providedIn: 'root',
})
export class AuthOpenModalService {
    constructor(
        private modalController: ModalController,
        private authNavigationService: AuthNavigationService,
    ) {}

    async openModalAuth(navigateUrl?: string): Promise<boolean> {
        return new Promise(async (resolve) => {
            const modal = await this.modalController.create({
                component: AuthPage,
                componentProps: {navigateUrl},
                enterAnimation: modalEnterAnimation,
                leaveAnimation: modalLeaveAnimation,
            });
            modal.present();
            this.authNavigationService.backButtonStart();
            modal.onWillDismiss().then(({ data }) => {
                this.authNavigationService.backButtonStop();
                resolve(data.auth);
            });
        });
    }
}
