import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {EMPTY, Observable, ReplaySubject} from 'rxjs';
import {TAccountUser} from './types/account-user.type';
import {catchError, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {AppCacheService} from '../services/cache.service';
import {IAdItem} from '../ad/interface/ad-items.interface';
import {fromPromise} from 'rxjs/internal-compatibility';
import {CategoryService} from '../services/category.service';
import {EditAccountAdDto} from './dto/account-ad-edit.dto';

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    private _accountState: ReplaySubject<TAccountUser> = new ReplaySubject(1);
    private _account$ = this._accountState.asObservable();

    constructor(
        private readonly _apiService: ApiService,
        private readonly _cacheService: AppCacheService,
        private categoryService: CategoryService,
    ) {}

    loadState(): Promise<TAccountUser> {
        // this._cacheService
        //     .loadFromObservable('accountUser', this._apiService.getAccount(), 'accountUser')
        //     .pipe(take(1))
        //     .subscribe(this._accountState);
        return new Promise((resolve, reject) => {
            this._apiService.getAccount().pipe(take(1), catchError((error) => {
                reject();
                return EMPTY;
            }), filter((res) => !!res)).subscribe((result: TAccountUser) => {
                resolve(result);
                this._accountState.next(result);
            });
        });
    }

    async refreshState(): Promise<any> {
        await this._cacheService.clearGroup('accountUser');
        return this.loadState();
    }

    getProfileInfo(): Observable<TAccountUser> {
        return this._account$;
    }

    async updateProfile(fields) {
        const updatedAccount = await this._apiService.updateAccount(fields);
        this._accountState.next(updatedAccount);
        return;
    }

    async updatePhone(smsCredentials) {
        return await this._apiService.updatePhone(smsCredentials);
    }

    async deleteAccount() {
        return await this._apiService.deleteAccount();
    }
    // Ad

    getAd(params, type: 'customer' | 'executor' | null = null, commonUserID: string | null = null): Observable<IAdItem[]> {
        return this._apiService.getAccountAd(params, type, commonUserID).pipe(
            switchMap((adItems) => {
                const itemsQuery = Promise.all(
                    adItems.map(async (adItem) => {
                        adItem.categoryInfo = { color: '', name: '', icon: '' };
                        adItem.categoryInfo.color = (
                            await this.categoryService.getCategoryById(adItem.categories[0])
                        ).property.color;
                        adItem.categoryInfo.name = await this.categoryService.getDisplayCategoryName(adItem.categories);
                        adItem.categoryInfo.icon = await this.categoryService.getCategoryIconById(adItem.categories[0]);
                        return adItem;
                    }),
                );

                return fromPromise(itemsQuery).pipe(
                    map((items: IAdItem[]) => {
                        return items;
                    }),
                ) as Observable<IAdItem[]>;
            }),
        );
    }

    async edit(id: string, ad: EditAccountAdDto) {
        return this._apiService.editAd(id, ad);
    }

    // Push

    // registerDevice() {
    // }
    //
    // unregisterDevice() {
    // }
}
