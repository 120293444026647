import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-ad-status',
    templateUrl: './ad-status.component.html',
    styleUrls: ['./ad-status.component.scss'],
})
export class AdStatusComponent implements OnInit {
    @Input() status;

    constructor() {}

    ngOnInit() {}
}
