import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { ApiService } from '../api/api.service';
import { StatsService } from '../stats/stats.service';

@Injectable()
export class OnlineInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthenticationService,
        private readonly apiService: ApiService,
        private readonly statsService: StatsService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            this.authService.getAccountID() &&
            this.authService.isAcceptRequest(request) &&
            !request.url.includes('setOnline')
        ) {
            this.statsService.setOnline();
        }

        return next.handle(request);
    }
}
