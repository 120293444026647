import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'statusName',
})
export class StatusNamePipe implements PipeTransform {
    status = {
        open: 'Открыто',
        in_progress: 'Выполняется',
        verification: 'На проверке',
        performed: 'Выполнено',
        not_performed: 'Не выполнено',
        canceled: 'Отменено',
        archive: 'В архиве',
        rejected: 'Отклонено',
        arbitration: 'Арбитраж',
    };

    transform(value: any): any {
        return this.status[value] ? this.status[value] : value;
    }
}
