import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-ui-rating-line',
    templateUrl: './ui-rating-line.component.html',
    styleUrls: ['./ui-rating-line.component.scss'],
})
export class UiRatingLineComponent implements OnInit {
    @Input() rating!: number;

    constructor() {}

    ngOnInit() {}
}
