export enum NotificationTypeEnum {
    archive = 'archive',
    done = 'done',
    notDone = 'not_done',
    offer = 'offer',
    review = 'review',
    editAd = 'edit_ad',
    deleteAd = 'delete_ad',
    swSelect = 'sw_select',
    check = 'check',
    arbitration = 'arbitration',
    swConfirmed = 'sw_confirmed',
    suggest = 'suggest',
    createInvoice = 'createInvoice',
    rejectInvoice = 'rejectInvoice',
    partner = 'partner',
}
