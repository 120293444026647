import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

export type TSmsToken = string;

@Injectable({
    providedIn: 'root',
})
export class SmsService {
    constructor(private readonly apiService: ApiService) {}

    getSms(phone): Observable<TSmsToken> {
        return this.apiService.getSms({ phone });
    }

    getSmsWithCaptchaToken(phone, captchaToken): Observable<TSmsToken> {
      return this.apiService.getSmsWithCaptchaToken({ phone, captchaToken });
    }
}
