export const environment = {
    production: true,
    API_URL: 'https://market.selfwork.ru/api',
    SOCKET_URL: 'https://market.selfwork.ru',
    KEY_GOOGLE_MAPS: 'AIzaSyCbTn2Y95jaZoR6ZHCl-v2P9iYWtjOGhf4',
    KEY_YANDEX_MAPS: 'b1727873-8fe6-40ef-9181-c78a93555163',
    APPFLOW_ID: 'c01261c7',
    APPFLOW_CHANNEL: 'Production',
    carrotAppId: '50018',
    carrotApiKey: '50018-d2ddefe1a0b8107e1c3e99925a',
    carrotAuthKey: 'userauthkey-50018-dc46cbbe2dea628d1f192559ffe48f66e35d4da8513a4bc99b975160bfee3b',
    ycaptchaClientKey: 'uUoYHu1hrw1PWknDzyqeTQ6dFcvXAUPOlINNNdG2',
};
