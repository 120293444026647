import { Component, Input, OnInit } from '@angular/core';
import { IAdItem } from '../../../core/ad/interface/ad-items.interface';
import { NavController } from '@ionic/angular';
import { AdTypeEnum } from '../../../core/ad/enums/ad-type.enum';

@Component({
    selector: 'app-ad-item',
    templateUrl: './ad-item.component.html',
    styleUrls: ['./ad-item.component.scss'],
})
export class AdItemComponent implements OnInit {
    @Input() status = true;

    @Input() ad: IAdItem;

    adTypeEnum = AdTypeEnum;

    @Input() navigate = true;

    constructor(private navCtrl: NavController) {
    }

    ngOnInit() {
    }

    read() {
        return !!localStorage.getItem(`ad_read_${this.ad._id}`);
    }

    goTo(id) {
        if (this.navigate) {
            this.navCtrl.navigateForward(['/ad-detail', id]);
        }
    }
}
