import { Injectable } from '@angular/core';
import { AnimationController } from '@ionic/angular';
import { Animation } from '@ionic/core';
import { BehaviorSubject } from 'rxjs';
import {Keyboard} from "@capacitor/keyboard";
import {PlatformService} from "../services/platform.service";

@Injectable({
    providedIn: 'root',
})
export class KeyboardFixService {
    content;
    keyboardH = 0;

    isOpen = false;

    isChat = false;

    ionSafeAreaBottom = 0;

    private keyboardEventHeight: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(private readonly animationController: AnimationController, private platformService: PlatformService) {

    }

    subToKeyboardEvent$() {
        return this.keyboardEventHeight.asObservable();
    }

    showAnimation(content, fromTranslateY, toTranslateY, duration = 210, easing = 'ease-out'): Animation {
        return this.animationController
            .create()
            .addElement(content)
            .duration(duration)
            .easing(easing)
            .fromTo('transform', `translateY(${fromTranslateY}px)`, `translateY(${toTranslateY}px)`);
    }

    closeAnimation(content, translateY): Animation {
        return this.showAnimation(content, 0, translateY, 130, 'ease-in').direction('reverse');
    }

    hide() {
      if (this.platformService.isCordova()) {
        Keyboard.hide();
      }
    }

    initEvent() {
        window.addEventListener('keyboardWillHide', () => {
            this.keyboardHidden();
        });

        window.addEventListener('keyboardWillShow', (event: any) => {
            this.ionSafeAreaBottom = parseInt(
                getComputedStyle(document.documentElement).getPropertyValue('--ion-safe-area-bottom'),
                10,
            );
            const keyboardHeight = event.keyboardHeight;
            this.keyboardShown({ keyboardHeight: Number(keyboardHeight) - this.ionSafeAreaBottom });
        });

        window.addEventListener('keydown', (event) => {
            if (event.code === 'BracketLeft' && (event.ctrlKey || event.metaKey)) {
                this.keyboardShown({ keyboardHeight: 200 });
            }

            if (event.code === 'BracketRight' && (event.ctrlKey || event.metaKey)) {
                this.keyboardHidden();
            }
        });
    }

    init() {
        this.initEvent();
    }

    isChatInput(element) {
        return element.closest('.chat-container');
    }

    getScrollView(element) {
    }

    getContent(element, selector) {
        return element.closest(selector);
    }

    async keyboardShown({ keyboardHeight }) {
        this.keyboardEventHeight.next(keyboardHeight);
        const el = document.querySelector(':focus');
        this.keyboardH = keyboardHeight;
        if (!el) {
            return;
        }
        if (this.isChatInput(el)) {
            this.content = this.getContent(el, '.chat-container');
            this.isChat = true;
            this.showAnimation(this.content, -this.keyboardH, -keyboardHeight).play();
        } else {
            this.content = this.getContent(el, 'ion-content');

            if (!this.content) {
                return;
            }
            this.isChat = false;
            this.content.style.setProperty('--keyboard-offset', ''.concat(keyboardHeight, 'px'));
            //el.scrollIntoView({block: 'center', inline: 'nearest'});
        }
        //this.keyboardH = keyboardHeight;
    }

    async keyboardHidden() {
        if (this.keyboardH) {
            this.keyboardEventHeight.next(0);
            if (this.isChat) {
                this.closeAnimation(this.content, -this.keyboardH).play();
            } else {
                if (this.content) {
                  this.content.style.setProperty('--keyboard-offset', ''.concat('0', 'px'));
                }
            }
            this.isOpen = false;
            this.keyboardH = 0;
        }
    }
}
