import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppCacheService } from './cache.service';
import { ApiService } from '../api/api.service';
import { ICategory } from '../category/interface/category';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    private categoryTtl = 60 * 60 * 24;
    private CAT_GROUP_KEY = 'CATEGORY';

    categoryUrl = `${environment.SOCKET_URL}/assets/icons/category`;

    constructor(private cacheService: AppCacheService, private apiService: ApiService) {}

    async getCategoryIconById(id: string) {
        if (id) {
            const categoryName = (await this.getCategoryById(id)).property!.icon;
            return `${this.categoryUrl}/${categoryName}.svg`;
        } else {
            return '';
        }
    }

    getCategoryIconByName(name: string) {
        return `${this.categoryUrl}/${name}.svg`;
    }

    getCategories() {
        return this.cacheService.loadFromObservable<any>(
            'category',
            this.loadCategories(),
            this.CAT_GROUP_KEY,
            this.categoryTtl,
        );
    }

    groupSubCategories(categories) {
        return categories.map(async (category: any) => {
            category.subCat = await this.getCategoriesByParentId(category._id).pipe(take(1)).toPromise();
            category.subCat.map((sub: any) => {
                sub.isSet = false;
                return sub;
            });
            return category;
        });
    }

    getCategoryById(id: string): Promise<ICategory> {
        return this.getCategories()
            .pipe(map((categories) => categories.find((category: any) => category._id == id)))
            .pipe(take(1))
            .toPromise();
    }

    getCategoryById$(id: string): Observable<ICategory> {
        return this.getCategories().pipe(map((categories) => categories.find((category: any) => category._id == id)));
    }

    getCategoriesByParentId(id: number): Observable<ICategory[]> {
        return this.getCategories().pipe(
            map((categories) => {
                return categories.filter((category: any) => {
                    return category.parentID === id;
                });
            }),
        );
    }

    async getDisplayCategoryName(category: any) {
        const categoryName = category[0] ? (await this.getCategoryById(category[0]))?.name : '';
        const subCategoryName = category[1] ? (await this.getCategoryById(category[1]))?.name : '';
        return `${categoryName}${category.length === 2 ? ' / ' : ''}${subCategoryName}`;
    }

    loadCategories(): Observable<ICategory[]> {
        return this.apiService.getCategories();
    }
}
