import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-app-popup-review-modal',
    templateUrl: './app-popup-review-modal.component.html',
    styleUrls: ['./app-popup-review-modal.component.scss'],
})
export class AppPopupReviewModalComponent implements OnInit {

    _editText = '';

    constructor(private modalController: ModalController) {
    }

    ngOnInit() {
    }

    save() {
        return this.modalController.dismiss(this._editText);
    }

}
