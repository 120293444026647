import {Component, OnInit} from '@angular/core';
import {AuthNavigationService} from '../../auth-navigation.service';
import {SmsService} from '../../../../core/sms/sms.service';
import {catchError} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {ToastService} from '../../../../core/services/toast.service';
import {ApiService} from '../../../../core/api/api.service';
import {LegalInformationService} from '../../../../core/services/legal-information.service';
import {CaptchaService} from "../../../../core/services/captcha.service";

@Component({
  selector: 'app-sign-up-page',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpPageComponent implements OnInit {

  phone = '';
  name = '';
  loading = false;

  constructor(
    private authNavigationService: AuthNavigationService,
    private smsService: SmsService,
    private toastService: ToastService,
    private apiService: ApiService,
    private legalInformationService: LegalInformationService,
    private captchaService: CaptchaService
  ) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  async sendPhone() {
    this.loading = true;
    try {
      if (this.captchaService.getToken()) {
        this.captchaService.reset();
      }
      const {id, token} = await this.captchaService.execute({id: 'signUp'});
      if (this.authNavigationService.getSlide() !== 1 || id !== 'signUp') {
        this.loading = false;
        return;
      }
      const phone = `+7${this.phone}`;
      const exists = await this.apiService.getUserExists(this.phone);
      if (exists) {
        this.loading = false;
        return this.toastService.presentToast('Пользователь уже зарегистрирован', 3000, 'danger');
      }
      this.smsService.getSmsWithCaptchaToken(phone, token).pipe(catchError((err) => {
        this.toastService.presentToast(err);
        this.loading = false;
        return EMPTY;
      })).subscribe((token) => {
        this.authNavigationService.setType(false);
        this.authNavigationService.smsInfoSignUp.phone = phone;
        this.authNavigationService.smsInfoSignUp.smsConfirm.token = token;
        this.authNavigationService.smsInfoSignUp.name = this.name;
        this.authNavigationService.setSlide(2);
        setTimeout(() => {
          this.loading = false
        }, 200);
      });
    } catch (e) {

    } finally {
      setTimeout(() => {
        this.loading = false
      }, 500);
    }
  }

  toSignIn() {
    this.authNavigationService.setSlide(0);
  }

  back() {
    return () => {
      this.authNavigationService.back();
    };
  }

  vaild() {
    return (this.phone.length === 10 && this.name.length > 1);
  }

  goLegal() {
    return this.legalInformationService.goLegal();
  }

  goPrivacy() {
    return this.legalInformationService.goPrivacy();
  }

}
