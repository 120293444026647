import { Component, Input, OnInit } from '@angular/core';
import { getRatingString } from '../../../core/utils/helpers';

@Component({
    selector: 'app-ui-rating-number',
    templateUrl: './ui-rating-number.component.html',
    styleUrls: ['./ui-rating-number.component.scss'],
})
export class UiRatingNumberComponent {
    @Input() sum = 0;
    @Input() count = 0;
    @Input() size?: 'small' | 'normal';

    constructor() {}

    rating(sum: number, count: number) {
        return getRatingString(sum, count);
    }
}
