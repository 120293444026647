import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-input-phone',
    templateUrl: './input-phone.component.html',
    styleUrls: ['./input-phone.component.scss'],
})
export class InputPhoneComponent implements OnInit {
    _value = '';
    prefix = false;

    @Input() placeholder = '';
    @Input() set value(val: any) {
        if (!this._value && val) {
            this._value = val.slice(2);
            this.prefix = true;
            const event = { target: { value: this._value } };
            this.inputNumber(event);
        }
        if (this._value[0] === '7' || this._value[0] === '8'){
            this._value = val;
            const event = { target: { value: this._value } };
            this.inputNumber(event);
        }
    }
    @Input() disabled = false;

    @Output() inputEv: EventEmitter<any> = new EventEmitter<any>(false);
    @Output() clickEnter: EventEmitter<any> = new EventEmitter<any>(false);

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit() {}

    inputNumber(event: any) {
        let phone = event.target.value.replace(new RegExp('\\D', 'g'), '');
        phone = phone[0] === '7' || phone[0] === '8' ? phone.slice(1) : phone;

        if (event.inputType === 'insertFromPaste' && !event.target.value.length) {
            event.target.value = phone;
            this._value = phone;
        }

        this.inputEv.emit(phone);
        this.cdr.detectChanges();

    }

    clickEnterEv() {
        this.clickEnter.emit(true);
    }

    showPrefix(bool: boolean) {
        if (bool) {
            this.prefix = true;
            return;
        } else if (this._value.length) {
            this.prefix = true;
            return;
        } else {
            this.prefix = false;
        }
    }
}
