import { Injectable } from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { first } from 'rxjs/operators';
import { PlatformService } from './platform.service';
import {Filesystem} from "@capacitor/filesystem";

@Injectable({
    providedIn: 'root',
})
export class PreloadService {
    constructor(
        private platformService: PlatformService,
        private iconReg: SvgIconRegistryService,
    ) {
    }

    async init() {
        if (this.platformService.isCordova()) {
            const files = await Promise.all([
                this.getSvgAssets(),
                this.getSvgAssets('card-alert'),
                this.getSvgAssets('messenger'),
                this.getSvgAssets('map'),
                this.getSvgAssets('notifications'),
                this.getSvgAssets('tabs'),
            ]);
            const images = [].concat.apply([], files);
            await this.preloadImages(images);
        }
    }

    async getSvgAssets(dir?) {
        // const directory = `/assets/icons${dir ? '/' + dir : ''}`;
        // const result = await Filesystem.listDir(this.file.applicationDirectory, 'www' + directory);
        // const files = result.filter((file) => file.isFile);
        // return files.map((file) => {
        //     return `${directory}/${file.name}`;
        // });
      return [];
    }

    async preloadImages(imageSources: string[]) {
        return Promise.all(imageSources.map(async (i) => await this.imageLoaded(i)));
    }

    imageLoaded(src) {
        return this.iconReg.loadSvg(src).pipe(first()).toPromise();
    }

}
