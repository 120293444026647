<div class="offer-info-container" [routerLink]="this.link">
    <div class="top">
        <app-category-icon
            *ngIf="category; else notCategory"
            [category]="{ color: category.property.color, icon: getIconCategory(category.property.icon) }"
        ></app-category-icon>
        <ng-template #notCategory><img class="users_pic" src="/assets/pictures/offers/users_offer.png" /></ng-template>
        <app-svg-icon class="arrow" [name]="'right-arrow'" [stroke]="'var(--ion-color-accent)'"></app-svg-icon>
    </div>
    <div class="text_title body_circe font_bold">{{ title }}</div>
    <div class="text_description caption_circe">{{ text }}</div>
</div>
