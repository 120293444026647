import { Component, OnInit, Input } from '@angular/core';
import { getRatingString } from '../../../core/utils/helpers';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {
    @Input() sum = 0;
    @Input() count = 0;

    @Input() type: 'text' | 'text_reverse' | 'stars' = 'text';

    constructor() {}

    ngOnInit() {}

    rating(sum: number, count: number) {
        return getRatingString(sum, count);
    }
}
