<app-header [shadow]="true">
    <div slot="start" (click)="back()">
        <app-svg-icon [stroke]="'var(--ion-color-main-text)'" [height]="20" [name]="'close'"></app-svg-icon>
    </div>

    <div slot="center">
        <div class="title body_circe font_bold">Редактирование</div>
    </div>
    <div slot="end">
        <div
            (click)="save()"
            [ngClass]="{ disable: _editText?.trim().length < minLength || _editText?.trim().length > maxLength }"
            class="body_secondary_circe save"
        >
            Готово
        </div>
    </div>
</app-header>
<ion-content [scrollY]="false" [forceOverscroll]="false">
    <div class="padding-20">
        <textarea #textareaEl class="about-text-textarea body_circe" [(ngModel)]="_editText" [minRows]="1" autosize></textarea>
        <span class="bar"></span>
        <span class="under-title--error caption_rubik" *ngIf="_editText?.trim().length < minLength">Минимальное количество символов - {{minLength}}</span>
        <span class="under-title--error caption_rubik" *ngIf="_editText?.trim().length > maxLength">Максимальное количество символов - {{maxLength}}</span>
        <span class="under-title caption_rubik" *ngIf="_editText?.trim().length >= minLength && _editText?.trim().length <= maxLength">{{ _editText?.trim().length }} / {{ maxLength }}</span>
    </div>
</ion-content>
