import { NgModule } from '@angular/core';
import { AuthPage } from './auth.page';

import { SharedModule } from '../../shared/shared.module';

import { AuthNavigationService } from './auth-navigation.service';
import { AuthOpenModalService } from './auth-open-modal.service';

import { SignInPageComponent } from './widgets/sign-in/sign-in.component';
import { SignUpPageComponent } from './widgets/sign-up/sign-up.component';
import { SmsPageComponent } from './widgets/sms/sms.component';

const components = [];

const pages = [SignInPageComponent, SignUpPageComponent, SmsPageComponent];

@NgModule({
    imports: [SharedModule],
    providers: [AuthNavigationService, AuthOpenModalService],
    exports: [AuthPage, ...components, ...pages],
    declarations: [AuthPage, ...components, ...pages],
})
export class AuthModule {}
