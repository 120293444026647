import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
    @Input() class: string;
    @Input() background: string;
    @Input() outline = false;
    @Input() color: string;
    @Input() disabled = false;

    @Input() load = false;

    @Output() btnClick: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    constructor() {}

    ngOnInit() {
        this.background = this.background ? this.background : !this.outline ? 'accent' : 'transparent';
        this.color = this.color ? this.color : !this.outline ? 'white' : 'accent';
    }

    click() {
        this.btnClick.emit(true);
    }
}
