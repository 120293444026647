import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LegalInformationService {

    constructor() {

    }

    goLegal() {
        //Пользовательское соглашение
        window.location.href = 'https://xn--80aapgyievp4gwb.xn--p1ai/terms/marketplace/';
    }

    goPrivacy() {
        //Политика конфедециальности
        window.location.href = 'https://xn--80aapgyievp4gwb.xn--p1ai/terms/marketplace/';
    }


}
