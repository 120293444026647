export enum ImageSizeTypeEnum {
    // 75px
    s = 's',

    // 130px;
    m = 'm',

    // 604px;
    x = 'x',

    // 1080px
    z = 'z',

    // 2560px
    w = 'w',
}
