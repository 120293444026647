<div class="profile-item" [class.contact]="contact">
    <div class="profile-item__avatar-container" (click)="goToProfile(profile._id)">
        <app-ui-avatar [src]="profile.attachment[0]"></app-ui-avatar>
    </div>

    <div class="profile-item__info-container" (click)="goToProfile(profile._id)">
        <div class="profile-item__online-container">
            <app-ui-online-status [lastOnlineDate]="profile.stats.lastOnline"></app-ui-online-status>
        </div>
        <div class="profile-item__name body_circe font_bold">
            {{ profile.name + ' ' + (profile.lastName ? profile.lastName : '') }}
        </div>
        <div class="profile-item__stat-container">
            <app-ui-sw-item-stat [profileStat]="profile.stats"></app-ui-sw-item-stat>
        </div>
    </div>

    <div *ngIf="arrow" class="profile-item__arrow-container" (click)="goToProfile(profile._id)">
        <app-svg-icon [name]="'right-arrow'" [stroke]="'var(--ion-color-additional-text)'"></app-svg-icon>
    </div>

    <div *ngIf="contact" class="profile-item__arrow-container">
        <app-svg-icon
            (click)="write(profile._id)"
            [name]="'dialogs-outline'"
            [fill]="'var(--ion-color-accent)'"
        ></app-svg-icon>
        <app-svg-icon
            (click)="call(profile.phone)"
            class="call"
            [name]="'call-outline'"
            [stroke]="'var(--ion-color-accent)'"
        ></app-svg-icon>
    </div>
</div>
