import { Component, Input, OnInit } from '@angular/core';
import { CategoryService } from '../../../core/services/category.service';
import { ICategory } from '../../../core/category/interface/category';
import { decOfNum } from '../../../core/utils/helpers';
import { AnalyticsService } from '../../../core/analytics/analytics.service';
import { AdTypeEnum } from '../../../core/ad/enums/ad-type.enum';

@Component({
    selector: 'app-offer-info-widget',
    templateUrl: './offer-info-widget.component.html',
    styleUrls: ['./offer-info-widget.component.scss'],
})
export class OfferInfoWidgetComponent implements OnInit {

    @Input() adId!: string;
    @Input() type: AdTypeEnum = AdTypeEnum.task;

    offerCount = 0;

    @Input() set _offerCount(value: any) {
        this.offerCount = value;
        this.setViewText();
    }

    @Input() customer = false;

    @Input() category: ICategory | null = null;

    title?: string;
    text?: string;
    link?: Array<string>;

    constructor(private categoryService: CategoryService, private readonly analyticsService: AnalyticsService) {
    }

    ngOnInit() {
        this.setViewText();
    }

    getIconCategory(name: string) {
        return this.categoryService.getCategoryIconByName(name);
    }

    decOffer(num: number) {
        return decOfNum(num, ['предложение', 'предложения', 'предложений']);
    }

    decVacancyOffer(num: number) {
        return decOfNum(num, ['отклик', 'отклика', 'откликов']);
    }

    setViewText() {
        if (!this.type || this.type === AdTypeEnum.task) {
            switch (this.customer) {
                case true:
                    if (this.offerCount > 0) {
                        this.title = `У задания ${this.offerCount} ${this.decOffer(this.offerCount)}`;
                        this.text = `Выберети одно из предложений или дождитесь пока больше исполнителей предложат свои услуги`;
                        this.link = ['/ad-offers', this.adId];
                    } else {
                        this.title = `Отправьте несколько предложений`;
                        this.text = `Так быстрее найдется тот, кто готов вам помочь. Просто выберите исполнителя и предложите заказ.`;
                        this.link = ['/ad-detail', this.adId, 'selfworkers'];
                    }
                    break;
                case false:
                    this.title = `Предложить услуги`;
                    this.text = `У задания ${this.offerCount} ${this.decOffer(
                        this.offerCount,
                    )}. Чем меньше предложений в задании, тем выше шансы стать исполнителем`;
                    this.link = ['/offer-add', this.adId];
                    break;
            }
        } else if (this.type === AdTypeEnum.vacancy) {
            switch (this.customer) {
                case true:
                    if (this.offerCount > 0) {
                        this.title = `У вакаснии ${this.offerCount} ${this.decVacancyOffer(this.offerCount)}`;
                        this.text = `Выберите подходящих самозанятых и отправьте им заявку на подключение для сотрудничества.`;
                        this.link = ['/vacancy-offers', this.adId];
                    } else {
                        this.title = `Предложите вакансию сами`;
                        this.text = `Так быстрее найдется подходящий работник. Найдите самозанятого и направьте ему эту вакансию.`;
                        this.link = ['/ad-detail', this.adId, 'selfworkers'];
                    }
                    break;
                case false:
                    if (this.offerCount > 0) {
                        this.title = `Откликнуться на вакансию`;
                        this.text = `У вакансии уже ${this.offerCount} ${this.decVacancyOffer(
                            this.offerCount,
                        )}. Чем их меньше, тем ваши шансы выше.`;
                        this.link = ['/offer-vacancy-add', this.adId];
                        break;
                    } else {
                        this.title = `Откликнуться на вакансию`;
                        this.text = `У вакансии еще нет откликов. Чем их меньше, тем ваши шансы выше.`;
                        this.link = ['/offer-vacancy-add', this.adId];
                        break;
                    }
            }
        }
    }
}
