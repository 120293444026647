import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISignInSmsDto } from './dto/sign-in-sms.dto';
import { ISignUpSmsDto } from './dto/sign-up-sms.dto';
import { ApiService } from '../api/api.service';
import { first, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { NavController } from '@ionic/angular';
import jwt_decode from 'jwt-decode';
import { ToastService } from '../services/toast.service';
import { PushService } from '../push/push.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { AnalyticsEventNameEnum } from '../analytics/enums/analytics-event-name.enum';
import { AnalyticsEventCategoryEnum } from '../analytics/enums/analytics-event-category.enum';
import { AlertService } from '../services/alert.service';
import { LoadingService } from '../services/loading.service';
import { PlatformService } from '../services/platform.service';
import { CarrotQuestService } from '../services/carrot-quest.service';
import {SplashScreen} from "@capacitor/splash-screen";

const ACCESS_TOKEN_KEY = 'accessToken';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    logoutProgress = false;

    constructor(
        private readonly apiService: ApiService,
        private readonly pushService: PushService,
        private navCtrl: NavController,
        private toastService: ToastService,
        private carrotQuestService: CarrotQuestService,
        private readonly analyticsService: AnalyticsService,
        private alertService: AlertService,
        private loadingService: LoadingService,
        private platformService: PlatformService,
    ) {
    }

    authState: BehaviorSubject<string> = new BehaviorSubject(localStorage.getItem(ACCESS_TOKEN_KEY));

    public isAuthorized(): Observable<boolean> {
        return this.getAccessToken().pipe(
            first(),
            map((token) => !!token),
        );
    }

    public watchAuthState$(): Observable<{ token; user } | false> {
        return this.getAccessToken().pipe(
            map((auth) => {
                return auth
                    ? {
                        user: jwt_decode(auth)._id,
                        token: auth,
                    }
                    : false;
            }),
        );
    }

    public getAccessToken(): Observable<string> {
        return this.authState.asObservable();
    }

    getAccountID() {
        return this.authState.getValue() ? jwt_decode(this.authState.getValue())._id : false;
    }

    public login(singInDto: ISignInSmsDto, fromPageName?) {
        return this.apiService.signIn(singInDto).then((result) => {
            if (result.token) {
                this.analyticsService.sendEvent({
                    action: AnalyticsEventNameEnum.LOGIN,
                    category: AnalyticsEventCategoryEnum.AUTH,
                    page: fromPageName,
                    value: {
                        method: 'phone',
                    },
                });
                this.setToken(result.token);
                this.pushService.registerDevice();
            } else {
                this.toastService.presentToast(result.message);
            }
            return result;
        });
    }

    public register(singUpDto: ISignUpSmsDto, fromPageName?) {
        return this.apiService.signUp(singUpDto).then((result) => {
            if (result.token) {
                this.analyticsService.sendEvent({
                    action: AnalyticsEventNameEnum.SIGN_UP,
                    category: AnalyticsEventCategoryEnum.AUTH,
                    page: fromPageName,
                    value: {
                        method: 'phone',
                    },
                });
                this.setToken(result.token);
                this.pushService.registerDevice();
            } else {
                this.toastService.presentToast(result.message);
            }
            return result;
        });
    }

    async logout() {
        if (this.logoutProgress === false) {
            this.logoutProgress = true;
            if (this.platformService.isCordova()) {
                try {
                    await this.loadingService.presentLoading('Выход...');
                    await SplashScreen.show({autoHide: false});
                    await this.pushService.unregisterDevice();
                    await this.carrotQuestService.logout();
                    localStorage.clear();
                    localStorage.setItem('welcome', 'true');
                    window.location.href = '/system';
                } catch (e) {
                    await this.loadingService.dismissLoading();
                    await SplashScreen.hide();
                    this.alertService.showAlert(`При выходе произошла ошибка. Попробуйте переустановить приложение или попробуйте позже`, ['Ок'], 'Ошибка', false).then(() => {
                        this.logoutProgress = false;
                    });
                }
            } else {
                localStorage.clear();
                localStorage.setItem('welcome', 'true');
                this.carrotQuestService.logout();
                window.location.href = '/system/profile';
            }
        }
    }

    // Какие запросы нужно пропустить
    public isAcceptRequest(req: HttpRequest<any>): boolean {
        return req.url.startsWith(environment.API_URL);
    }

    private setToken(token) {
        localStorage.setItem(ACCESS_TOKEN_KEY, token);
        this.authState.next(token);
    }
}
