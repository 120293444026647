import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TOffer } from '../../../core/offer/types/offer.type';

@Component({
    selector: 'app-offer-item',
    templateUrl: './offer-item.component.html',
    styleUrls: ['./offer-item.component.scss'],
})
export class OfferItemComponent implements AfterViewInit {
    @Input() offer!: TOffer;
    @Input() selectEnable = true;

    @ViewChild('responseReadMore') responseReadMore!: ElementRef<HTMLElement>;

    showReadMore = false;

    toggleReadMore = false;

    @Output() selectOffer: EventEmitter<string> = new EventEmitter<string>();

    constructor() {}

    checkTextHeight() {
        setTimeout(() => {
            this.showReadMore = this.responseReadMore.nativeElement.scrollHeight > 80;
        }, 100);
    }

    async readMoreToggle() {
        this.toggleReadMore = !this.toggleReadMore;
        if (this.toggleReadMore) {
            this.responseReadMore.nativeElement.style.maxHeight =
                this.responseReadMore.nativeElement.scrollHeight + 'px';
        } else {
            this.responseReadMore.nativeElement.style.maxHeight = '96px';
        }
    }

    ngAfterViewInit() {
        this.checkTextHeight();
    }

    select() {
        this.selectOffer.emit(this.offer._id);
    }
}
