import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'reviewText',
})
export class ReviewTextPipe implements PipeTransform {
    transform(value: number): string {
        switch (value) {
            case 1:
                return 'Ужасно';
            case 2:
                return 'Плохо';
            case 3:
                return 'Нормально';
            case 4:
                return 'Хорошо';
            case 5:
                return 'Отлично';
            default:
                return '';
        }
    }
}
