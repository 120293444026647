<div class="offer-item-container">
    <div class="field_item">
        <app-ui-review-profile-item
            [arrow]="false"
            [contact]="true"
            [profile]="offer.profile"
        ></app-ui-review-profile-item>
    </div>

    <div class="field_item">
        <div class="title caption_circe">Предложение</div>
        <div class="value body_circe">{{ offer.price.toLocaleString('ru') }} ₽</div>
    </div>

    <div class="field_item">
        <div class="title caption_circe">Отклик</div>
        <div
            class="value response body_circe"
            #responseReadMore
            [innerHTML]="offer.description | linkify | sanitizeHtml"
        ></div>
        <div *ngIf="showReadMore" class="additional_container">
            <div class="shadow" [class.hideShadow]="toggleReadMore"></div>
            <div class="readmore">
                <div class="caption_circe chip" (click)="readMoreToggle()">
                    {{ !toggleReadMore ? 'Читать полностью' : 'Свернуть' }}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="field_item" *ngIf="selectEnable">
    <app-button [class]="'primary'" (btnClick)="select()">Выбрать исполнителем</app-button>
</div>

<div class="divider"></div>
