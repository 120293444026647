import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { IAddress } from '../../../core/geo/interfaces/address.interface';
import { Platform } from '@ionic/angular';
import { ICategory } from '../../../core/category/interface/category';

@Component({
    selector: 'app-map-preview-widget',
    templateUrl: './map-preview-widget.component.html',
    styleUrls: ['./map-preview-widget.component.scss'],
})
export class MapPreviewWidgetComponent {
    url = '';
    addressLine = '';
    private baseHeight = 180;
    private baseWidth = this.platform.width() - 40;

    private addressFull: IAddress | null = null;

    @Input() category!: any;

    constructor(private platform: Platform) {}
    @Input() set address(addressObj: IAddress) {
        this.makeStaticMap(addressObj);
    }

    makeStaticMap(address: IAddress) {
        const centerLat = address.location.coordinates[1];
        const centerLon = address.location.coordinates[0];

        const mapSize = `${this.baseWidth}x${this.baseHeight}`;

        const zoom = 14;
        const scale = 2;

        const key = environment.KEY_GOOGLE_MAPS;

        const format =
            '&style=feature:poi.business%7Cvisibility:off&style=feature:poi.park%7Celement:labels.text%7Cvisibility:off';

        this.url =
            'https://maps.googleapis.com/maps/api/staticmap?' +
            `center=${centerLat},${centerLon}` +
            `&zoom=${zoom}` +
            `&scale=${scale}` +
            `&size=${mapSize}` +
            `&key=${key}` +
            format;

        this.addressLine = address.city + (address.streetAddress ? `, ${address.streetAddress}` : '');
        this.addressFull = address;
    }

    open() {
      const centerLat = this.addressFull.location.coordinates[1];
      const centerLon = this.addressFull.location.coordinates[0];
      window.open(`https://www.google.com/maps/search/?api=1&query=${centerLat},${centerLon}`);
    }
}
