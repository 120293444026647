import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-input-card',
    templateUrl: './input-card.component.html',
    styleUrls: ['./input-card.component.scss'],
})
export class InputCardComponent implements OnInit {
    @Input() placeholder = '';
    @Input() value;
    @Input() disabled = false;

    @Output() inputEv: EventEmitter<any> = new EventEmitter<any>(false);
    @Output() clickEnter: EventEmitter<any> = new EventEmitter<any>(false);

    constructor() {}

    ngOnInit() {}

    inputCode(event) {
        if (event) {
            const card = event.replace(new RegExp('\\D', 'g'), '');
            this.inputEv.emit(card);
        }
    }

    clickEnterEv() {
        this.clickEnter.emit(true);
    }
}
