<app-header [shadow]="true">
    <div slot="start">
        <app-back-button [present]="'modal'"></app-back-button>
    </div>

    <div slot="center">
        <div class="app-title body_circe font_bold">Причина</div>
    </div>
</app-header>

<ion-content>
    <h5 class="title">Почему задание не выполнено?</h5>

    <div class="reasons bottom-safe-area">
        <app-radio
            *ngFor="let reason of reasons"
            [text]="reason"
            [name]="'reason'"
            [value]="reason"
            (btnClick)="reasonSelected = $event"
            [checked]="reasonSelected == reason"
        ></app-radio>
    </div>

</ion-content>

<app-c-footer>
    <app-c-container class="app-padding">
        <app-button [disabled]="!reasonSelected" (btnClick)="goTo()" [class]="'primary'">Продолжить</app-button>
    </app-c-container>
</app-c-footer>



