import { Component, Input, OnInit } from '@angular/core';
import { ISelfworkerItem } from '../../../core/selfworkers/interfaces/selfworkers.interface';

@Component({
    selector: 'app-self-worker-item',
    templateUrl: './self-worker-item.component.html',
    styleUrls: ['./self-worker-item.component.scss'],
})
export class SelfWorkerItemComponent implements OnInit {
    @Input() selfworker!: ISelfworkerItem;

    constructor() {}

    ngOnInit() {}
}
