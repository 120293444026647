import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';

export type TSmsToken = string;

@Injectable({
    providedIn: 'root',
})
export class StatsService {
    setOnlineSubject = new Subject();
    setOnlineHandler = this.setOnlineSubject
        .asObservable()
        .pipe(debounceTime(10000))
        .subscribe(() => {
            this.apiService.setOnline();
        });

    constructor(private readonly apiService: ApiService) {}

    setOnline() {
        this.setOnlineSubject.next();
    }

    setOnlineFirst() {
        return this.apiService.setOnline();
    }
}
