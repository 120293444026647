import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-category-icon',
    templateUrl: './category-icon.component.html',
    styleUrls: ['./category-icon.component.scss'],
})
export class CategoryIconComponent implements OnInit {
    @Input() category: {
        color;
        icon;
        iconName?;
    };

    constructor() {}

    ngOnInit() {}
}
