<input data-cy="input-code"
    class="body_rubik font_regular"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [ngModel]="value"
    inputmode="numeric"
    mask="0000"
    type="text"
    (ngModelChange)="inputCode($event)"
    (keyup.enter)="clickEnterEv()"
/>
