import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-c-container',
    templateUrl: './c-container.component.html',
    styleUrls: ['./c-container.component.scss'],
})
export class CContainerComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
