import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

@Component({
    selector: 'app-ui-range',
    templateUrl: './ui-range.component.html',
    styleUrls: ['./ui-range.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiRangeComponent implements OnInit {

    _rangeSteps?: number[];
    _value?: number;
    range = 0;

    @Input() set rangeSteps(val: any) {
        if (val) {
            this._rangeSteps = val;
            this.check();
        }
    }

    @Input() set value(val: any) {
        if (val) {
            this._value = val;
            this.check();
        }
    }

    @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
    private changeRange = new Subject<any>();

    constructor() {
    }

    ngOnInit() {
      this.changeRange
        .pipe(
          debounceTime(150),
          distinctUntilChanged(),
        )
        .subscribe(data => this.valueChange.emit(data));
    }

    check() {
        if (this._rangeSteps && this._value) {
            this.range = this._rangeSteps.findIndex((e) => e === this._value);
            setTimeout(() => this.watchRange(), 0);
        }
    }

    watchRange() {
        this.changeRange.next(this._rangeSteps![this.range]);
    }

}
