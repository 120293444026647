<div
    class="rating-star"
    [class.rating-star--small]="size === 'small'"
    [class.rating-star--normal]="size === 'normal'"
    [class.rating-star--large]="size === 'large'"
>
    <app-ui-icon
        icon="star"
        [fillColor]="active ? 'var(--ion-color-stars)' : 'var(--ion-color-border-active)'"
    ></app-ui-icon>
</div>
