import { Injectable } from '@angular/core';
import { Router, ResolveEnd } from '@angular/router';
import { CustomModalController } from '../extends/custom-modal-controller.service';
import { PlatformService } from './platform.service';
import {StatusBar} from "@capacitor/status-bar";
import {Style} from "@capacitor/status-bar/dist/esm/definitions";

@Injectable({
    providedIn: 'root',
})
export class StatusBarService {

    private currentUrl = '';
    private whiteStatusBarTextUrl = [
        '/system/dialogs/auth',
        '/system/profile',
        '/user/',
    ];

    private modalsStack = [];

    constructor(
        private platformService: PlatformService,
        private customModalController: CustomModalController,
        private router: Router,
    ) {
    }

    init() {
        if (this.platformService.isAndroid()){
          StatusBar.setOverlaysWebView({overlay: true});
        }
        if (this.platformService.isCordova()) {
            this.firstCheck();
            this.routeStatusBar();
        }
    }

    firstCheck() {
        setTimeout(() => {
            this.currentUrl = this.router.url;
            this.checkCurrentStatusBar();
        }, 150);
    }

    async setWhiteStatusBar() {
      StatusBar.setStyle({style: Style.Light});
      if (await this.platformService.isOlderAndroid(8)) {
         StatusBar.setBackgroundColor({color: '#FFFFFF'})
      }
    }

    async setBlackStatusBar() {
      StatusBar.setStyle({style: Style.Dark});
      if (await this.platformService.isOlderAndroid(8)) {
         StatusBar.setBackgroundColor({color: '#1D2E69'})
      }
    }

    private routeStatusBar() {
        this.router.events.subscribe(event => {
            if (event instanceof ResolveEnd) {
                this.currentUrl = event.url;
                this.checkCurrentStatusBar();
            }
        });

        this.customModalController.isModalShow.subscribe((modal: any) => {
            if (modal.show && modal.opts && modal.opts.whiteTextStatusBar) {
                this.modalsStack.push({ show: true, whiteTextStatusBar: true });
            } else if (modal.show) {
                this.modalsStack.push({ show: true, whiteTextStatusBar: false });
            } else {
                this.modalsStack.pop();
            }
            if (this.modalsStack.length) {
                if (this.modalsStack[this.modalsStack.length - 1].whiteTextStatusBar) {
                    this.setBlackStatusBar();
                } else {
                    this.setWhiteStatusBar();
                }
            } else {
                this.checkCurrentStatusBar();
            }

        });
    }

    checkCurrentStatusBar() {
        if (this.whiteStatusBarTextUrl.some((url) => {
            return this.currentUrl.indexOf(url) + 1;
        })) {
            this.setBlackStatusBar();
        } else {
            this.setWhiteStatusBar();
        }
    }

}
