import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-input-textarea',
    templateUrl: './input-textarea.component.html',
    styleUrls: ['./input-textarea.component.scss'],
})
export class InputTextareaComponent implements OnInit {
    @Input() placeholder = '';
    @Input() value = '';
    @Input() readonly = false;
    @Input() disabled = false;
    @Input() rows!: number;

    @Output() inputEv: EventEmitter<any> = new EventEmitter<any>(false);

    constructor() {}

    ngOnInit() {}

    inputTextarea(event: any) {
        const input = event.target;
        this.inputEv.emit(input.value);
    }
}
