import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { AppCacheService } from '../services/cache.service';
import { ICity } from './types/city.interface';
import { findNearest } from 'geolib';
import { PositionService } from '../geo/position.service';

@Injectable({
    providedIn: 'root',
})
export class CitiesService {
    private categoryTtl = 60 * 60 * 24;
    private CITIES_GROUP_KEY = 'CITIES';

    constructor(private cacheService: AppCacheService, private apiService: ApiService, private positionService: PositionService) {
    }

    getCities(): Observable<ICity[]> {
        return this.cacheService.loadFromObservable<any>(
            'cities',
            this.loadCities(),
            this.CITIES_GROUP_KEY,
            this.categoryTtl,
        );
    }

    loadCities(): Observable<ICity[]> {
        return this.apiService.getCities();
    }

    getCityByFiasId(fiasId: string): Promise<ICity> {
        return this.getCities()
            .pipe(map((cities: ICity[]) => cities.find((city) => city.fias_id === fiasId)))
            .pipe(take(1))
            .toPromise();
    }

    async getCityNameByFiasId(fiasId: string) {
        const res = await this.getCityByFiasId(fiasId);
        return res.city;
    }

    async findNear() {
        const position = await this.positionService.getApproximatePosition();
        const cities = (await this.getCities().toPromise()).map((city) => {
            return { latitude: city.geo_lat, longitude: city.geo_lon, city: city.city, fias_id: city.fias_id };
        });
        return findNearest({ latitude: position.lat, longitude: position.lng }, cities);
    }

}
