import { IImage } from '../images/interfaces/image.interface';
import { ImageSizeTypeEnum } from '../images/enums/image-size-type.enum';

export const clamp = (min: number, n: number, max: number) => {
    return Math.max(min, Math.min(n, max));
};

export const getImageSrcBySize = (image: IImage, size: ImageSizeTypeEnum): string => {
    return image.sizes.find((i) => i.type === size).url;
};

export const decOfNum = (num: number, words: string[]): string => {
    return words[num % 100 > 4 && num % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][num % 10 < 5 ? num % 10 : 5]];
};

export const pause = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const getRatingString = (sum, count) => {
    if (isNaN(sum) || isNaN(count)) {
        return '0.0';
    } else {
        const rating = (sum / count).toFixed(1);
        return !isNaN(Number(rating)) ? rating : '0.0';
    }
};


export const loadScript = (src) => {
  return new Promise(function(resolve, reject) {
    const s: any = document.createElement('script');
    let r = false;
    s.type = 'text/javascript';
    s.src = src;
    s.async = true;
    s.onerror = function(err) {
      reject(err);
    };
    s.onload = s.onreadystatechange = function() {
      if (!r && (!this.readyState || this.readyState == 'complete')) {
        r = true;
        resolve(src);
      }
    };
    document.head.appendChild(s);
  });
};
