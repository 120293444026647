import { Injectable } from '@angular/core';
import {
    ActionSheetController,
    AlertController,
    LoadingController,
    ModalController,
    NavController,
    PopoverController,
} from '@ionic/angular';
import { PlatformService } from '../services/platform.service';
import { LocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { CarrotQuestService } from '../services/carrot-quest.service';
import {CaptchaService} from "../services/captcha.service";

@Injectable({
    providedIn: 'root',
})
export class BackButtonAndroidHelper {

    private primaryBackButtonFunction: [() => void, boolean] = null;

    private backButtonFunction: Array<() => void> = [];

    backButtonEnabled = true;

    constructor(
        private platformService: PlatformService,
        private modalController: ModalController,
        private loadingController: LoadingController,
        private popoverController: PopoverController,
        private actionSheetController: ActionSheetController,
        private alertController: AlertController,
        private navCtrl: NavController,
        private locationStrategy: LocationStrategy,
        private router: Router,
        private carrotQuestService: CarrotQuestService,
        private captchaService: CaptchaService,
    ) {
    }

    setBackButtonFunction(func) {
        this.backButtonFunction.push(func);
    }

    unsetBackButtonFunction() {
        this.backButtonFunction.pop();
    }

    setPrimaryBackButtonFunction(func, prevent = false) {
        this.primaryBackButtonFunction = [func, prevent];
    }

    unsetPrimaryBackButtonFunction() {
        this.primaryBackButtonFunction = null;
    }

    init() {
        if (this.platformService.isCordova()) {
            this.disableDefaultBackBtn();
            this.platformService.getPlatform().backButton.subscribeWithPriority(9999, async () => {
            });
        } else {
          if (this.platformService.isIos()) {
            this.blockSwipeIos();
          }
            this.locationStrategy.onPopState(() => {
                setTimeout(() => {
                    this.backSubInit();
                }, 50);
            });
        }
    }

  blockSwipeIos() {
    const element = document.querySelector('ion-app');
    const swipe = 30;

    element.addEventListener(
      'touchstart',
      (e: any) => {
        const pageX = e.pageX ? e.pageX : e.touches[0].pageX;
        if (pageX > swipe && pageX < window.innerWidth - swipe) {
          return;
        } else {
          //e.stopPropagation();
          e.preventDefault();
        }
      },
      true
    );
  }

    disableDefaultBackBtn() {
        document.addEventListener('backbutton', this.backSubInit.bind(this), false);
    }

    async backSubInit() {
        const carrotWebTop = document.querySelector('#carrot-messenger-frame');
        const smartCaptchaTopVisible: any = document.querySelector('.SmartCaptcha-Overlay_visible')?.children[1];
        if (carrotWebTop) {
            return this.carrotQuestService.close();
        }
        if (smartCaptchaTopVisible){
          return this.captchaService.reset();
        }
        if (this.backButtonEnabled) {
            this.backButtonEnabled = false;
            setTimeout(() => {
                this.backButtonEnabled = true;
            }, 500);

            if (this.primaryBackButtonFunction) {
                this.primaryBackButtonFunction[0]();
                if (this.primaryBackButtonFunction[1] === true){
                    return;
                }
            }

            const loadingTop = await this.loadingController.getTop();
            const popoverTop = await this.popoverController.getTop();
            const alertTop = await this.alertController.getTop();
            const actionSheetTop = await this.actionSheetController.getTop();
            const modalTop = await this.modalController.getTop();

            if (loadingTop) {
                return;
            } else if (alertTop) {
                return await this.alertController.dismiss(false, 'cancel');
            } else if (actionSheetTop) {
                return await this.actionSheetController.dismiss(false, 'cancel');
            } else if (popoverTop) {
                return await this.popoverController.dismiss(false, 'cancel');
            } else if (this.backButtonFunction.length) {
                this.backButtonFunction[this.backButtonFunction.length - 1]();
            } else if (modalTop) {
                return await this.modalController.dismiss();
            } else {
                const oldUrl = this.router.url;
                await this.navCtrl.pop();
                const newUrl = this.router.url;
                if (newUrl === oldUrl){
                    await this.navCtrl.navigateRoot('/', {animated: true, animationDirection: 'back'});
                }
            }
        }
    }

}
