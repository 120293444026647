<ng-container [ngSwitch]="isIos()">
    <ion-refresher *ngSwitchCase="true" [disabled]="disabled" [class.white]="isInvert" slot="fixed"
                   (ionRefresh)="refreshing($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-refresher *ngSwitchCase="false" [disabled]="disabled" slot="fixed"
                   (ionRefresh)="refreshing($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
</ng-container>
