import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlatformService } from '../../../core/services/platform.service';

@Component({
    selector: 'app-refresher',
    templateUrl: './refresher.component.html',
    styleUrls: ['./refresher.component.scss'],
})
export class RefresherComponent implements OnInit {
    @Input() isInvert = false;
    @Input() disabled = false;
    @Output() refresh: EventEmitter<any> = new EventEmitter<any>();

    constructor(private platformService: PlatformService) {}

    ngOnInit() {}

    isIos() {
        return this.platformService.isIos();
    }

    async refreshing(ev: any) {
        this.refresh.emit(ev);
    }
}
