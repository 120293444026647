import { INotificationState, NOTIFICATION_REDUCER_NODE, notificationItemAdapter } from './notification.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const notificationFeature = createFeatureSelector<INotificationState>(NOTIFICATION_REDUCER_NODE);
const notificationsItems = (state) => notificationFeature(state).notifications;

const { selectAll } = notificationItemAdapter.getSelectors(notificationsItems);
const loading = (state) => notificationFeature(state).loading;
const needLoad = (state) => notificationFeature(state).needLoad;

const selectUnread = createSelector(selectAll, (state) => state.filter((i) => !i.isRead));

const unreadExist = createSelector(selectUnread, (state) => !!state.length);

export const NotificationSelectors = {
    selectAll,
    selectUnread,
    loading,
    needLoad,
    unreadExist,
};
