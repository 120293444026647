import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-ui-rating-star',
    templateUrl: './ui-rating-star.component.html',
    styleUrls: ['./ui-rating-star.component.scss'],
})
export class UiRatingStarComponent {
    @Input() active!: boolean;
    @Input() size: 'small' | 'normal' | 'large' = 'normal';

    constructor() {}
}
